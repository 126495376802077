import { USER_ROUTE_NAME, USER_ROUTE_PATH } from '../constants/router';

export const CardSupport = ['Visa', 'MasterCard', 'JCB', 'American Express'];

export const TIME_FORMAT = {
  DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
  MESSAGE_TIME: 'YYYY/MM/DD HH:mm',
  HOUSE_MINUTE: (type: '-' | '/' | ':') => `HH${type}mm`,
  DATE: (type: '-' | '/') => `YYYY${type}MM${type}DD`,
};

export const QUERY_KEY = {
  USER_ME: 'USER_ME',
  LIST_SETTING: 'LIST_SETTING',
  DEAIL_SETTING: 'DEAIL_SETTING',

  LIST_NOTIFI: 'LIST_NOTIFI',
  DEAIL_NOTIFI: 'DEAIL_NOTIFI',

  LIST_MEMBER: 'LIST_MEMBER',

  LIST_GROUP_CHAT: 'LIST_GROUP_CHAT',
  DETAIL_GROUP_CHAT: 'DETAIL_GROUP_CHAT',
  COUNT_USER_UNREAD_MESSAGE: 'COUNT_USER_UNREAD_MESSAGE',

  PAYMENT_SETTING_STATUS: 'PAYMENT_SETTING_STATUS',

  STORAGE_ITEM: 'STORAGE_ITEM',

  ITEM_DETAIL: 'ITEM_DETAIL',

  LIST_ACCOUNT_EBAY: 'LIST_ACCOUNT_EBAY',

  LIST_PAYMENT_HISTORY: 'LIST_PAYMENT_HISTORY',

  DETAIL_EXPORT_COUNT: 'DETAIL_EXPORT_COUNT',

  COUNT_USER_UNREAD_SYSTEM_NOTIFICATION: 'COUNT_USER_UNREAD_SYSTEM_NOTIFICATION',

  INFO_QUIT: 'QUIT_MEMBER',

  LIST_GROUP_ITEM_MESSAGE: 'LIST_GROUP_ITEM_MESSAGE',
  LIST_GROUP_ITEM_MESSAGE_UNREAD: 'LIST_GROUP_ITEM_MESSAGE_UNREAD',
  LIST_GROUP_ITEM_MESSAGE_INQUIRY: 'LIST_GROUP_ITEM_MESSAGE_INQUIRY',
  SETTING_NOTIFY: 'SETTING_NOTIFY',
  BIZ_NOTIFY: 'BIZ_NOTIFY',
  SETTING: 'SETTING',
  COUNT_ITEM_LIST: 'COUNT_ITEM_LIST',
};

export enum RETURN_STATUS_ENUM {
  CLOSED = 'CLOSED',
  ESCALATED = 'ESCALATED',
  ITEM_DELIVERED = 'ITEM_DELIVERED',
  ITEM_SHIPPED = 'ITEM_SHIPPED',
  LESS_THAN_A_FULL_REFUND_ISSUED = 'LESS_THAN_A_FULL_REFUND_ISSUED',
  PARTIAL_REFUND_DECLINED = 'PARTIAL_REFUND_DECLINED',
  PARTIAL_REFUND_FAILED = 'PARTIAL_REFUND_FAILED',
  PARTIAL_REFUND_INITIATED = 'PARTIAL_REFUND_INITIATED',
  PARTIAL_REFUND_REQUESTED = 'PARTIAL_REFUND_REQUESTED',
  READY_FOR_SHIPPING = 'READY_FOR_SHIPPING',
  REPLACED = 'REPLACED',
  REPLACEMENT_CLOSED = 'REPLACEMENT_CLOSED',
  REPLACEMENT_DELIVERED = 'REPLACEMENT_DELIVERED',
  REPLACEMENT_LABEL_REQUESTED = 'REPLACEMENT_LABEL_REQUESTED',
  REPLACEMENT_REQUESTED = 'REPLACEMENT_REQUESTED',
  REPLACEMENT_SHIPPED = 'REPLACEMENT_SHIPPED',
  REPLACEMENT_STARTED = 'REPLACEMENT_STARTED',
  REPLACEMENT_WAITING_FOR_RMA = 'REPLACEMENT_WAITING_FOR_RMA',
  RETURN_LABEL_REQUESTED = 'RETURN_LABEL_REQUESTED',
  RETURN_REJECTED = 'RETURN_REJECTED',
  RETURN_REQUESTED = 'RETURN_REQUESTED',
  RETURN_REQUESTED_TIMEOUT = 'RETURN_REQUESTED_TIMEOUT',
  UNKNOWN = 'UNKNOWN',
  WAITING_FOR_RETURN_LABEL = 'WAITING_FOR_RETURN_LABEL',
  WAITING_FOR_RMA = 'WAITING_FOR_RMA',
}

export const STATUS_ITEM_MANAGEMENT = {
  NOT_DELIVERED: 'NOT_DELIVERED',
  DELIVERED: 'DELIVERED',
  WAITING_STORAGE: 'WAITING_STORAGE',
  WAITING_UPLOAD_IMAGE_VIDEO: 'WAITING_UPLOAD_IMAGE_VIDEO',
  IN_STORAGE: 'IN_STORAGE',
  SHIPPED_TO_BUYER: 'SHIPPED_TO_BUYER',
  SHIPPED_BACK: 'SHIPPED_BACK',
  DISPOSED: 'DISPOSED',
  IN_TRANSIT: 'IN_TRANSIT',
  NOT_SHIPPED: 'NOT_SHIPPED',
  SHIPPING_PREPARING: 'SHIPPING_PREPARING',
  SHIPPING_PREPARING_DONE: 'SHIPPING_PREPARING_DONE',
  NEW_DISPOSAL_REQUEST: 'NEW_DISPOSAL_REQUEST',
  PRICE_INPUTED: 'PRICE_INPUTED',
  CANCELED: 'CANCELED',
  SHIPPED: 'SHIPPED',
};

export enum TAKE_PHOTO_REQUEST_STATUS {
  REQUESTED = 'REQUESTED',
  TAKE_BY_OPERATION = 'TAKE_BY_OPERATION',
  UPLOAD_BY_STAFF = 'UPLOAD_BY_STAFF',
}

export enum STORAGE_REQUEST_STATUS {
  NOT_SHIPPED = 'NOT_SHIPPED',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED',
  CANCELED = 'CANCELED',
}

export enum STORAGE_REQUEST_CANCEL_REASON {
  USER_CANCEL = 'USER_CANCEL',
  AUTO_CANCEL = 'AUTO_CANCEL',
}

export enum ShipingLabelsTrackingStatus {
  UNKNOWN = 'UNKNOWN',
  PRE_TRANSIT = 'PRE_TRANSIT',
  TRANSIT = 'TRANSIT',
  DELIVERED = 'DELIVERED',
  RETURNED = 'RETURNED',
  FAILURE = 'FAILURE',
}

export enum REQUEST_ACTION_ITEM_DETAIL {
  CREATE_DISPOSAL_REQUEST = 'CREATE_DISPOSAL_REQUEST',
  CANCEL_STORAGE_REQUEST = 'CANCEL_STORAGE_REQUEST',
  CANCEL_DISPOSAL_REQUEST = 'CANCEL_DISPOSAL_REQUEST',
  CANCEL_SHIP_TO_BUYER_REQUEST = 'CANCEL_SHIP_TO_BUYER_REQUEST',
  CANCEL_SHIP_BACK_REQUEST = 'CANCEL_SHIP_BACK_REQUEST',
}

export const NavigateBiz = {
  UnsubcriptionEvent: (id?: number) => USER_ROUTE_NAME.QUIT_MEMBERSHIP, // logo
  ItemReceivedEvent: (id?: number) =>
    id ? `${USER_ROUTE_PATH.RETURN_ITEM}/detail/${id}` : USER_ROUTE_PATH.RETURN_ITEM,
  ItemShippedEvent: (id?: number) => (id ? `${USER_ROUTE_PATH.RETURN_ITEM}/detail/${id}` : USER_ROUTE_PATH.RETURN_ITEM),
  NewInquiryMessageEvent: (id?: number) =>
    id ? `${USER_ROUTE_PATH.RETURN_ITEM}/detail/${id}` : USER_ROUTE_PATH.RETURN_ITEM,
  StorageRequestAutoCancelEvent: (id?: number) =>
    id ? `${USER_ROUTE_PATH.RETURN_ITEM}/detail/${id}` : USER_ROUTE_PATH.RETURN_ITEM,
  StorageRequestCompletedEvent: (id?: number) =>
    id ? `${USER_ROUTE_PATH.RETURN_ITEM}/detail/${id}` : USER_ROUTE_PATH.RETURN_ITEM,
  ShipRequestCompletedEvent: (id?: number) =>
    id ? `${USER_ROUTE_PATH.RETURN_ITEM}/detail/${id}` : USER_ROUTE_PATH.RETURN_ITEM,
  DisposalRequestCompletedEvent: (id?: number) =>
    id ? `${USER_ROUTE_PATH.RETURN_ITEM}/detail/${id}` : USER_ROUTE_PATH.RETURN_ITEM,
  EbayAccountLinkedEvent: () => USER_ROUTE_PATH.EBAY_MANAGEMENT,
  CardUpdatedEvent: (id?: number) => USER_ROUTE_PATH.CARD,
  MemberAddedEvent: (id?: number) => USER_ROUTE_PATH.MEMBER_MANAGEMENT,
  MemberDeletedEvent: (id?: number) => USER_ROUTE_PATH.MEMBER_MANAGEMENT,
  EmailUpdatedEvent: (id?: number) => USER_ROUTE_PATH.PROFILE,
};
