/* tslint:disable */
/* eslint-disable */
/**
 * BayPackStaging
 * Api for staging - v1.0
 *
 * The version of the OpenAPI document: 1.0
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountEbay
 */
export interface AccountEbay {
    /**
     * 
     * @type {number}
     * @memberof AccountEbay
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof AccountEbay
     */
    'userEbayId': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbay
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbay
     */
    'avatar': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbay
     */
    'oauthToken': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbay
     */
    'expiredOauthToken': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbay
     */
    'refreshOauthToken': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbay
     */
    'expiredRefreshOauthToken': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbay
     */
    'dateGetToken': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbay
     */
    'marketplaceId': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbay
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbay
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbay
     */
    'eiasToken': string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountEbay
     */
    'isActivated': boolean;
    /**
     * 
     * @type {User}
     * @memberof AccountEbay
     */
    'user'?: User;
    /**
     * 
     * @type {Array<EbayReturns>}
     * @memberof AccountEbay
     */
    'ebayReturns'?: Array<EbayReturns>;
    /**
     * 
     * @type {number}
     * @memberof AccountEbay
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AccountEbay
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbay
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbay
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface AccountEbayPagination
 */
export interface AccountEbayPagination {
    /**
     * 
     * @type {number}
     * @memberof AccountEbayPagination
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AccountEbayPagination
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbayPagination
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbayPagination
     */
    'deletedAt': string;
    /**
     * 
     * @type {number}
     * @memberof AccountEbayPagination
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof AccountEbayPagination
     */
    'userEbayId': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbayPagination
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbayPagination
     */
    'avatar': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbayPagination
     */
    'expiredOauthToken': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbayPagination
     */
    'expiredRefreshOauthToken': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbayPagination
     */
    'dateGetToken': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbayPagination
     */
    'marketplaceId': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbayPagination
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEbayPagination
     */
    'lastName': string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountEbayPagination
     */
    'isActivated': boolean;
    /**
     * 
     * @type {Array<EbayReturns>}
     * @memberof AccountEbayPagination
     */
    'ebayReturns'?: Array<EbayReturns>;
}
/**
 * 
 * @export
 * @interface ActualHandlingCost
 */
export interface ActualHandlingCost {
    /**
     * 
     * @type {string}
     * @memberof ActualHandlingCost
     */
    'currencyID': string;
    /**
     * 
     * @type {string}
     * @memberof ActualHandlingCost
     */
    '_@ttribute': string;
}
/**
 * 
 * @export
 * @interface ActualShippingCost
 */
export interface ActualShippingCost {
    /**
     * 
     * @type {string}
     * @memberof ActualShippingCost
     */
    'currencyID': string;
    /**
     * 
     * @type {string}
     * @memberof ActualShippingCost
     */
    '_@ttribute': string;
}
/**
 * 
 * @export
 * @interface AddJobStorageRequestToQueueDto
 */
export interface AddJobStorageRequestToQueueDto {
    /**
     * 
     * @type {string}
     * @memberof AddJobStorageRequestToQueueDto
     */
    'transactionId': string;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'addressType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    'isTransliterated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'nationalRegion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'transliteratedFromScript'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'worldRegion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'addressLine1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'addressLine2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'county'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'stateOrProvince'?: string;
}
/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * First and Last Name of the addressee
     * @type {string}
     * @memberof AddressDto
     */
    'name': string;
    /**
     * Company Name
     * @type {string}
     * @memberof AddressDto
     */
    'company'?: string;
    /**
     * street number and street name
     * @type {string}
     * @memberof AddressDto
     */
    'street1': string;
    /**
     * street number and street name
     * @type {string}
     * @memberof AddressDto
     */
    'street2'?: string;
    /**
     * Name of a city
     * @type {string}
     * @memberof AddressDto
     */
    'city': string;
    /**
     * Most carriers only accept two or three character state abbreviations.
     * @type {string}
     * @memberof AddressDto
     */
    'state': string;
    /**
     * Postal code of an Address
     * @type {string}
     * @memberof AddressDto
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'email'?: string;
    /**
     * A string of up to 100 characters that can be filled with any additional information you want to attach to the object
     * @type {string}
     * @memberof AddressDto
     */
    'metadata'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddressDto
     */
    'validate'?: boolean;
}
/**
 * 
 * @export
 * @interface AddressImporterDto
 */
export interface AddressImporterDto {
    /**
     * 
     * @type {string}
     * @memberof AddressImporterDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressImporterDto
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressImporterDto
     */
    'street1'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressImporterDto
     */
    'street2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressImporterDto
     */
    'street3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressImporterDto
     */
    'streetNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressImporterDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressImporterDto
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressImporterDto
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressImporterDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressImporterDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressImporterDto
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddressImporterDto
     */
    'is_residential'?: boolean;
}
/**
 * 
 * @export
 * @interface AdjustmentAmount
 */
export interface AdjustmentAmount {
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAmount
     */
    'currencyID': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAmount
     */
    '_@ttribute': string;
}
/**
 * 
 * @export
 * @interface Admin
 */
export interface Admin {
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'loginId': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'role': string;
    /**
     * 
     * @type {boolean}
     * @memberof Admin
     */
    'staffActiveFlg': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Admin
     */
    'permissions': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Admin
     */
    'avatarId': number;
    /**
     * 
     * @type {Asset}
     * @memberof Admin
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {number}
     * @memberof Admin
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface AdminSetting
 */
export interface AdminSetting {
    /**
     * 
     * @type {string}
     * @memberof AdminSetting
     */
    'key': string;
    /**
     * 
     * @type {object}
     * @memberof AdminSetting
     */
    'value': object;
    /**
     * 
     * @type {number}
     * @memberof AdminSetting
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AdminSetting
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSetting
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSetting
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface AdminSettingDto
 */
export interface AdminSettingDto {
    /**
     * 
     * @type {string}
     * @memberof AdminSettingDto
     */
    'key': string;
    /**
     * 
     * @type {AdminSettingDtoValue}
     * @memberof AdminSettingDto
     */
    'value': AdminSettingDtoValue;
}
/**
 * 
 * @export
 * @interface AdminSettingDtoValue
 */
export interface AdminSettingDtoValue {
    /**
     * 
     * @type {string}
     * @memberof AdminSettingDtoValue
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSettingDtoValue
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSettingDtoValue
     */
    'subAddress': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSettingDtoValue
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSettingDtoValue
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface AdminSettingResponseType
 */
export interface AdminSettingResponseType {
    /**
     * 
     * @type {AdminSetting}
     * @memberof AdminSettingResponseType
     */
    'transport': AdminSetting;
    /**
     * 
     * @type {AdminSetting}
     * @memberof AdminSettingResponseType
     */
    'reception': AdminSetting;
}
/**
 * 
 * @export
 * @interface AdministratorControllerGetAllEmployee200Response
 */
export interface AdministratorControllerGetAllEmployee200Response {
    /**
     * 
     * @type {number}
     * @memberof AdministratorControllerGetAllEmployee200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdministratorControllerGetAllEmployee200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdministratorControllerGetAllEmployee200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Admin>}
     * @memberof AdministratorControllerGetAllEmployee200Response
     */
    'content'?: Array<Admin>;
}
/**
 * 
 * @export
 * @interface AdministratorControllerGetAllEmployee200ResponseAllOf
 */
export interface AdministratorControllerGetAllEmployee200ResponseAllOf {
    /**
     * 
     * @type {Array<Admin>}
     * @memberof AdministratorControllerGetAllEmployee200ResponseAllOf
     */
    'content'?: Array<Admin>;
}
/**
 * 
 * @export
 * @interface Amount
 */
export interface Amount {
    /**
     * 
     * @type {string}
     * @memberof Amount
     */
    'convertedFromCurrency'?: AmountConvertedFromCurrencyEnum;
    /**
     * 
     * @type {number}
     * @memberof Amount
     */
    'convertedFromValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof Amount
     */
    'currency'?: AmountCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof Amount
     */
    'exchangeRate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Amount
     */
    'value'?: number;
}

export const AmountConvertedFromCurrencyEnum = {
    Aed: 'AED',
    Afn: 'AFN',
    All: 'ALL',
    Amd: 'AMD',
    Ang: 'ANG',
    Aoa: 'AOA',
    Ars: 'ARS',
    Aud: 'AUD',
    Awg: 'AWG',
    Azn: 'AZN',
    Bam: 'BAM',
    Bbd: 'BBD',
    Bdt: 'BDT',
    Bgn: 'BGN',
    Bhd: 'BHD',
    Bif: 'BIF',
    Bmd: 'BMD',
    Bnd: 'BND',
    Bob: 'BOB',
    Brl: 'BRL',
    Bsd: 'BSD',
    Btn: 'BTN',
    Bwp: 'BWP',
    Byr: 'BYR',
    Bzd: 'BZD',
    Cad: 'CAD',
    Cdf: 'CDF',
    Chf: 'CHF',
    Clp: 'CLP',
    Cny: 'CNY',
    Cop: 'COP',
    Crc: 'CRC',
    Cup: 'CUP',
    Cve: 'CVE',
    Czk: 'CZK',
    Djf: 'DJF',
    Dkk: 'DKK',
    Dop: 'DOP',
    Dzd: 'DZD',
    Egp: 'EGP',
    Ern: 'ERN',
    Etb: 'ETB',
    Eur: 'EUR',
    Fjd: 'FJD',
    Fkp: 'FKP',
    Gbp: 'GBP',
    Gel: 'GEL',
    Ghs: 'GHS',
    Gip: 'GIP',
    Gmd: 'GMD',
    Gnf: 'GNF',
    Gtq: 'GTQ',
    Gyd: 'GYD',
    Hkd: 'HKD',
    Hnl: 'HNL',
    Hrk: 'HRK',
    Htg: 'HTG',
    Huf: 'HUF',
    Idr: 'IDR',
    Ils: 'ILS',
    Inr: 'INR',
    Iqd: 'IQD',
    Irr: 'IRR',
    Isk: 'ISK',
    Jmd: 'JMD',
    Jod: 'JOD',
    Jpy: 'JPY',
    Kes: 'KES',
    Kgs: 'KGS',
    Khr: 'KHR',
    Kmf: 'KMF',
    Kpw: 'KPW',
    Krw: 'KRW',
    Kwd: 'KWD',
    Kyd: 'KYD',
    Kzt: 'KZT',
    Lak: 'LAK',
    Lbp: 'LBP',
    Lkr: 'LKR',
    Lrd: 'LRD',
    Lsl: 'LSL',
    Ltl: 'LTL',
    Lyd: 'LYD',
    Mad: 'MAD',
    Mdl: 'MDL',
    Mga: 'MGA',
    Mkd: 'MKD',
    Mmk: 'MMK',
    Mnt: 'MNT',
    Mop: 'MOP',
    Mro: 'MRO',
    Mur: 'MUR',
    Mvr: 'MVR',
    Mwk: 'MWK',
    Mxn: 'MXN',
    Myr: 'MYR',
    Mzn: 'MZN',
    Nad: 'NAD',
    Ngn: 'NGN',
    Nio: 'NIO',
    Nok: 'NOK',
    Npr: 'NPR',
    Nzd: 'NZD',
    Omr: 'OMR',
    Pab: 'PAB',
    Pen: 'PEN',
    Pgk: 'PGK',
    Php: 'PHP',
    Pkr: 'PKR',
    Pln: 'PLN',
    Pyg: 'PYG',
    Qar: 'QAR',
    Ron: 'RON',
    Rsd: 'RSD',
    Rub: 'RUB',
    Rwf: 'RWF',
    Sar: 'SAR',
    Sbd: 'SBD',
    Scr: 'SCR',
    Sdg: 'SDG',
    Sek: 'SEK',
    Sgd: 'SGD',
    Shp: 'SHP',
    Sll: 'SLL',
    Sos: 'SOS',
    Srd: 'SRD',
    Std: 'STD',
    Syp: 'SYP',
    Szl: 'SZL',
    Thb: 'THB',
    Tjs: 'TJS',
    Tmt: 'TMT',
    Tnd: 'TND',
    Top: 'TOP',
    Try: 'TRY',
    Ttd: 'TTD',
    Twd: 'TWD',
    Tzs: 'TZS',
    Uah: 'UAH',
    Ugx: 'UGX',
    Usd: 'USD',
    Uyu: 'UYU',
    Uzs: 'UZS',
    Vef: 'VEF',
    Vnd: 'VND',
    Vuv: 'VUV',
    Wst: 'WST',
    Xaf: 'XAF',
    Xcd: 'XCD',
    Xof: 'XOF',
    Xpf: 'XPF',
    Yer: 'YER',
    Zar: 'ZAR',
    Zmw: 'ZMW',
    Zwl: 'ZWL'
} as const;

export type AmountConvertedFromCurrencyEnum = typeof AmountConvertedFromCurrencyEnum[keyof typeof AmountConvertedFromCurrencyEnum];
export const AmountCurrencyEnum = {
    Aed: 'AED',
    Afn: 'AFN',
    All: 'ALL',
    Amd: 'AMD',
    Ang: 'ANG',
    Aoa: 'AOA',
    Ars: 'ARS',
    Aud: 'AUD',
    Awg: 'AWG',
    Azn: 'AZN',
    Bam: 'BAM',
    Bbd: 'BBD',
    Bdt: 'BDT',
    Bgn: 'BGN',
    Bhd: 'BHD',
    Bif: 'BIF',
    Bmd: 'BMD',
    Bnd: 'BND',
    Bob: 'BOB',
    Brl: 'BRL',
    Bsd: 'BSD',
    Btn: 'BTN',
    Bwp: 'BWP',
    Byr: 'BYR',
    Bzd: 'BZD',
    Cad: 'CAD',
    Cdf: 'CDF',
    Chf: 'CHF',
    Clp: 'CLP',
    Cny: 'CNY',
    Cop: 'COP',
    Crc: 'CRC',
    Cup: 'CUP',
    Cve: 'CVE',
    Czk: 'CZK',
    Djf: 'DJF',
    Dkk: 'DKK',
    Dop: 'DOP',
    Dzd: 'DZD',
    Egp: 'EGP',
    Ern: 'ERN',
    Etb: 'ETB',
    Eur: 'EUR',
    Fjd: 'FJD',
    Fkp: 'FKP',
    Gbp: 'GBP',
    Gel: 'GEL',
    Ghs: 'GHS',
    Gip: 'GIP',
    Gmd: 'GMD',
    Gnf: 'GNF',
    Gtq: 'GTQ',
    Gyd: 'GYD',
    Hkd: 'HKD',
    Hnl: 'HNL',
    Hrk: 'HRK',
    Htg: 'HTG',
    Huf: 'HUF',
    Idr: 'IDR',
    Ils: 'ILS',
    Inr: 'INR',
    Iqd: 'IQD',
    Irr: 'IRR',
    Isk: 'ISK',
    Jmd: 'JMD',
    Jod: 'JOD',
    Jpy: 'JPY',
    Kes: 'KES',
    Kgs: 'KGS',
    Khr: 'KHR',
    Kmf: 'KMF',
    Kpw: 'KPW',
    Krw: 'KRW',
    Kwd: 'KWD',
    Kyd: 'KYD',
    Kzt: 'KZT',
    Lak: 'LAK',
    Lbp: 'LBP',
    Lkr: 'LKR',
    Lrd: 'LRD',
    Lsl: 'LSL',
    Ltl: 'LTL',
    Lyd: 'LYD',
    Mad: 'MAD',
    Mdl: 'MDL',
    Mga: 'MGA',
    Mkd: 'MKD',
    Mmk: 'MMK',
    Mnt: 'MNT',
    Mop: 'MOP',
    Mro: 'MRO',
    Mur: 'MUR',
    Mvr: 'MVR',
    Mwk: 'MWK',
    Mxn: 'MXN',
    Myr: 'MYR',
    Mzn: 'MZN',
    Nad: 'NAD',
    Ngn: 'NGN',
    Nio: 'NIO',
    Nok: 'NOK',
    Npr: 'NPR',
    Nzd: 'NZD',
    Omr: 'OMR',
    Pab: 'PAB',
    Pen: 'PEN',
    Pgk: 'PGK',
    Php: 'PHP',
    Pkr: 'PKR',
    Pln: 'PLN',
    Pyg: 'PYG',
    Qar: 'QAR',
    Ron: 'RON',
    Rsd: 'RSD',
    Rub: 'RUB',
    Rwf: 'RWF',
    Sar: 'SAR',
    Sbd: 'SBD',
    Scr: 'SCR',
    Sdg: 'SDG',
    Sek: 'SEK',
    Sgd: 'SGD',
    Shp: 'SHP',
    Sll: 'SLL',
    Sos: 'SOS',
    Srd: 'SRD',
    Std: 'STD',
    Syp: 'SYP',
    Szl: 'SZL',
    Thb: 'THB',
    Tjs: 'TJS',
    Tmt: 'TMT',
    Tnd: 'TND',
    Top: 'TOP',
    Try: 'TRY',
    Ttd: 'TTD',
    Twd: 'TWD',
    Tzs: 'TZS',
    Uah: 'UAH',
    Ugx: 'UGX',
    Usd: 'USD',
    Uyu: 'UYU',
    Uzs: 'UZS',
    Vef: 'VEF',
    Vnd: 'VND',
    Vuv: 'VUV',
    Wst: 'WST',
    Xaf: 'XAF',
    Xcd: 'XCD',
    Xof: 'XOF',
    Xpf: 'XPF',
    Yer: 'YER',
    Zar: 'ZAR',
    Zmw: 'ZMW',
    Zwl: 'ZWL'
} as const;

export type AmountCurrencyEnum = typeof AmountCurrencyEnum[keyof typeof AmountCurrencyEnum];

/**
 * 
 * @export
 * @interface AmountPaid
 */
export interface AmountPaid {
    /**
     * 
     * @type {string}
     * @memberof AmountPaid
     */
    'currencyID': string;
    /**
     * 
     * @type {string}
     * @memberof AmountPaid
     */
    '_@ttribute': string;
}
/**
 * 
 * @export
 * @interface AmountSaved
 */
export interface AmountSaved {
    /**
     * 
     * @type {string}
     * @memberof AmountSaved
     */
    'currencyID': string;
    /**
     * 
     * @type {string}
     * @memberof AmountSaved
     */
    '_@ttribute': string;
}
/**
 * 
 * @export
 * @interface AppliedPromotions
 */
export interface AppliedPromotions {
    /**
     * 
     * @type {string}
     * @memberof AppliedPromotions
     */
    'description': string;
    /**
     * 
     * @type {Amount}
     * @memberof AppliedPromotions
     */
    'discountAmount': Amount;
    /**
     * 
     * @type {string}
     * @memberof AppliedPromotions
     */
    'promotionId': string;
}
/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'originName': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'type': AssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'mimeType': string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'fileSize': number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'preview': string;
    /**
     * 
     * @type {AssetFocalPoint}
     * @memberof Asset
     */
    'focalPoint': AssetFocalPoint;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'parentId': number;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof Asset
     */
    'children': Array<Asset>;
    /**
     * 
     * @type {Asset}
     * @memberof Asset
     */
    'parent': Asset;
    /**
     * 
     * @type {Array<StorageItem>}
     * @memberof Asset
     */
    'photos': Array<StorageItem>;
    /**
     * 
     * @type {Array<TakePhotoRequest>}
     * @memberof Asset
     */
    'takePhotoRequest': Array<TakePhotoRequest>;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'deletedAt': string;
}

export const AssetTypeEnum = {
    Image: 'IMAGE',
    Video: 'VIDEO',
    Binary: 'BINARY',
    Folder: 'FOLDER'
} as const;

export type AssetTypeEnum = typeof AssetTypeEnum[keyof typeof AssetTypeEnum];

/**
 * 
 * @export
 * @interface AssetFocalPoint
 */
export interface AssetFocalPoint {
    /**
     * 
     * @type {number}
     * @memberof AssetFocalPoint
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetFocalPoint
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface BizNotification
 */
export interface BizNotification {
    /**
     * 
     * @type {number}
     * @memberof BizNotification
     */
    'userId': number;
    /**
     * 
     * @type {User}
     * @memberof BizNotification
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof BizNotification
     */
    'familyCode': string;
    /**
     * 
     * @type {string}
     * @memberof BizNotification
     */
    'eventType': string;
    /**
     * 
     * @type {object}
     * @memberof BizNotification
     */
    'metadata': object;
    /**
     * 
     * @type {boolean}
     * @memberof BizNotification
     */
    'sendMail': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BizNotification
     */
    'sendNotification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BizNotification
     */
    'isRead': boolean;
    /**
     * 
     * @type {string}
     * @memberof BizNotification
     */
    'assetLink': string;
    /**
     * 
     * @type {string}
     * @memberof BizNotification
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof BizNotification
     */
    'content': string;
    /**
     * 
     * @type {number}
     * @memberof BizNotification
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BizNotification
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BizNotification
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof BizNotification
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface BizNotificationControllerGetAll200Response
 */
export interface BizNotificationControllerGetAll200Response {
    /**
     * 
     * @type {number}
     * @memberof BizNotificationControllerGetAll200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof BizNotificationControllerGetAll200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof BizNotificationControllerGetAll200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<BizNotification>}
     * @memberof BizNotificationControllerGetAll200Response
     */
    'content'?: Array<BizNotification>;
}
/**
 * 
 * @export
 * @interface BizNotificationControllerGetAll200ResponseAllOf
 */
export interface BizNotificationControllerGetAll200ResponseAllOf {
    /**
     * 
     * @type {Array<BizNotification>}
     * @memberof BizNotificationControllerGetAll200ResponseAllOf
     */
    'content'?: Array<BizNotification>;
}
/**
 * 
 * @export
 * @interface Buyer
 */
export interface Buyer {
    /**
     * 
     * @type {string}
     * @memberof Buyer
     */
    'Email': string;
    /**
     * 
     * @type {string}
     * @memberof Buyer
     */
    'UserFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof Buyer
     */
    'UserLastName': string;
}
/**
 * 
 * @export
 * @interface BuyerEbay
 */
export interface BuyerEbay {
    /**
     * 
     * @type {BuyerEbayBuyerRegistrationAddress}
     * @memberof BuyerEbay
     */
    'buyerRegistrationAddress': BuyerEbayBuyerRegistrationAddress;
    /**
     * 
     * @type {TaxAddress}
     * @memberof BuyerEbay
     */
    'taxAddress': TaxAddress;
    /**
     * 
     * @type {BuyerEbayTaxIdentifier}
     * @memberof BuyerEbay
     */
    'taxIdentifier': BuyerEbayTaxIdentifier;
    /**
     * 
     * @type {string}
     * @memberof BuyerEbay
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface BuyerEbayBuyerRegistrationAddress
 */
export interface BuyerEbayBuyerRegistrationAddress {
    /**
     * 
     * @type {string}
     * @memberof BuyerEbayBuyerRegistrationAddress
     */
    'companyName'?: string;
    /**
     * 
     * @type {Address}
     * @memberof BuyerEbayBuyerRegistrationAddress
     */
    'contactAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof BuyerEbayBuyerRegistrationAddress
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerEbayBuyerRegistrationAddress
     */
    'fullName'?: string;
    /**
     * 
     * @type {BuyerEbayBuyerRegistrationAddressPrimaryPhone}
     * @memberof BuyerEbayBuyerRegistrationAddress
     */
    'primaryPhone'?: BuyerEbayBuyerRegistrationAddressPrimaryPhone;
}
/**
 * 
 * @export
 * @interface BuyerEbayBuyerRegistrationAddressPrimaryPhone
 */
export interface BuyerEbayBuyerRegistrationAddressPrimaryPhone {
    /**
     * 
     * @type {string}
     * @memberof BuyerEbayBuyerRegistrationAddressPrimaryPhone
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface BuyerEbayTaxIdentifier
 */
export interface BuyerEbayTaxIdentifier {
    /**
     * 
     * @type {string}
     * @memberof BuyerEbayTaxIdentifier
     */
    'taxpayerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerEbayTaxIdentifier
     */
    'taxIdentifierType'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerEbayTaxIdentifier
     */
    'issuingCountry'?: string;
}
/**
 * 
 * @export
 * @interface BuyerInfo
 */
export interface BuyerInfo {
    /**
     * 
     * @type {ShippingAddress}
     * @memberof BuyerInfo
     */
    'ShippingAddress': ShippingAddress;
}
/**
 * 
 * @export
 * @interface BuyerRequirementDetails
 */
export interface BuyerRequirementDetails {
    /**
     * 
     * @type {string}
     * @memberof BuyerRequirementDetails
     */
    'ShipToRegistrationCountry': string;
}
/**
 * 
 * @export
 * @interface CancelShipToBuyerDto
 */
export interface CancelShipToBuyerDto {
    /**
     * 
     * @type {number}
     * @memberof CancelShipToBuyerDto
     */
    'shipToBuyerItemId': number;
}
/**
 * 
 * @export
 * @interface CancelStorageRequest
 */
export interface CancelStorageRequest {
    /**
     * 
     * @type {number}
     * @memberof CancelStorageRequest
     */
    'storageRequestId': number;
}
/**
 * 
 * @export
 * @interface CategoryType
 */
export interface CategoryType {
    /**
     * 
     * @type {string}
     * @memberof CategoryType
     */
    'default': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryType
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ChangeNameDto
 */
export interface ChangeNameDto {
    /**
     * 
     * @type {number}
     * @memberof ChangeNameDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ChangeNameDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ChangePasswordDto
 */
export interface ChangePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface ChangeUserPlan
 */
export interface ChangeUserPlan {
    /**
     * 
     * @type {number}
     * @memberof ChangeUserPlan
     */
    'planVersionId': number;
}
/**
 * 
 * @export
 * @interface CheckUnsubcriptionAvailableResponse
 */
export interface CheckUnsubcriptionAvailableResponse {
    /**
     * 
     * @type {string}
     * @memberof CheckUnsubcriptionAvailableResponse
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface CheckValidCouponResponseDTO
 */
export interface CheckValidCouponResponseDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof CheckValidCouponResponseDTO
     */
    'invalidCouponCodes': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CheckValidCouponResponseDTO
     */
    'invalidDateCoupons': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CheckValidCouponResponseDTO
     */
    'invalidUserCodes': Array<string>;
}
/**
 * 
 * @export
 * @interface CheckoutStatus
 */
export interface CheckoutStatus {
    /**
     * 
     * @type {string}
     * @memberof CheckoutStatus
     */
    'eBayPaymentStatus': string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutStatus
     */
    'LastModifiedTime': string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutStatus
     */
    'PaymentMethod': string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutStatus
     */
    'Status': string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutStatus
     */
    'IntegratedMerchantCreditCardEnabled': string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutStatus
     */
    'PaymentInstrument': string;
}
/**
 * 
 * @export
 * @interface ConnectAccountEbayDto
 */
export interface ConnectAccountEbayDto {
    /**
     * 
     * @type {string}
     * @memberof ConnectAccountEbayDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface CountForItemListResponse
 */
export interface CountForItemListResponse {
    /**
     * 
     * @type {number}
     * @memberof CountForItemListResponse
     */
    'all': number;
    /**
     * 
     * @type {number}
     * @memberof CountForItemListResponse
     */
    'notShipped': number;
    /**
     * 
     * @type {number}
     * @memberof CountForItemListResponse
     */
    'inTransit': number;
    /**
     * 
     * @type {number}
     * @memberof CountForItemListResponse
     */
    'waitingStock': number;
    /**
     * 
     * @type {number}
     * @memberof CountForItemListResponse
     */
    'inStock': number;
    /**
     * 
     * @type {number}
     * @memberof CountForItemListResponse
     */
    'itemShip': number;
    /**
     * 
     * @type {number}
     * @memberof CountForItemListResponse
     */
    'itemDisposal': number;
    /**
     * 
     * @type {number}
     * @memberof CountForItemListResponse
     */
    'complete': number;
}
/**
 * 
 * @export
 * @interface CountForItemListUserResponse
 */
export interface CountForItemListUserResponse {
    /**
     * 
     * @type {number}
     * @memberof CountForItemListUserResponse
     */
    'USER_ALL': number;
    /**
     * 
     * @type {number}
     * @memberof CountForItemListUserResponse
     */
    'USER_SHIPPED': number;
    /**
     * 
     * @type {number}
     * @memberof CountForItemListUserResponse
     */
    'USER_IN_STOCK': number;
    /**
     * 
     * @type {number}
     * @memberof CountForItemListUserResponse
     */
    'USER_ITEM_SHIP_PREPARE': number;
    /**
     * 
     * @type {number}
     * @memberof CountForItemListUserResponse
     */
    'USER_ITEM_SHIPPED': number;
    /**
     * 
     * @type {number}
     * @memberof CountForItemListUserResponse
     */
    'USER_ITEM_DISPOSAL': number;
    /**
     * 
     * @type {number}
     * @memberof CountForItemListUserResponse
     */
    'USER_CANCEL': number;
    /**
     * 
     * @type {number}
     * @memberof CountForItemListUserResponse
     */
    'USER_INQUIRY': number;
}
/**
 * 
 * @export
 * @interface CountForRequestListResponse
 */
export interface CountForRequestListResponse {
    /**
     * 
     * @type {number}
     * @memberof CountForRequestListResponse
     */
    'stock': number;
    /**
     * 
     * @type {number}
     * @memberof CountForRequestListResponse
     */
    'ship': number;
    /**
     * 
     * @type {number}
     * @memberof CountForRequestListResponse
     */
    'photoInit': number;
    /**
     * 
     * @type {number}
     * @memberof CountForRequestListResponse
     */
    'photoRequest': number;
    /**
     * 
     * @type {number}
     * @memberof CountForRequestListResponse
     */
    'disposal': number;
    /**
     * 
     * @type {number}
     * @memberof CountForRequestListResponse
     */
    'question': number;
    /**
     * 
     * @type {number}
     * @memberof CountForRequestListResponse
     */
    'chat': number;
}
/**
 * 
 * @export
 * @interface CountUnreadReponse
 */
export interface CountUnreadReponse {
    /**
     * 
     * @type {number}
     * @memberof CountUnreadReponse
     */
    'unread': number;
}
/**
 * 
 * @export
 * @interface Coupon
 */
export interface Coupon {
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'expiredDate': string;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    'discountRate': number;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    'userId': number;
    /**
     * 
     * @type {User}
     * @memberof Coupon
     */
    'user': User;
    /**
     * 
     * @type {boolean}
     * @memberof Coupon
     */
    'isUsed': boolean;
    /**
     * 
     * @type {StorageRequest}
     * @memberof Coupon
     */
    'storageRequest'?: StorageRequest;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface CouponControllerReadListCoupons200Response
 */
export interface CouponControllerReadListCoupons200Response {
    /**
     * 
     * @type {number}
     * @memberof CouponControllerReadListCoupons200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof CouponControllerReadListCoupons200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof CouponControllerReadListCoupons200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Coupon>}
     * @memberof CouponControllerReadListCoupons200Response
     */
    'content'?: Array<Coupon>;
}
/**
 * 
 * @export
 * @interface CouponControllerReadListCoupons200ResponseAllOf
 */
export interface CouponControllerReadListCoupons200ResponseAllOf {
    /**
     * 
     * @type {Array<Coupon>}
     * @memberof CouponControllerReadListCoupons200ResponseAllOf
     */
    'content'?: Array<Coupon>;
}
/**
 * 
 * @export
 * @interface CreateDisposalRequestDto
 */
export interface CreateDisposalRequestDto {
    /**
     * 
     * @type {number}
     * @memberof CreateDisposalRequestDto
     */
    'storageItemId': number;
}
/**
 * 
 * @export
 * @interface CreateEmployeeDto
 */
export interface CreateEmployeeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    'loginId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    'password': string;
    /**
     * 
     * @type {CreateEmployeeDtoPermission}
     * @memberof CreateEmployeeDto
     */
    'permission': CreateEmployeeDtoPermission;
    /**
     * 
     * @type {Asset}
     * @memberof CreateEmployeeDto
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeDto
     */
    'isOperation': boolean;
}
/**
 * 
 * @export
 * @interface CreateEmployeeDtoPermission
 */
export interface CreateEmployeeDtoPermission {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeDtoPermission
     */
    'Authenticated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeDtoPermission
     */
    'RequestManagement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeDtoPermission
     */
    'ItemManagement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeDtoPermission
     */
    'ShipmentManagement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeDtoPermission
     */
    'Storage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeDtoPermission
     */
    'WarehouserCheck'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeDtoPermission
     */
    'RackManagement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeDtoPermission
     */
    'Profile'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeDtoPermission
     */
    'ChangePassword'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeDtoPermission
     */
    'ChatSupport'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateFolderDto
 */
export interface CreateFolderDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFolderDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateFolderDto
     */
    'assetFolderId'?: number;
}
/**
 * 
 * @export
 * @interface CreateMultipleCouponsDto
 */
export interface CreateMultipleCouponsDto {
    /**
     * 
     * @type {Array<CreateSingleCouponDto>}
     * @memberof CreateMultipleCouponsDto
     */
    'coupons': Array<CreateSingleCouponDto>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateMultipleCouponsDto
     */
    'isSendMail': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateMultipleCouponsDto
     */
    'mailTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMultipleCouponsDto
     */
    'mailContent'?: string;
}
/**
 * 
 * @export
 * @interface CreateNotiDto
 */
export interface CreateNotiDto {
    /**
     * 
     * @type {string}
     * @memberof CreateNotiDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNotiDto
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface CreatePaymentSettingDto
 */
export interface CreatePaymentSettingDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentSettingDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentSettingDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentSettingDto
     */
    'activationDate': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentSettingDto
     */
    'shippoSurchargeConstInUS': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentSettingDto
     */
    'shippoSurchargeConstOutUS': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentSettingDto
     */
    'videoRecordFee': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentSettingDto
     */
    'disposalFee': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentSettingDto
     */
    'takePhotoFee': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentSettingDto
     */
    'cancellationConst': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentSettingDto
     */
    'trackingNumberNotRegisteredFee': number;
    /**
     * 
     * @type {Array<PlanVersionDto>}
     * @memberof CreatePaymentSettingDto
     */
    'planVersions': Array<PlanVersionDto>;
}
/**
 * 
 * @export
 * @interface CreatePlanDto
 */
export interface CreatePlanDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePlanDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanDto
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface CreateRackDto
 */
export interface CreateRackDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRackDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface CreateRefunds
 */
export interface CreateRefunds {
    /**
     * 
     * @type {string}
     * @memberof CreateRefunds
     */
    'transaction': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRefunds
     */
    'async': boolean;
}
/**
 * 
 * @export
 * @interface CreateShipToBuyerDto
 */
export interface CreateShipToBuyerDto {
    /**
     * 
     * @type {number}
     * @memberof CreateShipToBuyerDto
     */
    'storageItemId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'hsCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'ioss': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'shippingAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'shippingAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'shippingName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'shippingTelNo': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'shippingZipCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'shippingCarrier': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'shippingPlan': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'shippingRate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'lineItemId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateShipToBuyerDto
     */
    'quantityPurchased': number;
    /**
     * 
     * @type {number}
     * @memberof CreateShipToBuyerDto
     */
    'orderAccountEbayId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'orderBuyerCountry': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'orderBuyerId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateShipToBuyerDto
     */
    'orderItemPrice': number;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'orderTransactionId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'orderShippingAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'orderShippingAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'orderShippingName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'orderShippingTelNo': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'orderStatus': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'orderItemPriceCurrency': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'orderItemId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'orderItemName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'orderItemSKU': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'orderBuyerEiasToken': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'orderShippingZipCode': string;
    /**
     * 
     * @type {number}
     * @memberof CreateShipToBuyerDto
     */
    'itemPrice': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShipToBuyerDto
     */
    'isInsurance': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateShipToBuyerDto
     */
    'shipByDate'?: string;
}
/**
 * 
 * @export
 * @interface CreateShipmentDto
 */
export interface CreateShipmentDto {
    /**
     * 
     * @type {CreateShipmentDtoAddressFrom}
     * @memberof CreateShipmentDto
     */
    'address_from'?: CreateShipmentDtoAddressFrom;
    /**
     * 
     * @type {CreateShipmentDtoAddressReturn}
     * @memberof CreateShipmentDto
     */
    'address_return'?: CreateShipmentDtoAddressReturn;
    /**
     * 
     * @type {CreateShipmentDtoAddressTo}
     * @memberof CreateShipmentDto
     */
    'address_to'?: CreateShipmentDtoAddressTo;
    /**
     * List of Carrier Accounts object_ids used to filter the returned rates. If set, only rates from these carriers will be returned
     * @type {Array<string>}
     * @memberof CreateShipmentDto
     */
    'carrierAccounts'?: Array<string>;
    /**
     * 
     * @type {Array<ParcelsDto>}
     * @memberof CreateShipmentDto
     */
    'parcels': Array<ParcelsDto>;
    /**
     * 
     * @type {number}
     * @memberof CreateShipmentDto
     */
    'ebayAccountId'?: number;
    /**
     * 
     * @type {CreateShipmentDtoCustomsDeclaration}
     * @memberof CreateShipmentDto
     */
    'customs_declaration'?: CreateShipmentDtoCustomsDeclaration;
}
/**
 * addressee from
 * @export
 * @interface CreateShipmentDtoAddressFrom
 */
export interface CreateShipmentDtoAddressFrom {
    /**
     * First and Last Name of the addressee
     * @type {string}
     * @memberof CreateShipmentDtoAddressFrom
     */
    'name': string;
    /**
     * Company Name
     * @type {string}
     * @memberof CreateShipmentDtoAddressFrom
     */
    'company'?: string;
    /**
     * street number and street name
     * @type {string}
     * @memberof CreateShipmentDtoAddressFrom
     */
    'street1': string;
    /**
     * street number and street name
     * @type {string}
     * @memberof CreateShipmentDtoAddressFrom
     */
    'street2'?: string;
    /**
     * Name of a city
     * @type {string}
     * @memberof CreateShipmentDtoAddressFrom
     */
    'city': string;
    /**
     * Most carriers only accept two or three character state abbreviations.
     * @type {string}
     * @memberof CreateShipmentDtoAddressFrom
     */
    'state': string;
    /**
     * Postal code of an Address
     * @type {string}
     * @memberof CreateShipmentDtoAddressFrom
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoAddressFrom
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoAddressFrom
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoAddressFrom
     */
    'email'?: string;
    /**
     * A string of up to 100 characters that can be filled with any additional information you want to attach to the object
     * @type {string}
     * @memberof CreateShipmentDtoAddressFrom
     */
    'metadata'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShipmentDtoAddressFrom
     */
    'validate'?: boolean;
}
/**
 * addressee return
 * @export
 * @interface CreateShipmentDtoAddressReturn
 */
export interface CreateShipmentDtoAddressReturn {
    /**
     * First and Last Name of the addressee
     * @type {string}
     * @memberof CreateShipmentDtoAddressReturn
     */
    'name': string;
    /**
     * Company Name
     * @type {string}
     * @memberof CreateShipmentDtoAddressReturn
     */
    'company'?: string;
    /**
     * street number and street name
     * @type {string}
     * @memberof CreateShipmentDtoAddressReturn
     */
    'street1': string;
    /**
     * street number and street name
     * @type {string}
     * @memberof CreateShipmentDtoAddressReturn
     */
    'street2'?: string;
    /**
     * Name of a city
     * @type {string}
     * @memberof CreateShipmentDtoAddressReturn
     */
    'city': string;
    /**
     * Most carriers only accept two or three character state abbreviations.
     * @type {string}
     * @memberof CreateShipmentDtoAddressReturn
     */
    'state': string;
    /**
     * Postal code of an Address
     * @type {string}
     * @memberof CreateShipmentDtoAddressReturn
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoAddressReturn
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoAddressReturn
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoAddressReturn
     */
    'email'?: string;
    /**
     * A string of up to 100 characters that can be filled with any additional information you want to attach to the object
     * @type {string}
     * @memberof CreateShipmentDtoAddressReturn
     */
    'metadata'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShipmentDtoAddressReturn
     */
    'validate'?: boolean;
}
/**
 * addressee to
 * @export
 * @interface CreateShipmentDtoAddressTo
 */
export interface CreateShipmentDtoAddressTo {
    /**
     * First and Last Name of the addressee
     * @type {string}
     * @memberof CreateShipmentDtoAddressTo
     */
    'name': string;
    /**
     * Company Name
     * @type {string}
     * @memberof CreateShipmentDtoAddressTo
     */
    'company'?: string;
    /**
     * street number and street name
     * @type {string}
     * @memberof CreateShipmentDtoAddressTo
     */
    'street1': string;
    /**
     * street number and street name
     * @type {string}
     * @memberof CreateShipmentDtoAddressTo
     */
    'street2'?: string;
    /**
     * Name of a city
     * @type {string}
     * @memberof CreateShipmentDtoAddressTo
     */
    'city': string;
    /**
     * Most carriers only accept two or three character state abbreviations.
     * @type {string}
     * @memberof CreateShipmentDtoAddressTo
     */
    'state': string;
    /**
     * Postal code of an Address
     * @type {string}
     * @memberof CreateShipmentDtoAddressTo
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoAddressTo
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoAddressTo
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoAddressTo
     */
    'email'?: string;
    /**
     * A string of up to 100 characters that can be filled with any additional information you want to attach to the object
     * @type {string}
     * @memberof CreateShipmentDtoAddressTo
     */
    'metadata'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShipmentDtoAddressTo
     */
    'validate'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateShipmentDtoCustomsDeclaration
 */
export interface CreateShipmentDtoCustomsDeclaration {
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'aes_itn'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'b13a_filing_option'?: CreateShipmentDtoCustomsDeclarationB13aFilingOptionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'b13a_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'certificate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'certify'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'certify_signer'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'commercial_invoice'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'contents_explanation'?: string;
    /**
     * 
     * @type {ExporterIdentificationDto}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'exporter_identification'?: ExporterIdentificationDto;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'disclaimer'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'exporter_reference'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'importer_reference'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'is_vat_collected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'invoice'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'license'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'metadata'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'notes'?: string;
    /**
     * 
     * @type {AddressImporterDto}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'address_importer'?: AddressImporterDto;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'contents_type'?: CreateShipmentDtoCustomsDeclarationContentsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'eel_pfc'?: CreateShipmentDtoCustomsDeclarationEelPfcEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'incoterm'?: CreateShipmentDtoCustomsDeclarationIncotermEnum;
    /**
     * 
     * @type {Array<CustomsItemCreateRequestDto>}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'items': Array<CustomsItemCreateRequestDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'non_delivery_option'?: CreateShipmentDtoCustomsDeclarationNonDeliveryOptionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShipmentDtoCustomsDeclaration
     */
    'test'?: boolean;
}

export const CreateShipmentDtoCustomsDeclarationB13aFilingOptionEnum = {
    FiledElectronically: 'FILED_ELECTRONICALLY',
    SummaryReporting: 'SUMMARY_REPORTING',
    NotRequired: 'NOT_REQUIRED'
} as const;

export type CreateShipmentDtoCustomsDeclarationB13aFilingOptionEnum = typeof CreateShipmentDtoCustomsDeclarationB13aFilingOptionEnum[keyof typeof CreateShipmentDtoCustomsDeclarationB13aFilingOptionEnum];
export const CreateShipmentDtoCustomsDeclarationContentsTypeEnum = {
    Documents: 'DOCUMENTS',
    Gift: 'GIFT',
    Sample: 'SAMPLE',
    Merchandise: 'MERCHANDISE',
    HumanitarianDonation: 'HUMANITARIAN_DONATION',
    ReturnMerchandise: 'RETURN_MERCHANDISE',
    Other: 'OTHER'
} as const;

export type CreateShipmentDtoCustomsDeclarationContentsTypeEnum = typeof CreateShipmentDtoCustomsDeclarationContentsTypeEnum[keyof typeof CreateShipmentDtoCustomsDeclarationContentsTypeEnum];
export const CreateShipmentDtoCustomsDeclarationEelPfcEnum = {
    Noeei3037A: 'NOEEI_30_37_a',
    Noeei3037H: 'NOEEI_30_37_h',
    Noeei3037F: 'NOEEI_30_37_f',
    Noeei3036: 'NOEEI_30_36',
    AesItn: 'AES_ITN'
} as const;

export type CreateShipmentDtoCustomsDeclarationEelPfcEnum = typeof CreateShipmentDtoCustomsDeclarationEelPfcEnum[keyof typeof CreateShipmentDtoCustomsDeclarationEelPfcEnum];
export const CreateShipmentDtoCustomsDeclarationIncotermEnum = {
    Ddp: 'DDP',
    Ddu: 'DDU',
    Fca: 'FCA',
    Dap: 'DAP',
    EDap: 'eDAP'
} as const;

export type CreateShipmentDtoCustomsDeclarationIncotermEnum = typeof CreateShipmentDtoCustomsDeclarationIncotermEnum[keyof typeof CreateShipmentDtoCustomsDeclarationIncotermEnum];
export const CreateShipmentDtoCustomsDeclarationNonDeliveryOptionEnum = {
    Abandon: 'ABANDON',
    Return: 'RETURN'
} as const;

export type CreateShipmentDtoCustomsDeclarationNonDeliveryOptionEnum = typeof CreateShipmentDtoCustomsDeclarationNonDeliveryOptionEnum[keyof typeof CreateShipmentDtoCustomsDeclarationNonDeliveryOptionEnum];

/**
 * 
 * @export
 * @interface CreateShipmentResponse
 */
export interface CreateShipmentResponse {
    /**
     * 
     * @type {object}
     * @memberof CreateShipmentResponse
     */
    'extra'?: object;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentResponse
     */
    'metadata': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentResponse
     */
    'shipmentDate'?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateShipmentResponse
     */
    'addressFrom': object;
    /**
     * 
     * @type {object}
     * @memberof CreateShipmentResponse
     */
    'addressReturn'?: object;
    /**
     * 
     * @type {object}
     * @memberof CreateShipmentResponse
     */
    'addressTo': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateShipmentResponse
     */
    'carrierAccounts': Array<string>;
    /**
     * 
     * @type {object}
     * @memberof CreateShipmentResponse
     */
    'customsDeclaration'?: object;
    /**
     * 
     * @type {Array<object>}
     * @memberof CreateShipmentResponse
     */
    'messages': Array<object>;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentResponse
     */
    'objectCreated': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentResponse
     */
    'objectId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentResponse
     */
    'objectOwner': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentResponse
     */
    'objectUpdated': string;
    /**
     * 
     * @type {Array<object>}
     * @memberof CreateShipmentResponse
     */
    'parcels': Array<object>;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentResponse
     */
    'status': CreateShipmentResponseStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShipmentResponse
     */
    'test'?: boolean;
    /**
     * 
     * @type {Rate}
     * @memberof CreateShipmentResponse
     */
    'rates': Rate;
}

export const CreateShipmentResponseStatusEnum = {
    Error: 'ERROR',
    Queued: 'QUEUED',
    Success: 'SUCCESS',
    Status: 'STATUS'
} as const;

export type CreateShipmentResponseStatusEnum = typeof CreateShipmentResponseStatusEnum[keyof typeof CreateShipmentResponseStatusEnum];

/**
 * 
 * @export
 * @interface CreateShippingFulfillmentDetailsDto
 */
export interface CreateShippingFulfillmentDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof CreateShippingFulfillmentDetailsDto
     */
    'orderId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateShippingFulfillmentDetailsDto
     */
    'userEbayId': number;
    /**
     * 
     * @type {ShippingFulfillmentDetails}
     * @memberof CreateShippingFulfillmentDetailsDto
     */
    'detail': ShippingFulfillmentDetails;
}
/**
 * 
 * @export
 * @interface CreateSingleCouponDto
 */
export interface CreateSingleCouponDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSingleCouponDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleCouponDto
     */
    'expiredDate': string;
    /**
     * 
     * @type {number}
     * @memberof CreateSingleCouponDto
     */
    'discountRate': number;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleCouponDto
     */
    'userCode': string;
}
/**
 * 
 * @export
 * @interface CreateStorageItemNoteDto
 */
export interface CreateStorageItemNoteDto {
    /**
     * 
     * @type {number}
     * @memberof CreateStorageItemNoteDto
     */
    'storageItemId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateStorageItemNoteDto
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface CreateStorageRequestDto
 */
export interface CreateStorageRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateStorageRequestDto
     */
    'sellerPay': boolean;
    /**
     * 
     * @type {StorageItemDto}
     * @memberof CreateStorageRequestDto
     */
    'item': StorageItemDto;
    /**
     * 
     * @type {StorageRequestDto}
     * @memberof CreateStorageRequestDto
     */
    'request': StorageRequestDto;
    /**
     * 
     * @type {number}
     * @memberof CreateStorageRequestDto
     */
    'userId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateStorageRequestDto
     */
    'upload'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateStorageRequestExtraFeeResponse
 */
export interface CreateStorageRequestExtraFeeResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateStorageRequestExtraFeeResponse
     */
    'insuranceFee': number;
    /**
     * 
     * @type {number}
     * @memberof CreateStorageRequestExtraFeeResponse
     */
    'unboxVideoFee': number;
    /**
     * 
     * @type {Array<PatternBaseFee>}
     * @memberof CreateStorageRequestExtraFeeResponse
     */
    'patternBaseFee': Array<PatternBaseFee>;
}
/**
 * 
 * @export
 * @interface CreateSubUserDto
 */
export interface CreateSubUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSubUserDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubUserDto
     */
    'name': string;
    /**
     * 
     * @type {CreateSubUserDtoPermission}
     * @memberof CreateSubUserDto
     */
    'permission': CreateSubUserDtoPermission;
}
/**
 * 
 * @export
 * @interface CreateSubUserDtoPermission
 */
export interface CreateSubUserDtoPermission {
    /**
     * 
     * @type {boolean}
     * @memberof CreateSubUserDtoPermission
     */
    'Authenticated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSubUserDtoPermission
     */
    'ProductManagement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSubUserDtoPermission
     */
    'BizNotification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSubUserDtoPermission
     */
    'BizNotificationMail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSubUserDtoPermission
     */
    'AccountManagement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSubUserDtoPermission
     */
    'Setting'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSubUserDtoPermission
     */
    'PaymentManagement'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateTransaction
 */
export interface CreateTransaction {
    /**
     * 
     * @type {boolean}
     * @memberof CreateTransaction
     */
    'async'?: boolean;
    /**
     * Print format of the label
     * @type {string}
     * @memberof CreateTransaction
     */
    'label_file_type'?: CreateTransactionLabelFileTypeEnum;
    /**
     * note
     * @type {string}
     * @memberof CreateTransaction
     */
    'metadata'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransaction
     */
    'rate': string;
}

export const CreateTransactionLabelFileTypeEnum = {
    Png: 'PNG',
    Png23x75: 'PNG_2.3x7.5',
    Pdf: 'PDF',
    Pdf23x75: 'PDF_2.3x7.5',
    Pdf4x6: 'PDF_4x6',
    Pdf4x8: 'PDF_4x8',
    PdfA4: 'PDF_A4',
    PdfA5: 'PDF_A5',
    PdfA6: 'PDF_A6',
    Zplii: 'ZPLII'
} as const;

export type CreateTransactionLabelFileTypeEnum = typeof CreateTransactionLabelFileTypeEnum[keyof typeof CreateTransactionLabelFileTypeEnum];

/**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'currency': CurrencyCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'value': string;
}

export const CurrencyCurrencyEnum = {
    Aed: 'AED',
    Afn: 'AFN',
    All: 'ALL',
    Amd: 'AMD',
    Ang: 'ANG',
    Aoa: 'AOA',
    Ars: 'ARS',
    Aud: 'AUD',
    Awg: 'AWG',
    Azn: 'AZN',
    Bam: 'BAM',
    Bbd: 'BBD',
    Bdt: 'BDT',
    Bgn: 'BGN',
    Bhd: 'BHD',
    Bif: 'BIF',
    Bmd: 'BMD',
    Bnd: 'BND',
    Bob: 'BOB',
    Brl: 'BRL',
    Bsd: 'BSD',
    Btn: 'BTN',
    Bwp: 'BWP',
    Byr: 'BYR',
    Bzd: 'BZD',
    Cad: 'CAD',
    Cdf: 'CDF',
    Chf: 'CHF',
    Clp: 'CLP',
    Cny: 'CNY',
    Cop: 'COP',
    Crc: 'CRC',
    Cup: 'CUP',
    Cve: 'CVE',
    Czk: 'CZK',
    Djf: 'DJF',
    Dkk: 'DKK',
    Dop: 'DOP',
    Dzd: 'DZD',
    Egp: 'EGP',
    Ern: 'ERN',
    Etb: 'ETB',
    Eur: 'EUR',
    Fjd: 'FJD',
    Fkp: 'FKP',
    Gbp: 'GBP',
    Gel: 'GEL',
    Ghs: 'GHS',
    Gip: 'GIP',
    Gmd: 'GMD',
    Gnf: 'GNF',
    Gtq: 'GTQ',
    Gyd: 'GYD',
    Hkd: 'HKD',
    Hnl: 'HNL',
    Hrk: 'HRK',
    Htg: 'HTG',
    Huf: 'HUF',
    Idr: 'IDR',
    Ils: 'ILS',
    Inr: 'INR',
    Iqd: 'IQD',
    Irr: 'IRR',
    Isk: 'ISK',
    Jmd: 'JMD',
    Jod: 'JOD',
    Jpy: 'JPY',
    Kes: 'KES',
    Kgs: 'KGS',
    Khr: 'KHR',
    Kmf: 'KMF',
    Kpw: 'KPW',
    Krw: 'KRW',
    Kwd: 'KWD',
    Kyd: 'KYD',
    Kzt: 'KZT',
    Lak: 'LAK',
    Lbp: 'LBP',
    Lkr: 'LKR',
    Lrd: 'LRD',
    Lsl: 'LSL',
    Ltl: 'LTL',
    Lyd: 'LYD',
    Mad: 'MAD',
    Mdl: 'MDL',
    Mga: 'MGA',
    Mkd: 'MKD',
    Mmk: 'MMK',
    Mnt: 'MNT',
    Mop: 'MOP',
    Mro: 'MRO',
    Mur: 'MUR',
    Mvr: 'MVR',
    Mwk: 'MWK',
    Mxn: 'MXN',
    Myr: 'MYR',
    Mzn: 'MZN',
    Nad: 'NAD',
    Ngn: 'NGN',
    Nio: 'NIO',
    Nok: 'NOK',
    Npr: 'NPR',
    Nzd: 'NZD',
    Omr: 'OMR',
    Pab: 'PAB',
    Pen: 'PEN',
    Pgk: 'PGK',
    Php: 'PHP',
    Pkr: 'PKR',
    Pln: 'PLN',
    Pyg: 'PYG',
    Qar: 'QAR',
    Ron: 'RON',
    Rsd: 'RSD',
    Rub: 'RUB',
    Rwf: 'RWF',
    Sar: 'SAR',
    Sbd: 'SBD',
    Scr: 'SCR',
    Sdg: 'SDG',
    Sek: 'SEK',
    Sgd: 'SGD',
    Shp: 'SHP',
    Sll: 'SLL',
    Sos: 'SOS',
    Srd: 'SRD',
    Std: 'STD',
    Syp: 'SYP',
    Szl: 'SZL',
    Thb: 'THB',
    Tjs: 'TJS',
    Tmt: 'TMT',
    Tnd: 'TND',
    Top: 'TOP',
    Try: 'TRY',
    Ttd: 'TTD',
    Twd: 'TWD',
    Tzs: 'TZS',
    Uah: 'UAH',
    Ugx: 'UGX',
    Usd: 'USD',
    Uyu: 'UYU',
    Uzs: 'UZS',
    Vef: 'VEF',
    Vnd: 'VND',
    Vuv: 'VUV',
    Wst: 'WST',
    Xaf: 'XAF',
    Xcd: 'XCD',
    Xof: 'XOF',
    Xpf: 'XPF',
    Yer: 'YER',
    Zar: 'ZAR',
    Zmw: 'ZMW',
    Zwl: 'ZWL'
} as const;

export type CurrencyCurrencyEnum = typeof CurrencyCurrencyEnum[keyof typeof CurrencyCurrencyEnum];

/**
 * 
 * @export
 * @interface CurrencyID
 */
export interface CurrencyID {
    /**
     * 
     * @type {string}
     * @memberof CurrencyID
     */
    'currencyID': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyID
     */
    '_@ttribute': string;
}
/**
 * 
 * @export
 * @interface CustomOrderFromEbay
 */
export interface CustomOrderFromEbay {
    /**
     * 
     * @type {string}
     * @memberof CustomOrderFromEbay
     */
    'avatar': string;
    /**
     * 
     * @type {string}
     * @memberof CustomOrderFromEbay
     */
    'accountEbayName': string;
    /**
     * 
     * @type {LineItem}
     * @memberof CustomOrderFromEbay
     */
    'lineItems': LineItem;
    /**
     * 
     * @type {BuyerEbay}
     * @memberof CustomOrderFromEbay
     */
    'buyer': BuyerEbay;
    /**
     * 
     * @type {string}
     * @memberof CustomOrderFromEbay
     */
    'sellerId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomOrderFromEbay
     */
    'orderId': string;
    /**
     * 
     * @type {Array<FulfillmentStartInstructions>}
     * @memberof CustomOrderFromEbay
     */
    'fulfillmentStartInstructions': Array<FulfillmentStartInstructions>;
}
/**
 * 
 * @export
 * @interface CustomerMeResponse
 */
export interface CustomerMeResponse {
    /**
     * 
     * @type {string}
     * @memberof CustomerMeResponse
     */
    'userCode': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMeResponse
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMeResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMeResponse
     */
    'password': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerMeResponse
     */
    'freeTrialMonths': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerMeResponse
     */
    'freeTrialRemaining': number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerMeResponse
     */
    'membershipStatus': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerMeResponse
     */
    'unsubcriptionAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMeResponse
     */
    'membershipOutAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerMeResponse
     */
    'autoPaymentFailFlg': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerMeResponse
     */
    'blockedFlg': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerMeResponse
     */
    'activeFlg': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerMeResponse
     */
    'permissions': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CustomerMeResponse
     */
    'avatarId': number;
    /**
     * 
     * @type {Asset}
     * @memberof CustomerMeResponse
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof CustomerMeResponse
     */
    'payment': Array<Payment>;
    /**
     * 
     * @type {Array<AccountEbay>}
     * @memberof CustomerMeResponse
     */
    'AccountEbay'?: Array<AccountEbay>;
    /**
     * 
     * @type {number}
     * @memberof CustomerMeResponse
     */
    'parentId': number;
    /**
     * 
     * @type {User}
     * @memberof CustomerMeResponse
     */
    'parent': User;
    /**
     * 
     * @type {Array<User>}
     * @memberof CustomerMeResponse
     */
    'children': Array<User>;
    /**
     * 
     * @type {UserPlan}
     * @memberof CustomerMeResponse
     */
    'userPlan': UserPlan;
    /**
     * 
     * @type {UserSetting}
     * @memberof CustomerMeResponse
     */
    'userSetting': UserSetting;
    /**
     * 
     * @type {UserCard}
     * @memberof CustomerMeResponse
     */
    'userCard': UserCard;
    /**
     * 
     * @type {SettingNotification}
     * @memberof CustomerMeResponse
     */
    'settingNotification': SettingNotification;
    /**
     * 
     * @type {number}
     * @memberof CustomerMeResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerMeResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMeResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMeResponse
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface CustomsDeclarationCreateRequestDto
 */
export interface CustomsDeclarationCreateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'aes_itn'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'b13a_filing_option'?: CustomsDeclarationCreateRequestDtoB13aFilingOptionEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'b13a_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'certificate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'certify'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'certify_signer'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'commercial_invoice'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'contents_explanation'?: string;
    /**
     * 
     * @type {ExporterIdentificationDto}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'exporter_identification'?: ExporterIdentificationDto;
    /**
     * 
     * @type {string}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'disclaimer'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'exporter_reference'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'importer_reference'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'is_vat_collected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'invoice'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'license'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'metadata'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'notes'?: string;
    /**
     * 
     * @type {AddressImporterDto}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'address_importer'?: AddressImporterDto;
    /**
     * 
     * @type {string}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'contents_type'?: CustomsDeclarationCreateRequestDtoContentsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'eel_pfc'?: CustomsDeclarationCreateRequestDtoEelPfcEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'incoterm'?: CustomsDeclarationCreateRequestDtoIncotermEnum;
    /**
     * 
     * @type {Array<CustomsItemCreateRequestDto>}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'items': Array<CustomsItemCreateRequestDto>;
    /**
     * 
     * @type {string}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'non_delivery_option'?: CustomsDeclarationCreateRequestDtoNonDeliveryOptionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CustomsDeclarationCreateRequestDto
     */
    'test'?: boolean;
}

export const CustomsDeclarationCreateRequestDtoB13aFilingOptionEnum = {
    FiledElectronically: 'FILED_ELECTRONICALLY',
    SummaryReporting: 'SUMMARY_REPORTING',
    NotRequired: 'NOT_REQUIRED'
} as const;

export type CustomsDeclarationCreateRequestDtoB13aFilingOptionEnum = typeof CustomsDeclarationCreateRequestDtoB13aFilingOptionEnum[keyof typeof CustomsDeclarationCreateRequestDtoB13aFilingOptionEnum];
export const CustomsDeclarationCreateRequestDtoContentsTypeEnum = {
    Documents: 'DOCUMENTS',
    Gift: 'GIFT',
    Sample: 'SAMPLE',
    Merchandise: 'MERCHANDISE',
    HumanitarianDonation: 'HUMANITARIAN_DONATION',
    ReturnMerchandise: 'RETURN_MERCHANDISE',
    Other: 'OTHER'
} as const;

export type CustomsDeclarationCreateRequestDtoContentsTypeEnum = typeof CustomsDeclarationCreateRequestDtoContentsTypeEnum[keyof typeof CustomsDeclarationCreateRequestDtoContentsTypeEnum];
export const CustomsDeclarationCreateRequestDtoEelPfcEnum = {
    Noeei3037A: 'NOEEI_30_37_a',
    Noeei3037H: 'NOEEI_30_37_h',
    Noeei3037F: 'NOEEI_30_37_f',
    Noeei3036: 'NOEEI_30_36',
    AesItn: 'AES_ITN'
} as const;

export type CustomsDeclarationCreateRequestDtoEelPfcEnum = typeof CustomsDeclarationCreateRequestDtoEelPfcEnum[keyof typeof CustomsDeclarationCreateRequestDtoEelPfcEnum];
export const CustomsDeclarationCreateRequestDtoIncotermEnum = {
    Ddp: 'DDP',
    Ddu: 'DDU',
    Fca: 'FCA',
    Dap: 'DAP',
    EDap: 'eDAP'
} as const;

export type CustomsDeclarationCreateRequestDtoIncotermEnum = typeof CustomsDeclarationCreateRequestDtoIncotermEnum[keyof typeof CustomsDeclarationCreateRequestDtoIncotermEnum];
export const CustomsDeclarationCreateRequestDtoNonDeliveryOptionEnum = {
    Abandon: 'ABANDON',
    Return: 'RETURN'
} as const;

export type CustomsDeclarationCreateRequestDtoNonDeliveryOptionEnum = typeof CustomsDeclarationCreateRequestDtoNonDeliveryOptionEnum[keyof typeof CustomsDeclarationCreateRequestDtoNonDeliveryOptionEnum];

/**
 * 
 * @export
 * @interface CustomsItemCreateRequestDto
 */
export interface CustomsItemCreateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CustomsItemCreateRequestDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CustomsItemCreateRequestDto
     */
    'eccn_ear99'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomsItemCreateRequestDto
     */
    'mass_unit': CustomsItemCreateRequestDtoMassUnitEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomsItemCreateRequestDto
     */
    'metadata'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomsItemCreateRequestDto
     */
    'net_weight': string;
    /**
     * 
     * @type {string}
     * @memberof CustomsItemCreateRequestDto
     */
    'origin_country': string;
    /**
     * 
     * @type {number}
     * @memberof CustomsItemCreateRequestDto
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof CustomsItemCreateRequestDto
     */
    'sku_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomsItemCreateRequestDto
     */
    'hs_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomsItemCreateRequestDto
     */
    'tariff_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomsItemCreateRequestDto
     */
    'value_amount': string;
    /**
     * 
     * @type {string}
     * @memberof CustomsItemCreateRequestDto
     */
    'value_currency': string;
}

export const CustomsItemCreateRequestDtoMassUnitEnum = {
    G: 'g',
    Kg: 'kg',
    Lb: 'lb',
    Oz: 'oz'
} as const;

export type CustomsItemCreateRequestDtoMassUnitEnum = typeof CustomsItemCreateRequestDtoMassUnitEnum[keyof typeof CustomsItemCreateRequestDtoMassUnitEnum];

/**
 * 
 * @export
 * @interface DeleteAssetDto
 */
export interface DeleteAssetDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteAssetDto
     */
    'type': DeleteAssetDtoTypeEnum;
}

export const DeleteAssetDtoTypeEnum = {
    Hard: 'HARD',
    Canceled: 'CANCELED'
} as const;

export type DeleteAssetDtoTypeEnum = typeof DeleteAssetDtoTypeEnum[keyof typeof DeleteAssetDtoTypeEnum];

/**
 * 
 * @export
 * @interface DisposalRequest
 */
export interface DisposalRequest {
    /**
     * 
     * @type {number}
     * @memberof DisposalRequest
     */
    'requestedUserId': number;
    /**
     * 
     * @type {User}
     * @memberof DisposalRequest
     */
    'requestedUser': User;
    /**
     * 
     * @type {number}
     * @memberof DisposalRequest
     */
    'storageItemId': number;
    /**
     * 
     * @type {StorageItem}
     * @memberof DisposalRequest
     */
    'storageItem': StorageItem;
    /**
     * 
     * @type {string}
     * @memberof DisposalRequest
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof DisposalRequest
     */
    'disposedStaffId': number;
    /**
     * 
     * @type {Admin}
     * @memberof DisposalRequest
     */
    'disposedStaff': Admin;
    /**
     * 
     * @type {string}
     * @memberof DisposalRequest
     */
    'disposedAt': string;
    /**
     * 
     * @type {number}
     * @memberof DisposalRequest
     */
    'chargeAmount': number;
    /**
     * 
     * @type {number}
     * @memberof DisposalRequest
     */
    'chargeAmountYen': number;
    /**
     * 
     * @type {number}
     * @memberof DisposalRequest
     */
    'refundRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisposalRequest
     */
    'paymentId': number;
    /**
     * 
     * @type {Payment}
     * @memberof DisposalRequest
     */
    'payment'?: Payment;
    /**
     * 
     * @type {string}
     * @memberof DisposalRequest
     */
    'refundAt': string;
    /**
     * 
     * @type {number}
     * @memberof DisposalRequest
     */
    'refundAmountYen': number;
    /**
     * 
     * @type {number}
     * @memberof DisposalRequest
     */
    'refundPaymentId': number;
    /**
     * 
     * @type {Payment}
     * @memberof DisposalRequest
     */
    'refundPayment': Payment;
    /**
     * 
     * @type {number}
     * @memberof DisposalRequest
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DisposalRequest
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DisposalRequest
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof DisposalRequest
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface EbayCollectAndRemitTaxes
 */
export interface EbayCollectAndRemitTaxes {
    /**
     * 
     * @type {Amount}
     * @memberof EbayCollectAndRemitTaxes
     */
    'TAmount': Amount;
    /**
     * 
     * @type {EbayCollectAndRemitTaxesEbayReference}
     * @memberof EbayCollectAndRemitTaxes
     */
    'ebayReference': EbayCollectAndRemitTaxesEbayReference;
    /**
     * 
     * @type {string}
     * @memberof EbayCollectAndRemitTaxes
     */
    'taxType': string;
    /**
     * 
     * @type {string}
     * @memberof EbayCollectAndRemitTaxes
     */
    'collectionMethod': string;
}
/**
 * 
 * @export
 * @interface EbayCollectAndRemitTaxesEbayReference
 */
export interface EbayCollectAndRemitTaxesEbayReference {
    /**
     * 
     * @type {string}
     * @memberof EbayCollectAndRemitTaxesEbayReference
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EbayCollectAndRemitTaxesEbayReference
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface EbayManagerControllerGetAllAccountEbay200Response
 */
export interface EbayManagerControllerGetAllAccountEbay200Response {
    /**
     * 
     * @type {number}
     * @memberof EbayManagerControllerGetAllAccountEbay200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof EbayManagerControllerGetAllAccountEbay200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof EbayManagerControllerGetAllAccountEbay200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<AccountEbayPagination>}
     * @memberof EbayManagerControllerGetAllAccountEbay200Response
     */
    'content'?: Array<AccountEbayPagination>;
}
/**
 * 
 * @export
 * @interface EbayManagerControllerGetAllAccountEbay200ResponseAllOf
 */
export interface EbayManagerControllerGetAllAccountEbay200ResponseAllOf {
    /**
     * 
     * @type {Array<AccountEbayPagination>}
     * @memberof EbayManagerControllerGetAllAccountEbay200ResponseAllOf
     */
    'content'?: Array<AccountEbayPagination>;
}
/**
 * 
 * @export
 * @interface EbayReturnControllerGetAllEbayReturnsOfUser200Response
 */
export interface EbayReturnControllerGetAllEbayReturnsOfUser200Response {
    /**
     * 
     * @type {number}
     * @memberof EbayReturnControllerGetAllEbayReturnsOfUser200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof EbayReturnControllerGetAllEbayReturnsOfUser200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof EbayReturnControllerGetAllEbayReturnsOfUser200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<EbayReturnsPagination>}
     * @memberof EbayReturnControllerGetAllEbayReturnsOfUser200Response
     */
    'content'?: Array<EbayReturnsPagination>;
}
/**
 * 
 * @export
 * @interface EbayReturnControllerGetAllEbayReturnsOfUser200ResponseAllOf
 */
export interface EbayReturnControllerGetAllEbayReturnsOfUser200ResponseAllOf {
    /**
     * 
     * @type {Array<EbayReturnsPagination>}
     * @memberof EbayReturnControllerGetAllEbayReturnsOfUser200ResponseAllOf
     */
    'content'?: Array<EbayReturnsPagination>;
}
/**
 * 
 * @export
 * @interface EbayReturns
 */
export interface EbayReturns {
    /**
     * 
     * @type {number}
     * @memberof EbayReturns
     */
    'accountEbayId': number;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayReturnId': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayReturnStatusCode': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayReturnCreationDate': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayReturnDeadline': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayItemId': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayItemSku': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayItemName': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayItemImage': string;
    /**
     * 
     * @type {number}
     * @memberof EbayReturns
     */
    'ebayOrderItemPrice': number;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayOrderItemCurrency': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayOrderId': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayBuyerId': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayBuyerEiasToken': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayBuyerCountry': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayOrderShippingAddress': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayOrderShippingAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayOrderShippingStateOrProvince': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayOrderShippingCity': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayOrderShippingPostalCode': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayOrderShippingName': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'ebayOrderShippingTelNo': string;
    /**
     * 
     * @type {boolean}
     * @memberof EbayReturns
     */
    'storageRequestStatus': boolean;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'uploadFileId': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'actualDeliveryDate': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'payee': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'conditionDisplayName': string;
    /**
     * 
     * @type {AccountEbay}
     * @memberof EbayReturns
     */
    'accountEbay'?: AccountEbay;
    /**
     * 
     * @type {StorageItem}
     * @memberof EbayReturns
     */
    'storageItem'?: StorageItem;
    /**
     * 
     * @type {number}
     * @memberof EbayReturns
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturns
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface EbayReturnsPagination
 */
export interface EbayReturnsPagination {
    /**
     * 
     * @type {number}
     * @memberof EbayReturnsPagination
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'deletedAt': string;
    /**
     * 
     * @type {number}
     * @memberof EbayReturnsPagination
     */
    'accountEbayId': number;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayReturnId': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayReturnStatusCode': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayReturnCreationDate': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayReturnDeadline': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayItemId': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayItemSku': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayItemName': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayItemImage': string;
    /**
     * 
     * @type {number}
     * @memberof EbayReturnsPagination
     */
    'ebayOrderItemPrice': number;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayOrderItemCurrency': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayOrderId': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayBuyerId': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayBuyerEiasToken': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayBuyerCountry': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayOrderShippingAddress': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayOrderShippingAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayOrderShippingStateOrProvince': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayOrderShippingCity': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayOrderShippingPostalCode': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayOrderShippingName': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'ebayOrderShippingTelNo': string;
    /**
     * 
     * @type {boolean}
     * @memberof EbayReturnsPagination
     */
    'storageRequestStatus': boolean;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'uploadFileId': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'actualDeliveryDate': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'payee': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'conditionDisplayName': string;
    /**
     * 
     * @type {AccountEbay}
     * @memberof EbayReturnsPagination
     */
    'accountEbay'?: AccountEbay;
    /**
     * 
     * @type {StorageItem}
     * @memberof EbayReturnsPagination
     */
    'storageItem'?: StorageItem;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'userEbayId': string;
    /**
     * 
     * @type {string}
     * @memberof EbayReturnsPagination
     */
    'avatar': string;
}
/**
 * 
 * @export
 * @interface EditSingleCouponDto
 */
export interface EditSingleCouponDto {
    /**
     * 
     * @type {number}
     * @memberof EditSingleCouponDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EditSingleCouponDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditSingleCouponDto
     */
    'expiredDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditSingleCouponDto
     */
    'discountRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof EditSingleCouponDto
     */
    'userCode'?: string;
}
/**
 * 
 * @export
 * @interface EmailDto
 */
export interface EmailDto {
    /**
     * 
     * @type {string}
     * @memberof EmailDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface Errors
 */
export interface Errors {
    /**
     * 
     * @type {string}
     * @memberof Errors
     */
    'ShortMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Errors
     */
    'LongMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Errors
     */
    'ErrorCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Errors
     */
    'SeverityCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Errors
     */
    'ErrorClassification'?: string;
}
/**
 * 
 * @export
 * @interface ExchangeRate
 */
export interface ExchangeRate {
    /**
     * 
     * @type {string}
     * @memberof ExchangeRate
     */
    'fromTo': string;
    /**
     * 
     * @type {number}
     * @memberof ExchangeRate
     */
    'currentRate': number;
    /**
     * 
     * @type {number}
     * @memberof ExchangeRate
     */
    'nextDayRate': number;
    /**
     * 
     * @type {number}
     * @memberof ExchangeRate
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeRate
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeRate
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeRate
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface ExporterIdentificationDto
 */
export interface ExporterIdentificationDto {
    /**
     * 
     * @type {string}
     * @memberof ExporterIdentificationDto
     */
    'eori_number'?: string;
    /**
     * 
     * @type {ExporterIdentificationDtoTaxId}
     * @memberof ExporterIdentificationDto
     */
    'tax_id': ExporterIdentificationDtoTaxId;
}
/**
 * 
 * @export
 * @interface ExporterIdentificationDtoTaxId
 */
export interface ExporterIdentificationDtoTaxId {
    /**
     * 
     * @type {string}
     * @memberof ExporterIdentificationDtoTaxId
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExporterIdentificationDtoTaxId
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface FilesDto
 */
export interface FilesDto {
    /**
     * 
     * @type {string}
     * @memberof FilesDto
     */
    'source': string;
    /**
     * 
     * @type {number}
     * @memberof FilesDto
     */
    'assetId': number;
}
/**
 * 
 * @export
 * @interface FulfillmentPolicy
 */
export interface FulfillmentPolicy {
    /**
     * 
     * @type {Array<CategoryType>}
     * @memberof FulfillmentPolicy
     */
    'categoryTypes': Array<CategoryType>;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentPolicy
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentPolicy
     */
    'freightShipping': string;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentPolicy
     */
    'fulfillmentPolicyId': string;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentPolicy
     */
    'globalShipping': string;
    /**
     * 
     * @type {UnitTime}
     * @memberof FulfillmentPolicy
     */
    'handlingTime': UnitTime;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentPolicy
     */
    'localPickup': string;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentPolicy
     */
    'marketplaceId': string;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentPolicy
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentPolicy
     */
    'pickupDropOff': string;
    /**
     * 
     * @type {Array<ShippingOption>}
     * @memberof FulfillmentPolicy
     */
    'shippingOptions': Array<ShippingOption>;
    /**
     * 
     * @type {RegionFilter}
     * @memberof FulfillmentPolicy
     */
    'shipToLocations': RegionFilter;
}
/**
 * 
 * @export
 * @interface FulfillmentStartInstructions
 */
export interface FulfillmentStartInstructions {
    /**
     * 
     * @type {string}
     * @memberof FulfillmentStartInstructions
     */
    'destinationTimeZone': string;
    /**
     * 
     * @type {boolean}
     * @memberof FulfillmentStartInstructions
     */
    'ebaySupportedFulfillment': boolean;
    /**
     * 
     * @type {Address}
     * @memberof FulfillmentStartInstructions
     */
    'finalDestinationAddress': Address;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentStartInstructions
     */
    'fulfillmentInstructionsType': string;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentStartInstructions
     */
    'maxEstimatedDeliveryDate': string;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentStartInstructions
     */
    'minEstimatedDeliveryDate': string;
    /**
     * 
     * @type {FulfillmentStartInstructionsPickupStep}
     * @memberof FulfillmentStartInstructions
     */
    'pickupStep': FulfillmentStartInstructionsPickupStep;
    /**
     * 
     * @type {FulfillmentStartInstructionsShippingStep}
     * @memberof FulfillmentStartInstructions
     */
    'shippingStep': FulfillmentStartInstructionsShippingStep;
}
/**
 * 
 * @export
 * @interface FulfillmentStartInstructionsPickupStep
 */
export interface FulfillmentStartInstructionsPickupStep {
    /**
     * 
     * @type {string}
     * @memberof FulfillmentStartInstructionsPickupStep
     */
    'merchantLocationKey'?: string;
}
/**
 * 
 * @export
 * @interface FulfillmentStartInstructionsShippingStep
 */
export interface FulfillmentStartInstructionsShippingStep {
    /**
     * 
     * @type {string}
     * @memberof FulfillmentStartInstructionsShippingStep
     */
    'shippingCarrierCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentStartInstructionsShippingStep
     */
    'shippingServiceCode'?: string;
    /**
     * 
     * @type {BuyerEbayBuyerRegistrationAddress}
     * @memberof FulfillmentStartInstructionsShippingStep
     */
    'shipTo'?: BuyerEbayBuyerRegistrationAddress;
    /**
     * 
     * @type {string}
     * @memberof FulfillmentStartInstructionsShippingStep
     */
    'shipToReferenceId'?: string;
}
/**
 * 
 * @export
 * @interface GetDetailItemEbayXMLDto
 */
export interface GetDetailItemEbayXMLDto {
    /**
     * 
     * @type {string}
     * @memberof GetDetailItemEbayXMLDto
     */
    'itemId': string;
    /**
     * 
     * @type {number}
     * @memberof GetDetailItemEbayXMLDto
     */
    'userEbayId': number;
}
/**
 * 
 * @export
 * @interface GetShippingPolicy
 */
export interface GetShippingPolicy {
    /**
     * 
     * @type {Array<FulfillmentPolicy>}
     * @memberof GetShippingPolicy
     */
    'fulfillmentPolicies': Array<FulfillmentPolicy>;
    /**
     * 
     * @type {number}
     * @memberof GetShippingPolicy
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface GetUserDetailBasicResponseDTO
 */
export interface GetUserDetailBasicResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof GetUserDetailBasicResponseDTO
     */
    'userCode': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDetailBasicResponseDTO
     */
    'userName': string;
    /**
     * 
     * @type {number}
     * @memberof GetUserDetailBasicResponseDTO
     */
    'revenue': number;
    /**
     * 
     * @type {string}
     * @memberof GetUserDetailBasicResponseDTO
     */
    'userMail': string;
    /**
     * 
     * @type {number}
     * @memberof GetUserDetailBasicResponseDTO
     */
    'unPaid': number;
    /**
     * 
     * @type {string}
     * @memberof GetUserDetailBasicResponseDTO
     */
    'bizMail': string;
}
/**
 * 
 * @export
 * @interface GetUserDetailMembershipResponseDTO
 */
export interface GetUserDetailMembershipResponseDTO {
    /**
     * 
     * @type {boolean}
     * @memberof GetUserDetailMembershipResponseDTO
     */
    'isMembership': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserDetailMembershipResponseDTO
     */
    'isBlocked': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserDetailMembershipResponseDTO
     */
    'showDeleteButton': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUserDetailMembershipResponseDTO
     */
    'userCode': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDetailMembershipResponseDTO
     */
    'userName': string;
}
/**
 * 
 * @export
 * @interface GrantedLimitDto
 */
export interface GrantedLimitDto {
    /**
     * 
     * @type {string}
     * @memberof GrantedLimitDto
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof GrantedLimitDto
     */
    'itemId': string;
}
/**
 * 
 * @export
 * @interface HighBidder
 */
export interface HighBidder {
    /**
     * 
     * @type {string}
     * @memberof HighBidder
     */
    'AboutMePage': string;
    /**
     * 
     * @type {string}
     * @memberof HighBidder
     */
    'EIASToken': string;
    /**
     * 
     * @type {string}
     * @memberof HighBidder
     */
    'Email': string;
    /**
     * 
     * @type {string}
     * @memberof HighBidder
     */
    'FeedbackScore': string;
    /**
     * 
     * @type {string}
     * @memberof HighBidder
     */
    'PositiveFeedbackPercent': string;
    /**
     * 
     * @type {string}
     * @memberof HighBidder
     */
    'eBayGoodStanding': string;
    /**
     * 
     * @type {string}
     * @memberof HighBidder
     */
    'NewUser': string;
    /**
     * 
     * @type {string}
     * @memberof HighBidder
     */
    'RegistrationDate': string;
    /**
     * 
     * @type {string}
     * @memberof HighBidder
     */
    'Site': string;
    /**
     * 
     * @type {string}
     * @memberof HighBidder
     */
    'UserID': string;
    /**
     * 
     * @type {string}
     * @memberof HighBidder
     */
    'VATStatus': string;
    /**
     * 
     * @type {BuyerInfo}
     * @memberof HighBidder
     */
    'BuyerInfo': BuyerInfo;
    /**
     * 
     * @type {string}
     * @memberof HighBidder
     */
    'UserAnonymized': string;
}
/**
 * 
 * @export
 * @interface InternalMessageReadHistories
 */
export interface InternalMessageReadHistories {
    /**
     * 
     * @type {number}
     * @memberof InternalMessageReadHistories
     */
    'itemInternalMessageId': number;
    /**
     * 
     * @type {number}
     * @memberof InternalMessageReadHistories
     */
    'staffId': number;
    /**
     * 
     * @type {Admin}
     * @memberof InternalMessageReadHistories
     */
    'staff'?: Admin;
    /**
     * 
     * @type {ItemInternalMessages}
     * @memberof InternalMessageReadHistories
     */
    'itemInternalMessages'?: ItemInternalMessages;
    /**
     * 
     * @type {number}
     * @memberof InternalMessageReadHistories
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof InternalMessageReadHistories
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof InternalMessageReadHistories
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof InternalMessageReadHistories
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface Item
 */
export interface Item {
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'AutoPay': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'BuyerProtection': string;
    /**
     * 
     * @type {CurrencyID}
     * @memberof Item
     */
    'BuyItNowPrice': CurrencyID;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'Country': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'CurrencyID': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'ItemID': string;
    /**
     * 
     * @type {ListingDetails}
     * @memberof Item
     */
    'ListingDetails': ListingDetails;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'ListingDuration': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'ListingType': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'Location': string;
    /**
     * 
     * @type {PrimaryCategory}
     * @memberof Item
     */
    'PrimaryCategory': PrimaryCategory;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'PrivateListing': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'Quantity': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'IsItemEMSEligible': string;
    /**
     * 
     * @type {CurrencyID}
     * @memberof Item
     */
    'ReservePrice': CurrencyID;
    /**
     * 
     * @type {ReviseStatus}
     * @memberof Item
     */
    'ReviseStatus': ReviseStatus;
    /**
     * 
     * @type {Seller}
     * @memberof Item
     */
    'Seller': Seller;
    /**
     * 
     * @type {SellingStatus}
     * @memberof Item
     */
    'SellingStatus': SellingStatus;
    /**
     * 
     * @type {ShippingDetails}
     * @memberof Item
     */
    'ShippingDetails': ShippingDetails;
    /**
     * 
     * @type {Array<string>}
     * @memberof Item
     */
    'ShipToLocations': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'Site': string;
    /**
     * 
     * @type {CurrencyID}
     * @memberof Item
     */
    'StartPrice': CurrencyID;
    /**
     * 
     * @type {Storefront}
     * @memberof Item
     */
    'Storefront': Storefront;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'TimeLeft': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'Title': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'WatchCount': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'GetItFast': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'BuyerResponsibleForShipping': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'SKU': string;
    /**
     * 
     * @type {PictureDetails}
     * @memberof Item
     */
    'PictureDetails': PictureDetails;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'DispatchTimeMax': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'ProxyItem': string;
    /**
     * 
     * @type {CurrencyID}
     * @memberof Item
     */
    'BuyerGuaranteePrice': CurrencyID;
    /**
     * 
     * @type {BuyerRequirementDetails}
     * @memberof Item
     */
    'BuyerRequirementDetails': BuyerRequirementDetails;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'IntangibleItem': string;
    /**
     * 
     * @type {ReturnPolicy}
     * @memberof Item
     */
    'ReturnPolicy': ReturnPolicy;
    /**
     * 
     * @type {Variations}
     * @memberof Item
     */
    'Variations': Variations;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'ConditionID': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'ConditionDisplayName': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'PostCheckoutExperienceEnabled': string;
    /**
     * 
     * @type {SellerProfiles}
     * @memberof Item
     */
    'SellerProfiles': SellerProfiles;
    /**
     * 
     * @type {ShippingPackageDetails}
     * @memberof Item
     */
    'ShippingPackageDetails': ShippingPackageDetails;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'HideFromSearch': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'OutOfStockControl': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'eBayPlus': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'eBayPlusEligible': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'IsSecureDescription': string;
}
/**
 * 
 * @export
 * @interface ItemInquiryControllerGetAll200Response
 */
export interface ItemInquiryControllerGetAll200Response {
    /**
     * 
     * @type {number}
     * @memberof ItemInquiryControllerGetAll200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ItemInquiryControllerGetAll200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ItemInquiryControllerGetAll200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<ItemInquiryMessages>}
     * @memberof ItemInquiryControllerGetAll200Response
     */
    'content'?: Array<ItemInquiryMessages>;
}
/**
 * 
 * @export
 * @interface ItemInquiryControllerGetAll200ResponseAllOf
 */
export interface ItemInquiryControllerGetAll200ResponseAllOf {
    /**
     * 
     * @type {Array<ItemInquiryMessages>}
     * @memberof ItemInquiryControllerGetAll200ResponseAllOf
     */
    'content'?: Array<ItemInquiryMessages>;
}
/**
 * 
 * @export
 * @interface ItemInquiryMessages
 */
export interface ItemInquiryMessages {
    /**
     * 
     * @type {number}
     * @memberof ItemInquiryMessages
     */
    'itemMessageGroupId': number;
    /**
     * 
     * @type {number}
     * @memberof ItemInquiryMessages
     */
    'sendSide': number;
    /**
     * 
     * @type {number}
     * @memberof ItemInquiryMessages
     */
    'sendUserId': number;
    /**
     * 
     * @type {number}
     * @memberof ItemInquiryMessages
     */
    'sendStaffId': number;
    /**
     * 
     * @type {string}
     * @memberof ItemInquiryMessages
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ItemInquiryMessages
     */
    'isRead': number;
    /**
     * 
     * @type {number}
     * @memberof ItemInquiryMessages
     */
    'isClosedInquiryMessage': number;
    /**
     * 
     * @type {Array<ItemMessageImages>}
     * @memberof ItemInquiryMessages
     */
    'itemMessageImages': Array<ItemMessageImages>;
    /**
     * 
     * @type {ItemMessageGroup}
     * @memberof ItemInquiryMessages
     */
    'itemMessageGroup'?: ItemMessageGroup;
    /**
     * 
     * @type {User}
     * @memberof ItemInquiryMessages
     */
    'sendUser'?: User;
    /**
     * 
     * @type {Admin}
     * @memberof ItemInquiryMessages
     */
    'sendStaff'?: Admin;
    /**
     * 
     * @type {number}
     * @memberof ItemInquiryMessages
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ItemInquiryMessages
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ItemInquiryMessages
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ItemInquiryMessages
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface ItemIntenalControllerGetAll200Response
 */
export interface ItemIntenalControllerGetAll200Response {
    /**
     * 
     * @type {number}
     * @memberof ItemIntenalControllerGetAll200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ItemIntenalControllerGetAll200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ItemIntenalControllerGetAll200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<ItemInternalMessages>}
     * @memberof ItemIntenalControllerGetAll200Response
     */
    'content'?: Array<ItemInternalMessages>;
}
/**
 * 
 * @export
 * @interface ItemIntenalControllerGetAll200ResponseAllOf
 */
export interface ItemIntenalControllerGetAll200ResponseAllOf {
    /**
     * 
     * @type {Array<ItemInternalMessages>}
     * @memberof ItemIntenalControllerGetAll200ResponseAllOf
     */
    'content'?: Array<ItemInternalMessages>;
}
/**
 * 
 * @export
 * @interface ItemInternalMessages
 */
export interface ItemInternalMessages {
    /**
     * 
     * @type {number}
     * @memberof ItemInternalMessages
     */
    'itemMessageGroupId': number;
    /**
     * 
     * @type {number}
     * @memberof ItemInternalMessages
     */
    'sendStaffId': number;
    /**
     * 
     * @type {string}
     * @memberof ItemInternalMessages
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ItemInternalMessages
     */
    'messageTranslated': string;
    /**
     * 
     * @type {number}
     * @memberof ItemInternalMessages
     */
    'isClosedInquiryMessage': number;
    /**
     * 
     * @type {Array<ItemMessageImages>}
     * @memberof ItemInternalMessages
     */
    'itemMessageImages': Array<ItemMessageImages>;
    /**
     * 
     * @type {ItemMessageGroup}
     * @memberof ItemInternalMessages
     */
    'itemMessageGroup'?: ItemMessageGroup;
    /**
     * 
     * @type {Admin}
     * @memberof ItemInternalMessages
     */
    'sendStaff'?: Admin;
    /**
     * 
     * @type {InternalMessageReadHistories}
     * @memberof ItemInternalMessages
     */
    'internalMessageReadHistories'?: InternalMessageReadHistories;
    /**
     * 
     * @type {number}
     * @memberof ItemInternalMessages
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ItemInternalMessages
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ItemInternalMessages
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ItemInternalMessages
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface ItemMessageGroup
 */
export interface ItemMessageGroup {
    /**
     * 
     * @type {number}
     * @memberof ItemMessageGroup
     */
    'storageItemId': number;
    /**
     * 
     * @type {number}
     * @memberof ItemMessageGroup
     */
    'assignId': number;
    /**
     * 
     * @type {string}
     * @memberof ItemMessageGroup
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof ItemMessageGroup
     */
    'type': number;
    /**
     * 
     * @type {StorageItem}
     * @memberof ItemMessageGroup
     */
    'storageRequest'?: StorageItem;
    /**
     * 
     * @type {Array<ItemInternalMessages>}
     * @memberof ItemMessageGroup
     */
    'itemInternalMessages': Array<ItemInternalMessages>;
    /**
     * 
     * @type {Array<ItemInquiryMessages>}
     * @memberof ItemMessageGroup
     */
    'itemInquiryMessages': Array<ItemInquiryMessages>;
    /**
     * 
     * @type {Admin}
     * @memberof ItemMessageGroup
     */
    'assign': Admin;
    /**
     * 
     * @type {number}
     * @memberof ItemMessageGroup
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ItemMessageGroup
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ItemMessageGroup
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ItemMessageGroup
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface ItemMessageImages
 */
export interface ItemMessageImages {
    /**
     * 
     * @type {number}
     * @memberof ItemMessageImages
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ItemMessageImages
     */
    'itemInquiryMessageId': number;
    /**
     * 
     * @type {number}
     * @memberof ItemMessageImages
     */
    'itemInternalMessageId': number;
    /**
     * 
     * @type {string}
     * @memberof ItemMessageImages
     */
    'source': string;
    /**
     * 
     * @type {number}
     * @memberof ItemMessageImages
     */
    'assetId': number;
    /**
     * 
     * @type {ItemInquiryMessages}
     * @memberof ItemMessageImages
     */
    'ItemInquiryMessages'?: ItemInquiryMessages;
    /**
     * 
     * @type {ItemInternalMessages}
     * @memberof ItemMessageImages
     */
    'itemInternalMessages'?: ItemInternalMessages;
}
/**
 * 
 * @export
 * @interface LineItem
 */
export interface LineItem {
    /**
     * 
     * @type {Array<AppliedPromotions>}
     * @memberof LineItem
     */
    'appliedPromotions': Array<AppliedPromotions>;
    /**
     * 
     * @type {LineItemDeliveryCost}
     * @memberof LineItem
     */
    'deliveryCost': LineItemDeliveryCost;
    /**
     * 
     * @type {Amount}
     * @memberof LineItem
     */
    'discountedLineItemCost': Amount;
    /**
     * 
     * @type {Array<EbayCollectAndRemitTaxes>}
     * @memberof LineItem
     */
    'ebayCollectAndRemitTaxes': Array<EbayCollectAndRemitTaxes>;
    /**
     * 
     * @type {LineItemEbayCollectedCharges}
     * @memberof LineItem
     */
    'ebayCollectedCharges': LineItemEbayCollectedCharges;
    /**
     * 
     * @type {LineItemGiftDetails}
     * @memberof LineItem
     */
    'giftDetails': LineItemGiftDetails;
    /**
     * 
     * @type {LineItemItemLocation}
     * @memberof LineItem
     */
    'itemLocation': LineItemItemLocation;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'legacyItemId': string;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'legacyVariationId': string;
    /**
     * 
     * @type {Amount}
     * @memberof LineItem
     */
    'lineItemCost': Amount;
    /**
     * 
     * @type {LineItemLineItemFulfillmentInstructions}
     * @memberof LineItem
     */
    'lineItemFulfillmentInstructions': LineItemLineItemFulfillmentInstructions;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'lineItemFulfillmentStatus': string;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'lineItemId': string;
    /**
     * 
     * @type {Array<LinkedOrderLineItems>}
     * @memberof LineItem
     */
    'linkedOrderLineItems': Array<LinkedOrderLineItems>;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'listingMarketplaceId': string;
    /**
     * 
     * @type {LineItemProperties}
     * @memberof LineItem
     */
    'properties': LineItemProperties;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'purchaseMarketplaceId': string;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    'quantity': number;
    /**
     * 
     * @type {Array<LineItemRefund>}
     * @memberof LineItem
     */
    'refunds': Array<LineItemRefund>;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'sku': string;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'soldFormat': string;
    /**
     * 
     * @type {Array<Tax>}
     * @memberof LineItem
     */
    'taxes': Array<Tax>;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'title': string;
    /**
     * 
     * @type {Amount}
     * @memberof LineItem
     */
    'total': Amount;
    /**
     * 
     * @type {Array<NameValuePair>}
     * @memberof LineItem
     */
    'variationAspects': Array<NameValuePair>;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    'avatarItem'?: string;
}
/**
 * 
 * @export
 * @interface LineItemDeliveryCost
 */
export interface LineItemDeliveryCost {
    /**
     * 
     * @type {Amount}
     * @memberof LineItemDeliveryCost
     */
    'discountAmount'?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof LineItemDeliveryCost
     */
    'handlingCost'?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof LineItemDeliveryCost
     */
    'importCharges'?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof LineItemDeliveryCost
     */
    'shippingCost'?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof LineItemDeliveryCost
     */
    'shippingIntermediationFee'?: Amount;
}
/**
 * 
 * @export
 * @interface LineItemEbayCollectedCharges
 */
export interface LineItemEbayCollectedCharges {
    /**
     * 
     * @type {Amount}
     * @memberof LineItemEbayCollectedCharges
     */
    'ebayShipping'?: Amount;
}
/**
 * 
 * @export
 * @interface LineItemGiftDetails
 */
export interface LineItemGiftDetails {
    /**
     * 
     * @type {string}
     * @memberof LineItemGiftDetails
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemGiftDetails
     */
    'recipientEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemGiftDetails
     */
    'senderName'?: string;
}
/**
 * 
 * @export
 * @interface LineItemItemLocation
 */
export interface LineItemItemLocation {
    /**
     * 
     * @type {string}
     * @memberof LineItemItemLocation
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemItemLocation
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemItemLocation
     */
    'postalCode'?: string;
}
/**
 * 
 * @export
 * @interface LineItemLineItemFulfillmentInstructions
 */
export interface LineItemLineItemFulfillmentInstructions {
    /**
     * 
     * @type {string}
     * @memberof LineItemLineItemFulfillmentInstructions
     */
    'destinationTimeZone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LineItemLineItemFulfillmentInstructions
     */
    'guaranteedDelivery'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LineItemLineItemFulfillmentInstructions
     */
    'maxEstimatedDeliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemLineItemFulfillmentInstructions
     */
    'minEstimatedDeliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemLineItemFulfillmentInstructions
     */
    'shipByDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemLineItemFulfillmentInstructions
     */
    'sourceTimeZone'?: string;
}
/**
 * 
 * @export
 * @interface LineItemProperties
 */
export interface LineItemProperties {
    /**
     * 
     * @type {boolean}
     * @memberof LineItemProperties
     */
    'buyerProtection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LineItemProperties
     */
    'fromBestOffer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LineItemProperties
     */
    'soldViaAdCampaign'?: boolean;
}
/**
 * 
 * @export
 * @interface LineItemReference
 */
export interface LineItemReference {
    /**
     * 
     * @type {string}
     * @memberof LineItemReference
     */
    'lineItemId': string;
    /**
     * 
     * @type {number}
     * @memberof LineItemReference
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface LineItemRefund
 */
export interface LineItemRefund {
    /**
     * 
     * @type {Amount}
     * @memberof LineItemRefund
     */
    'amount': Amount;
    /**
     * 
     * @type {string}
     * @memberof LineItemRefund
     */
    'refundDate': string;
    /**
     * 
     * @type {string}
     * @memberof LineItemRefund
     */
    'refundId': string;
    /**
     * 
     * @type {string}
     * @memberof LineItemRefund
     */
    'refundReferenceId': string;
}
/**
 * 
 * @export
 * @interface LinkedOrderLineItems
 */
export interface LinkedOrderLineItems {
    /**
     * 
     * @type {Array<NameValuePair>}
     * @memberof LinkedOrderLineItems
     */
    'lineItemAspects': Array<NameValuePair>;
    /**
     * 
     * @type {string}
     * @memberof LinkedOrderLineItems
     */
    'lineItemId': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedOrderLineItems
     */
    'maxEstimatedDeliveryDate': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedOrderLineItems
     */
    'minEstimatedDeliveryDate': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedOrderLineItems
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedOrderLineItems
     */
    'sellerId': string;
    /**
     * 
     * @type {Array<TrackingInfo>}
     * @memberof LinkedOrderLineItems
     */
    'shipments': Array<TrackingInfo>;
    /**
     * 
     * @type {string}
     * @memberof LinkedOrderLineItems
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface ListingDetails
 */
export interface ListingDetails {
    /**
     * 
     * @type {string}
     * @memberof ListingDetails
     */
    'Adult': string;
    /**
     * 
     * @type {string}
     * @memberof ListingDetails
     */
    'BindingAuction': string;
    /**
     * 
     * @type {string}
     * @memberof ListingDetails
     */
    'CheckoutEnabled': string;
    /**
     * 
     * @type {CurrencyID}
     * @memberof ListingDetails
     */
    'ConvertedBuyItNowPrice': CurrencyID;
    /**
     * 
     * @type {CurrencyID}
     * @memberof ListingDetails
     */
    'ConvertedStartPrice': CurrencyID;
    /**
     * 
     * @type {CurrencyID}
     * @memberof ListingDetails
     */
    'ConvertedReservePrice': CurrencyID;
    /**
     * 
     * @type {string}
     * @memberof ListingDetails
     */
    'HasReservePrice': string;
    /**
     * 
     * @type {string}
     * @memberof ListingDetails
     */
    'StartTime': string;
    /**
     * 
     * @type {string}
     * @memberof ListingDetails
     */
    'EndTime': string;
    /**
     * 
     * @type {string}
     * @memberof ListingDetails
     */
    'ViewItemURL': string;
    /**
     * 
     * @type {string}
     * @memberof ListingDetails
     */
    'HasUnansweredQuestions': string;
    /**
     * 
     * @type {string}
     * @memberof ListingDetails
     */
    'HasPublicMessages': string;
    /**
     * 
     * @type {string}
     * @memberof ListingDetails
     */
    'ViewItemURLForNaturalSearch': string;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginIdDto
 */
export interface LoginIdDto {
    /**
     * 
     * @type {string}
     * @memberof LoginIdDto
     */
    'loginId': string;
}
/**
 * 
 * @export
 * @interface LoginResponseDto
 */
export interface LoginResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface MailResponseDTO
 */
export interface MailResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof MailResponseDTO
     */
    'mailTitle': string;
    /**
     * 
     * @type {string}
     * @memberof MailResponseDTO
     */
    'mailContent': string;
}
/**
 * 
 * @export
 * @interface ManualPaymentDto
 */
export interface ManualPaymentDto {
    /**
     * 
     * @type {number}
     * @memberof ManualPaymentDto
     */
    'paymentId': number;
}
/**
 * 
 * @export
 * @interface MaskAsRead
 */
export interface MaskAsRead {
    /**
     * 
     * @type {number}
     * @memberof MaskAsRead
     */
    'bizId': number;
}
/**
 * 
 * @export
 * @interface MeasurementSystem
 */
export interface MeasurementSystem {
    /**
     * 
     * @type {string}
     * @memberof MeasurementSystem
     */
    'measurementSystem': string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementSystem
     */
    'unit': string;
}
/**
 * 
 * @export
 * @interface MonetaryDetails
 */
export interface MonetaryDetails {
    /**
     * 
     * @type {Payments}
     * @memberof MonetaryDetails
     */
    'Payments': Payments;
}
/**
 * 
 * @export
 * @interface MonthPaymentDetail
 */
export interface MonthPaymentDetail {
    /**
     * 
     * @type {string}
     * @memberof MonthPaymentDetail
     */
    'title': string;
    /**
     * 
     * @type {AccountEbay}
     * @memberof MonthPaymentDetail
     */
    'accountEbay': AccountEbay;
    /**
     * 
     * @type {StorageItem}
     * @memberof MonthPaymentDetail
     */
    'storageItem': StorageItem;
    /**
     * 
     * @type {number}
     * @memberof MonthPaymentDetail
     */
    'chargeAmount': number;
    /**
     * 
     * @type {number}
     * @memberof MonthPaymentDetail
     */
    'chargeAmountYen': number;
    /**
     * 
     * @type {boolean}
     * @memberof MonthPaymentDetail
     */
    'success': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonthPaymentDetail
     */
    'refund'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonthPaymentDetail
     */
    'paidAt': string;
    /**
     * 
     * @type {Array<SubContentMonthPaymentDetail>}
     * @memberof MonthPaymentDetail
     */
    'subContent': Array<SubContentMonthPaymentDetail>;
}
/**
 * 
 * @export
 * @interface MonthPaymentDetailDto
 */
export interface MonthPaymentDetailDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof MonthPaymentDetailDto
     */
    'paymentIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof MonthPaymentDetailDto
     */
    'accountEbayId'?: number;
}
/**
 * 
 * @export
 * @interface MonthPaymentListItem
 */
export interface MonthPaymentListItem {
    /**
     * 
     * @type {string}
     * @memberof MonthPaymentListItem
     */
    'month': string;
    /**
     * 
     * @type {number}
     * @memberof MonthPaymentListItem
     */
    'chargeAmount': number;
    /**
     * 
     * @type {number}
     * @memberof MonthPaymentListItem
     */
    'chargeAmountYen': number;
    /**
     * 
     * @type {boolean}
     * @memberof MonthPaymentListItem
     */
    'success': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonthPaymentListItem
     */
    'paymentIds': Array<number>;
}
/**
 * 
 * @export
 * @interface MonthlyDetailDto
 */
export interface MonthlyDetailDto {
    /**
     * 
     * @type {string}
     * @memberof MonthlyDetailDto
     */
    'month'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonthlyDetailDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonthlyDetailDto
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface NameValueList
 */
export interface NameValueList {
    /**
     * 
     * @type {string}
     * @memberof NameValueList
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof NameValueList
     */
    'Value': string;
}
/**
 * 
 * @export
 * @interface NameValuePair
 */
export interface NameValuePair {
    /**
     * 
     * @type {string}
     * @memberof NameValuePair
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof NameValuePair
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface NotifiUserControllerGet200Response
 */
export interface NotifiUserControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof NotifiUserControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof NotifiUserControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof NotifiUserControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<SystemNotificationUser>}
     * @memberof NotifiUserControllerGet200Response
     */
    'content'?: Array<SystemNotificationUser>;
}
/**
 * 
 * @export
 * @interface NotifiUserControllerGet200ResponseAllOf
 */
export interface NotifiUserControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<SystemNotificationUser>}
     * @memberof NotifiUserControllerGet200ResponseAllOf
     */
    'content'?: Array<SystemNotificationUser>;
}
/**
 * 
 * @export
 * @interface NotifyEbayReturnCommon
 */
export interface NotifyEbayReturnCommon {
    /**
     * 
     * @type {string}
     * @memberof NotifyEbayReturnCommon
     */
    'ReturnId': string;
    /**
     * 
     * @type {string}
     * @memberof NotifyEbayReturnCommon
     */
    'ReturnStatus': string;
    /**
     * 
     * @type {string}
     * @memberof NotifyEbayReturnCommon
     */
    'ReturnGlobalId': string;
    /**
     * 
     * @type {string}
     * @memberof NotifyEbayReturnCommon
     */
    'OrderId': string;
    /**
     * 
     * @type {NotifyTransactionIdentity}
     * @memberof NotifyEbayReturnCommon
     */
    'TransactionIdentity': NotifyTransactionIdentity;
    /**
     * 
     * @type {string}
     * @memberof NotifyEbayReturnCommon
     */
    'ReturnLink': string;
    /**
     * 
     * @type {string}
     * @memberof NotifyEbayReturnCommon
     */
    'ReturnTrackingId': string;
    /**
     * 
     * @type {string}
     * @memberof NotifyEbayReturnCommon
     */
    'NotificationEventName': string;
    /**
     * 
     * @type {string}
     * @memberof NotifyEbayReturnCommon
     */
    'RecipientUserID': string;
    /**
     * 
     * @type {string}
     * @memberof NotifyEbayReturnCommon
     */
    'CreationDate': string;
    /**
     * 
     * @type {string}
     * @memberof NotifyEbayReturnCommon
     */
    'OtherPartyId': string;
    /**
     * 
     * @type {string}
     * @memberof NotifyEbayReturnCommon
     */
    'OtherPartyRole': string;
    /**
     * 
     * @type {string}
     * @memberof NotifyEbayReturnCommon
     */
    'Priority': string;
}
/**
 * 
 * @export
 * @interface NotifyTransactionIdentity
 */
export interface NotifyTransactionIdentity {
    /**
     * 
     * @type {string}
     * @memberof NotifyTransactionIdentity
     */
    'ItemId': string;
    /**
     * 
     * @type {string}
     * @memberof NotifyTransactionIdentity
     */
    'TransactionId': string;
}
/**
 * 
 * @export
 * @interface OmitTypeClass
 */
export interface OmitTypeClass {
    /**
     * 
     * @type {number}
     * @memberof OmitTypeClass
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'deletedAt': string;
    /**
     * 
     * @type {number}
     * @memberof OmitTypeClass
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'userEbayId': string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'avatar': string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'oauthToken': string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'expiredOauthToken': string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'refreshOauthToken': string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'expiredRefreshOauthToken': string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'dateGetToken': string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'marketplaceId': string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'eiasToken': string;
    /**
     * 
     * @type {boolean}
     * @memberof OmitTypeClass
     */
    'isActivated': boolean;
}
/**
 * 
 * @export
 * @interface OrderXML
 */
export interface OrderXML {
    /**
     * 
     * @type {string}
     * @memberof OrderXML
     */
    'OrderID': string;
    /**
     * 
     * @type {string}
     * @memberof OrderXML
     */
    'OrderStatus': string;
    /**
     * 
     * @type {AdjustmentAmount}
     * @memberof OrderXML
     */
    'AdjustmentAmount': AdjustmentAmount;
    /**
     * 
     * @type {AmountPaid}
     * @memberof OrderXML
     */
    'AmountPaid': AmountPaid;
    /**
     * 
     * @type {AmountSaved}
     * @memberof OrderXML
     */
    'AmountSaved': AmountSaved;
    /**
     * 
     * @type {CheckoutStatus}
     * @memberof OrderXML
     */
    'CheckoutStatus': CheckoutStatus;
    /**
     * 
     * @type {ShippingDetails}
     * @memberof OrderXML
     */
    'ShippingDetails': ShippingDetails;
    /**
     * 
     * @type {string}
     * @memberof OrderXML
     */
    'CreatedTime': string;
    /**
     * 
     * @type {ShippingAddress}
     * @memberof OrderXML
     */
    'ShippingAddress': ShippingAddress;
    /**
     * 
     * @type {ShippingServiceSelected}
     * @memberof OrderXML
     */
    'ShippingServiceSelected': ShippingServiceSelected;
    /**
     * 
     * @type {Subtotal}
     * @memberof OrderXML
     */
    'Subtotal': Subtotal;
    /**
     * 
     * @type {Total}
     * @memberof OrderXML
     */
    'Total': Total;
    /**
     * 
     * @type {TransactionArray}
     * @memberof OrderXML
     */
    'TransactionArray': TransactionArray;
    /**
     * 
     * @type {string}
     * @memberof OrderXML
     */
    'BuyerUserID': string;
    /**
     * 
     * @type {string}
     * @memberof OrderXML
     */
    'PaidTime': string;
    /**
     * 
     * @type {string}
     * @memberof OrderXML
     */
    'EIASToken': string;
    /**
     * 
     * @type {string}
     * @memberof OrderXML
     */
    'PaymentHoldStatus': string;
    /**
     * 
     * @type {string}
     * @memberof OrderXML
     */
    'IsMultiLegShipping': string;
    /**
     * 
     * @type {MonetaryDetails}
     * @memberof OrderXML
     */
    'MonetaryDetails': MonetaryDetails;
    /**
     * 
     * @type {string}
     * @memberof OrderXML
     */
    'SellerUserID': string;
    /**
     * 
     * @type {string}
     * @memberof OrderXML
     */
    'SellerEIASToken': string;
    /**
     * 
     * @type {string}
     * @memberof OrderXML
     */
    'CancelStatus': string;
    /**
     * 
     * @type {string}
     * @memberof OrderXML
     */
    'ExtendedOrderID': string;
    /**
     * 
     * @type {string}
     * @memberof OrderXML
     */
    'ContainseBayPlusTransaction': string;
}
/**
 * 
 * @export
 * @interface OtpDto
 */
export interface OtpDto {
    /**
     * 
     * @type {string}
     * @memberof OtpDto
     */
    'otp': string;
    /**
     * 
     * @type {string}
     * @memberof OtpDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface OverViewResponseDto
 */
export interface OverViewResponseDto {
    /**
     * 
     * @type {string}
     * @memberof OverViewResponseDto
     */
    'month': string;
    /**
     * 
     * @type {number}
     * @memberof OverViewResponseDto
     */
    'revenue': number;
    /**
     * 
     * @type {number}
     * @memberof OverViewResponseDto
     */
    'shippoShipFee': number;
    /**
     * 
     * @type {number}
     * @memberof OverViewResponseDto
     */
    'profit': number;
    /**
     * 
     * @type {number}
     * @memberof OverViewResponseDto
     */
    'profitRate': number;
    /**
     * 
     * @type {number}
     * @memberof OverViewResponseDto
     */
    'storageRequestCount': number;
    /**
     * 
     * @type {number}
     * @memberof OverViewResponseDto
     */
    'shipToBuyerRequestCount': number;
    /**
     * 
     * @type {number}
     * @memberof OverViewResponseDto
     */
    'inStorageItemCount': number;
    /**
     * 
     * @type {number}
     * @memberof OverViewResponseDto
     */
    'userCount': number;
    /**
     * 
     * @type {object}
     * @memberof OverViewResponseDto
     */
    'userPlanCount': object;
    /**
     * 
     * @type {number}
     * @memberof OverViewResponseDto
     */
    'ebayAccountCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof OverViewResponseDto
     */
    'success': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof OverViewResponseDto
     */
    'paymentIds': Array<number>;
}
/**
 * 
 * @export
 * @interface PaginationResponse
 */
export interface PaginationResponse {
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface ParcelsDto
 */
export interface ParcelsDto {
    /**
     * distance unit
     * @type {string}
     * @memberof ParcelsDto
     */
    'distance_unit': ParcelsDtoDistanceUnitEnum;
    /**
     * Height of the parcel
     * @type {string}
     * @memberof ParcelsDto
     */
    'height': string;
    /**
     * length of the parcel
     * @type {string}
     * @memberof ParcelsDto
     */
    'length': string;
    /**
     * The unit used for weight
     * @type {string}
     * @memberof ParcelsDto
     */
    'mass_unit': ParcelsDtoMassUnitEnum;
    /**
     * weight of the parcel
     * @type {string}
     * @memberof ParcelsDto
     */
    'weight': string;
    /**
     * Width  of the parcel
     * @type {string}
     * @memberof ParcelsDto
     */
    'width': string;
    /**
     * A string of up to 100 characters that can be filled with any additional information you want to attach to the object
     * @type {string}
     * @memberof ParcelsDto
     */
    'metadata'?: string;
}

export const ParcelsDtoDistanceUnitEnum = {
    Cm: 'cm',
    In: 'in',
    Ft: 'ft',
    M: 'm',
    Mm: 'mm',
    Yd: 'yd'
} as const;

export type ParcelsDtoDistanceUnitEnum = typeof ParcelsDtoDistanceUnitEnum[keyof typeof ParcelsDtoDistanceUnitEnum];
export const ParcelsDtoMassUnitEnum = {
    G: 'g',
    Kg: 'kg',
    Lb: 'lb',
    Oz: 'oz'
} as const;

export type ParcelsDtoMassUnitEnum = typeof ParcelsDtoMassUnitEnum[keyof typeof ParcelsDtoMassUnitEnum];

/**
 * 
 * @export
 * @interface PatternBaseFee
 */
export interface PatternBaseFee {
    /**
     * 
     * @type {number}
     * @memberof PatternBaseFee
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PatternBaseFee
     */
    'baseFee': number;
    /**
     * 
     * @type {number}
     * @memberof PatternBaseFee
     */
    'oneDayFee': number;
}
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'paymentMethod': string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'chargeAmount': number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'chargeAmountYen': number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'payFor': string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'userId': number;
    /**
     * 
     * @type {User}
     * @memberof Payment
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'chargeId': string;
    /**
     * 
     * @type {object}
     * @memberof Payment
     */
    'metadata': object;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface PaymentSetting
 */
export interface PaymentSetting {
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'activationDate': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof PaymentSetting
     */
    'shippoSurchargeConstInUS': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentSetting
     */
    'shippoSurchargeConstOutUS': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentSetting
     */
    'videoRecordFee': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentSetting
     */
    'takePhotoFee': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentSetting
     */
    'cancellationConst': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentSetting
     */
    'trackingNumberNotRegisteredFee': number;
    /**
     * 
     * @type {Array<PlanVersion>}
     * @memberof PaymentSetting
     */
    'planVersions': Array<PlanVersion>;
    /**
     * 
     * @type {number}
     * @memberof PaymentSetting
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface PaymentSettingControllerGetAllSetting200Response
 */
export interface PaymentSettingControllerGetAllSetting200Response {
    /**
     * 
     * @type {number}
     * @memberof PaymentSettingControllerGetAllSetting200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentSettingControllerGetAllSetting200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentSettingControllerGetAllSetting200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<PaymentSetting>}
     * @memberof PaymentSettingControllerGetAllSetting200Response
     */
    'content'?: Array<PaymentSetting>;
}
/**
 * 
 * @export
 * @interface PaymentSettingControllerGetAllSetting200ResponseAllOf
 */
export interface PaymentSettingControllerGetAllSetting200ResponseAllOf {
    /**
     * 
     * @type {Array<PaymentSetting>}
     * @memberof PaymentSettingControllerGetAllSetting200ResponseAllOf
     */
    'content'?: Array<PaymentSetting>;
}
/**
 * 
 * @export
 * @interface Payments
 */
export interface Payments {
    /**
     * 
     * @type {Payment}
     * @memberof Payments
     */
    'Payment': Payment;
}
/**
 * 
 * @export
 * @interface PhotoRequestDto
 */
export interface PhotoRequestDto {
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestDto
     */
    'storageItemId': number;
    /**
     * 
     * @type {string}
     * @memberof PhotoRequestDto
     */
    'userMessage': string;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestDto
     */
    'photoQuantity'?: number;
}
/**
 * 
 * @export
 * @interface PictureDetails
 */
export interface PictureDetails {
    /**
     * 
     * @type {string}
     * @memberof PictureDetails
     */
    'GalleryType': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PictureDetails
     */
    'PictureURL': Array<string>;
}
/**
 * 
 * @export
 * @interface PictureVariation
 */
export interface PictureVariation {
    /**
     * 
     * @type {string}
     * @memberof PictureVariation
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof PictureVariation
     */
    'Value': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PictureVariation
     */
    'PictureURL': Array<string>;
}
/**
 * 
 * @export
 * @interface PictureVariationEbay
 */
export interface PictureVariationEbay {
    /**
     * 
     * @type {string}
     * @memberof PictureVariationEbay
     */
    'VariationSpecificName': string;
    /**
     * 
     * @type {Array<VariationSpecificPictureSet>}
     * @memberof PictureVariationEbay
     */
    'VariationSpecificPictureSet': Array<VariationSpecificPictureSet>;
}
/**
 * 
 * @export
 * @interface Plan
 */
export interface Plan {
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'symbol': string;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface PlanVersion
 */
export interface PlanVersion {
    /**
     * 
     * @type {PaymentSetting}
     * @memberof PlanVersion
     */
    'paymentSetting': PaymentSetting;
    /**
     * 
     * @type {number}
     * @memberof PlanVersion
     */
    'paymentSettingId': number;
    /**
     * 
     * @type {Array<PlanVersionPattern>}
     * @memberof PlanVersion
     */
    'planVersionPatterns': Array<PlanVersionPattern>;
    /**
     * 
     * @type {Plan}
     * @memberof PlanVersion
     */
    'plan': Plan;
    /**
     * 
     * @type {number}
     * @memberof PlanVersion
     */
    'planId': number;
    /**
     * 
     * @type {string}
     * @memberof PlanVersion
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersion
     */
    'shortDescription': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersion
     */
    'longDescription': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersion
     */
    'basicServiceDescription': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersion
     */
    'feeDescription': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersion
     */
    'useCaseDesciption': string;
    /**
     * 
     * @type {number}
     * @memberof PlanVersion
     */
    'monthlyFee': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersion
     */
    'maxWeight': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersion
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PlanVersion
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersion
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersion
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface PlanVersionDto
 */
export interface PlanVersionDto {
    /**
     * 
     * @type {number}
     * @memberof PlanVersionDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionDto
     */
    'planId': number;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionDto
     */
    'shortDescription': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionDto
     */
    'longDescription': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionDto
     */
    'basicServiceDescription': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionDto
     */
    'feeDescription': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionDto
     */
    'useCaseDesciption': string;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionDto
     */
    'monthlyFee': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionDto
     */
    'maxWeight'?: number;
    /**
     * 
     * @type {Array<PlanVersionPatternDto>}
     * @memberof PlanVersionDto
     */
    'planVersionPatterns': Array<PlanVersionPatternDto>;
}
/**
 * 
 * @export
 * @interface PlanVersionExtendDto
 */
export interface PlanVersionExtendDto {
    /**
     * 
     * @type {PaymentSetting}
     * @memberof PlanVersionExtendDto
     */
    'paymentSetting': PaymentSetting;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionExtendDto
     */
    'paymentSettingId': number;
    /**
     * 
     * @type {Array<PlanVersionPattern>}
     * @memberof PlanVersionExtendDto
     */
    'planVersionPatterns': Array<PlanVersionPattern>;
    /**
     * 
     * @type {Plan}
     * @memberof PlanVersionExtendDto
     */
    'plan': Plan;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionExtendDto
     */
    'planId': number;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionExtendDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionExtendDto
     */
    'shortDescription': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionExtendDto
     */
    'longDescription': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionExtendDto
     */
    'basicServiceDescription': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionExtendDto
     */
    'feeDescription': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionExtendDto
     */
    'useCaseDesciption': string;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionExtendDto
     */
    'monthlyFee': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionExtendDto
     */
    'maxWeight': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionExtendDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionExtendDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionExtendDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionExtendDto
     */
    'deletedAt': string;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionExtendDto
     */
    'daysRemaining': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionExtendDto
     */
    'currentMonthFee': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionExtendDto
     */
    'nextMonthFee': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionExtendDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionExtendDto
     */
    'extraFee': number;
}
/**
 * 
 * @export
 * @interface PlanVersionPattern
 */
export interface PlanVersionPattern {
    /**
     * 
     * @type {PlanVersion}
     * @memberof PlanVersionPattern
     */
    'planVersion': PlanVersion;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionPattern
     */
    'planVersionId': number;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionPattern
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionPattern
     */
    'baseFeeConstWeight': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionPattern
     */
    'baseFeeConstPrice': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionPattern
     */
    'storageFeeConstWeight': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionPattern
     */
    'storageFeeConstPrice': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionPattern
     */
    'minBaseFee': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionPattern
     */
    'minStorageFee': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionPattern
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionPattern
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionPattern
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionPattern
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface PlanVersionPatternDto
 */
export interface PlanVersionPatternDto {
    /**
     * 
     * @type {number}
     * @memberof PlanVersionPatternDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PlanVersionPatternDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionPatternDto
     */
    'baseFeeConstWeight': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionPatternDto
     */
    'baseFeeConstPrice': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionPatternDto
     */
    'storageFeeConstWeight': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionPatternDto
     */
    'storageFeeConstPrice': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionPatternDto
     */
    'minBaseFee': number;
    /**
     * 
     * @type {number}
     * @memberof PlanVersionPatternDto
     */
    'minStorageFee': number;
}
/**
 * 
 * @export
 * @interface PrimaryCategory
 */
export interface PrimaryCategory {
    /**
     * 
     * @type {string}
     * @memberof PrimaryCategory
     */
    'CategoryID': string;
    /**
     * 
     * @type {string}
     * @memberof PrimaryCategory
     */
    'CategoryName': string;
}
/**
 * 
 * @export
 * @interface Rack
 */
export interface Rack {
    /**
     * 
     * @type {string}
     * @memberof Rack
     */
    'code': string;
    /**
     * 
     * @type {Array<StorageItemRack>}
     * @memberof Rack
     */
    'storageItemRack': Array<StorageItemRack>;
    /**
     * 
     * @type {number}
     * @memberof Rack
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Rack
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Rack
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Rack
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface RackControllerFindAll200Response
 */
export interface RackControllerFindAll200Response {
    /**
     * 
     * @type {number}
     * @memberof RackControllerFindAll200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof RackControllerFindAll200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof RackControllerFindAll200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Rack>}
     * @memberof RackControllerFindAll200Response
     */
    'content'?: Array<Rack>;
}
/**
 * 
 * @export
 * @interface RackControllerFindAll200ResponseAllOf
 */
export interface RackControllerFindAll200ResponseAllOf {
    /**
     * 
     * @type {Array<Rack>}
     * @memberof RackControllerFindAll200ResponseAllOf
     */
    'content'?: Array<Rack>;
}
/**
 * 
 * @export
 * @interface Rate
 */
export interface Rate {
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'amountLocal': string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'currency': string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'currencyLocal': string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'arrivesBy'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Rate
     */
    'attributes': Array<RateAttributesEnum>;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'carrierAccount': string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'durationTerms'?: string;
    /**
     * 
     * @type {number}
     * @memberof Rate
     */
    'estimatedDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof Rate
     */
    'includedInsurancePrice'?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Rate
     */
    'messages'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'objectCreated': string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'objectId': string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'objectOwner': string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'providerImage75'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'providerImage200'?: string;
    /**
     * 
     * @type {object}
     * @memberof Rate
     */
    'servicelevel': object;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'shipment': string;
    /**
     * 
     * @type {boolean}
     * @memberof Rate
     */
    'test'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'zone'?: string;
}

export const RateAttributesEnum = {
    Bestvalue: 'BESTVALUE',
    Cheapest: 'CHEAPEST',
    Fastest: 'FASTEST'
} as const;

export type RateAttributesEnum = typeof RateAttributesEnum[keyof typeof RateAttributesEnum];

/**
 * 
 * @export
 * @interface ReadMessageDto
 */
export interface ReadMessageDto {
    /**
     * 
     * @type {number}
     * @memberof ReadMessageDto
     */
    'groupId'?: number;
}
/**
 * 
 * @export
 * @interface Reception
 */
export interface Reception {
    /**
     * 
     * @type {string}
     * @memberof Reception
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof Reception
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Reception
     */
    'subAddress': string;
    /**
     * 
     * @type {string}
     * @memberof Reception
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof Reception
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface Region
 */
export interface Region {
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'regionName': string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'regionType': RegionRegionTypeEnum;
}

export const RegionRegionTypeEnum = {
    Country: 'COUNTRY',
    CountryRegion: 'COUNTRY_REGION',
    StateOrProvince: 'STATE_OR_PROVINCE',
    WorldRegion: 'WORLD_REGION',
    Worldwide: 'WORLDWIDE'
} as const;

export type RegionRegionTypeEnum = typeof RegionRegionTypeEnum[keyof typeof RegionRegionTypeEnum];

/**
 * 
 * @export
 * @interface RegionFilter
 */
export interface RegionFilter {
    /**
     * 
     * @type {Array<Region>}
     * @memberof RegionFilter
     */
    'regionExcluded': Array<Region>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof RegionFilter
     */
    'regionIncluded': Array<Region>;
}
/**
 * 
 * @export
 * @interface RegisterStorageDto
 */
export interface RegisterStorageDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterStorageDto
     */
    'sku': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterStorageDto
     */
    'rackCode': string;
}
/**
 * 
 * @export
 * @interface RegisterUserDto
 */
export interface RegisterUserDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'cardToken'?: string;
    /**
     * 
     * @type {number}
     * @memberof RegisterUserDto
     */
    'planVersionId'?: number;
}
/**
 * 
 * @export
 * @interface RequestPasswordResetDto
 */
export interface RequestPasswordResetDto {
    /**
     * 
     * @type {string}
     * @memberof RequestPasswordResetDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RequestPasswordResetDto
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface ReturnPolicy
 */
export interface ReturnPolicy {
    /**
     * 
     * @type {string}
     * @memberof ReturnPolicy
     */
    'RefundOption': string;
    /**
     * 
     * @type {string}
     * @memberof ReturnPolicy
     */
    'Refund': string;
    /**
     * 
     * @type {string}
     * @memberof ReturnPolicy
     */
    'ReturnsWithinOption': string;
    /**
     * 
     * @type {string}
     * @memberof ReturnPolicy
     */
    'ReturnsWithin': string;
    /**
     * 
     * @type {string}
     * @memberof ReturnPolicy
     */
    'ReturnsAcceptedOption': string;
    /**
     * 
     * @type {string}
     * @memberof ReturnPolicy
     */
    'ReturnsAccepted': string;
    /**
     * 
     * @type {string}
     * @memberof ReturnPolicy
     */
    'ShippingCostPaidByOption': string;
    /**
     * 
     * @type {string}
     * @memberof ReturnPolicy
     */
    'ShippingCostPaidBy': string;
    /**
     * 
     * @type {string}
     * @memberof ReturnPolicy
     */
    'InternationalRefundOption': string;
    /**
     * 
     * @type {string}
     * @memberof ReturnPolicy
     */
    'InternationalReturnsAcceptedOption': string;
    /**
     * 
     * @type {string}
     * @memberof ReturnPolicy
     */
    'InternationalReturnsWithinOption': string;
    /**
     * 
     * @type {string}
     * @memberof ReturnPolicy
     */
    'InternationalShippingCostPaidByOption': string;
}
/**
 * 
 * @export
 * @interface RevenueMonthDetailListItem
 */
export interface RevenueMonthDetailListItem {
    /**
     * 
     * @type {string}
     * @memberof RevenueMonthDetailListItem
     */
    'userCode': string;
    /**
     * 
     * @type {string}
     * @memberof RevenueMonthDetailListItem
     */
    'userName': string;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailListItem
     */
    'revenue': number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailListItem
     */
    'shippoShipFee': number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailListItem
     */
    'profit': number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailListItem
     */
    'profitRate': number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailListItem
     */
    'monthlyFee': number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailListItem
     */
    'storageRequestRevenue': number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailListItem
     */
    'storageRequestCount': number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailListItem
     */
    'shipToBuyerRequestRevenue': number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailListItem
     */
    'shipToBuyerRequestCount': number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailListItem
     */
    'disposalRevenue': number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailListItem
     */
    'disposalCount': number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailListItem
     */
    'optionFee': number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailListItem
     */
    'storageFeeRevenue': number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailListItem
     */
    'storageFeeCount': number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailListItem
     */
    'diffFee': number;
    /**
     * 
     * @type {boolean}
     * @memberof RevenueMonthDetailListItem
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface RevenueMonthDetailResponseDto
 */
export interface RevenueMonthDetailResponseDto {
    /**
     * 
     * @type {RevenueMonthDetailResponseDtoLeft}
     * @memberof RevenueMonthDetailResponseDto
     */
    'left': RevenueMonthDetailResponseDtoLeft;
    /**
     * 
     * @type {RevenueMonthDetailResponseDtoRight}
     * @memberof RevenueMonthDetailResponseDto
     */
    'right': RevenueMonthDetailResponseDtoRight;
    /**
     * 
     * @type {Array<RevenueMonthDetailListItem>}
     * @memberof RevenueMonthDetailResponseDto
     */
    'list': Array<RevenueMonthDetailListItem>;
}
/**
 * 
 * @export
 * @interface RevenueMonthDetailResponseDtoLeft
 */
export interface RevenueMonthDetailResponseDtoLeft {
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoLeft
     */
    'revenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoLeft
     */
    'shippoShipFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoLeft
     */
    'profit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoLeft
     */
    'profitRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoLeft
     */
    'storageRequestRevenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoLeft
     */
    'storageRequestCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoLeft
     */
    'shipToBuyerRequestRevenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoLeft
     */
    'shipToBuyerRequestCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoLeft
     */
    'disposalRevenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoLeft
     */
    'disposalCount'?: number;
}
/**
 * 
 * @export
 * @interface RevenueMonthDetailResponseDtoRight
 */
export interface RevenueMonthDetailResponseDtoRight {
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoRight
     */
    'monthlyFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoRight
     */
    'baseFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoRight
     */
    'storageFeeRevenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoRight
     */
    'storageFeeCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoRight
     */
    'storageRequestInsuranceFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoRight
     */
    'storageRequestInsuranceCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoRight
     */
    'shipToBuyerRequestInsuranceFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoRight
     */
    'shipToBuyerRequestInsuranceCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoRight
     */
    'unboxViewFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoRight
     */
    'unboxViewCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoRight
     */
    'photoRequestFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoRight
     */
    'photoRequestCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoRight
     */
    'shipDiffFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoRight
     */
    'shipDiffCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoRight
     */
    'baseDiffFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueMonthDetailResponseDtoRight
     */
    'baseDiffCount'?: number;
}
/**
 * 
 * @export
 * @interface ReviseFixedPriceItemEbayDto
 */
export interface ReviseFixedPriceItemEbayDto {
    /**
     * 
     * @type {number}
     * @memberof ReviseFixedPriceItemEbayDto
     */
    'userEbayId': number;
    /**
     * 
     * @type {string}
     * @memberof ReviseFixedPriceItemEbayDto
     */
    'ItemId': string;
    /**
     * 
     * @type {string}
     * @memberof ReviseFixedPriceItemEbayDto
     */
    'Title': string;
    /**
     * 
     * @type {string}
     * @memberof ReviseFixedPriceItemEbayDto
     */
    'Sku'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReviseFixedPriceItemEbayDto
     */
    'PictureGallery'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ReviseFixedPriceItemEbayDto
     */
    'Country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviseFixedPriceItemEbayDto
     */
    'Location'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviseFixedPriceItemEbayDto
     */
    'PostalCode'?: string;
    /**
     * 
     * @type {ReviseItemEbayDtoSellerProfiles}
     * @memberof ReviseFixedPriceItemEbayDto
     */
    'SellerProfiles': ReviseItemEbayDtoSellerProfiles;
    /**
     * 
     * @type {Array<VariationsItemEbayDto>}
     * @memberof ReviseFixedPriceItemEbayDto
     */
    'Variations': Array<VariationsItemEbayDto>;
    /**
     * 
     * @type {Array<PictureVariation>}
     * @memberof ReviseFixedPriceItemEbayDto
     */
    'Picture'?: Array<PictureVariation>;
}
/**
 * 
 * @export
 * @interface ReviseItemEbayDto
 */
export interface ReviseItemEbayDto {
    /**
     * 
     * @type {number}
     * @memberof ReviseItemEbayDto
     */
    'userEbayId': number;
    /**
     * 
     * @type {string}
     * @memberof ReviseItemEbayDto
     */
    'ItemId': string;
    /**
     * 
     * @type {string}
     * @memberof ReviseItemEbayDto
     */
    'Title': string;
    /**
     * 
     * @type {string}
     * @memberof ReviseItemEbayDto
     */
    'Sku'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReviseItemEbayDto
     */
    'Quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReviseItemEbayDto
     */
    'StartPrice': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReviseItemEbayDto
     */
    'PictureGallery'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ReviseItemEbayDto
     */
    'Country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviseItemEbayDto
     */
    'Location'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviseItemEbayDto
     */
    'PostalCode'?: string;
    /**
     * 
     * @type {ReviseItemEbayDtoSellerProfiles}
     * @memberof ReviseItemEbayDto
     */
    'SellerProfiles': ReviseItemEbayDtoSellerProfiles;
}
/**
 * 
 * @export
 * @interface ReviseItemEbayDtoSellerProfiles
 */
export interface ReviseItemEbayDtoSellerProfiles {
    /**
     * 
     * @type {ReviseItemEbayDtoSellerProfilesSellerShippingProfile}
     * @memberof ReviseItemEbayDtoSellerProfiles
     */
    'SellerShippingProfile'?: ReviseItemEbayDtoSellerProfilesSellerShippingProfile;
    /**
     * 
     * @type {ReviseItemEbayDtoSellerProfilesSellerReturnProfile}
     * @memberof ReviseItemEbayDtoSellerProfiles
     */
    'SellerReturnProfile'?: ReviseItemEbayDtoSellerProfilesSellerReturnProfile;
    /**
     * 
     * @type {ReviseItemEbayDtoSellerProfilesSellerPaymentProfile}
     * @memberof ReviseItemEbayDtoSellerProfiles
     */
    'SellerPaymentProfile'?: ReviseItemEbayDtoSellerProfilesSellerPaymentProfile;
}
/**
 * 
 * @export
 * @interface ReviseItemEbayDtoSellerProfilesSellerPaymentProfile
 */
export interface ReviseItemEbayDtoSellerProfilesSellerPaymentProfile {
    /**
     * 
     * @type {string}
     * @memberof ReviseItemEbayDtoSellerProfilesSellerPaymentProfile
     */
    'PaymentProfileID'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviseItemEbayDtoSellerProfilesSellerPaymentProfile
     */
    'PaymentProfileName'?: string;
}
/**
 * 
 * @export
 * @interface ReviseItemEbayDtoSellerProfilesSellerReturnProfile
 */
export interface ReviseItemEbayDtoSellerProfilesSellerReturnProfile {
    /**
     * 
     * @type {string}
     * @memberof ReviseItemEbayDtoSellerProfilesSellerReturnProfile
     */
    'ReturnProfileID'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviseItemEbayDtoSellerProfilesSellerReturnProfile
     */
    'ReturnProfileName'?: string;
}
/**
 * 
 * @export
 * @interface ReviseItemEbayDtoSellerProfilesSellerShippingProfile
 */
export interface ReviseItemEbayDtoSellerProfilesSellerShippingProfile {
    /**
     * 
     * @type {string}
     * @memberof ReviseItemEbayDtoSellerProfilesSellerShippingProfile
     */
    'ShippingProfileID'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviseItemEbayDtoSellerProfilesSellerShippingProfile
     */
    'ShippingProfileName'?: string;
}
/**
 * 
 * @export
 * @interface ReviseStatus
 */
export interface ReviseStatus {
    /**
     * 
     * @type {string}
     * @memberof ReviseStatus
     */
    'ItemRevised': string;
}
/**
 * 
 * @export
 * @interface SalesTax
 */
export interface SalesTax {
    /**
     * 
     * @type {string}
     * @memberof SalesTax
     */
    'ShippingIncludedInTax': string;
}
/**
 * 
 * @export
 * @interface Seller
 */
export interface Seller {
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'AboutMePage': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'Email': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'FeedbackScore': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'PositiveFeedbackPercent': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'FeedbackPrivate': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'IDVerified': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'eBayGoodStanding': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'NewUser': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'RegistrationDate': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'Site': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'Status': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'UserID': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'UserIDChanged': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'UserIDLastChanged': string;
    /**
     * 
     * @type {SellerInfo}
     * @memberof Seller
     */
    'SellerInfo': SellerInfo;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'MotorsDealer': string;
}
/**
 * 
 * @export
 * @interface SellerInfo
 */
export interface SellerInfo {
    /**
     * 
     * @type {string}
     * @memberof SellerInfo
     */
    'AllowPaymentEdit': string;
    /**
     * 
     * @type {string}
     * @memberof SellerInfo
     */
    'CheckoutEnabled': string;
    /**
     * 
     * @type {string}
     * @memberof SellerInfo
     */
    'CIPBankAccountStored': string;
    /**
     * 
     * @type {string}
     * @memberof SellerInfo
     */
    'GoodStanding': string;
    /**
     * 
     * @type {string}
     * @memberof SellerInfo
     */
    'LiveAuctionAuthorized': string;
    /**
     * 
     * @type {string}
     * @memberof SellerInfo
     */
    'MerchandizingPref': string;
    /**
     * 
     * @type {string}
     * @memberof SellerInfo
     */
    'QualifiesForB2BVAT': string;
    /**
     * 
     * @type {string}
     * @memberof SellerInfo
     */
    'StoreOwner': string;
    /**
     * 
     * @type {string}
     * @memberof SellerInfo
     */
    'StoreURL': string;
    /**
     * 
     * @type {string}
     * @memberof SellerInfo
     */
    'SafePaymentExempt': string;
    /**
     * 
     * @type {string}
     * @memberof SellerInfo
     */
    'TopRatedSeller': string;
}
/**
 * 
 * @export
 * @interface SellerPaymentProfile
 */
export interface SellerPaymentProfile {
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentProfile
     */
    'PaymentProfileID': string;
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentProfile
     */
    'PaymentProfileName': string;
}
/**
 * 
 * @export
 * @interface SellerProfiles
 */
export interface SellerProfiles {
    /**
     * 
     * @type {SellerShippingProfile}
     * @memberof SellerProfiles
     */
    'SellerShippingProfile': SellerShippingProfile;
    /**
     * 
     * @type {SellerReturnProfile}
     * @memberof SellerProfiles
     */
    'SellerReturnProfile': SellerReturnProfile;
    /**
     * 
     * @type {SellerPaymentProfile}
     * @memberof SellerProfiles
     */
    'SellerPaymentProfile': SellerPaymentProfile;
}
/**
 * 
 * @export
 * @interface SellerReturnProfile
 */
export interface SellerReturnProfile {
    /**
     * 
     * @type {string}
     * @memberof SellerReturnProfile
     */
    'ReturnProfileID': string;
    /**
     * 
     * @type {string}
     * @memberof SellerReturnProfile
     */
    'ReturnProfileName': string;
}
/**
 * 
 * @export
 * @interface SellerShippingProfile
 */
export interface SellerShippingProfile {
    /**
     * 
     * @type {string}
     * @memberof SellerShippingProfile
     */
    'ShippingProfileID': string;
    /**
     * 
     * @type {string}
     * @memberof SellerShippingProfile
     */
    'ShippingProfileName': string;
}
/**
 * 
 * @export
 * @interface SellingStatus
 */
export interface SellingStatus {
    /**
     * 
     * @type {string}
     * @memberof SellingStatus
     */
    'BidCount': string;
    /**
     * 
     * @type {CurrencyID}
     * @memberof SellingStatus
     */
    'BidIncrement': CurrencyID;
    /**
     * 
     * @type {CurrencyID}
     * @memberof SellingStatus
     */
    'ConvertedCurrentPrice': CurrencyID;
    /**
     * 
     * @type {CurrencyID}
     * @memberof SellingStatus
     */
    'CurrentPrice': CurrencyID;
    /**
     * 
     * @type {HighBidder}
     * @memberof SellingStatus
     */
    'HighBidder': HighBidder;
    /**
     * 
     * @type {string}
     * @memberof SellingStatus
     */
    'LeadCount': string;
    /**
     * 
     * @type {CurrencyID}
     * @memberof SellingStatus
     */
    'MinimumToBid': CurrencyID;
    /**
     * 
     * @type {string}
     * @memberof SellingStatus
     */
    'QuantitySold': string;
    /**
     * 
     * @type {string}
     * @memberof SellingStatus
     */
    'ReserveMet': string;
    /**
     * 
     * @type {string}
     * @memberof SellingStatus
     */
    'SecondChanceEligible': string;
    /**
     * 
     * @type {string}
     * @memberof SellingStatus
     */
    'ListingStatus': string;
    /**
     * 
     * @type {string}
     * @memberof SellingStatus
     */
    'QuantitySoldByPickupInStore': string;
}
/**
 * 
 * @export
 * @interface SellingStatus2
 */
export interface SellingStatus2 {
    /**
     * 
     * @type {string}
     * @memberof SellingStatus2
     */
    'QuantitySold': string;
    /**
     * 
     * @type {string}
     * @memberof SellingStatus2
     */
    'QuantitySoldByPickupInStore': string;
}
/**
 * 
 * @export
 * @interface SendAllMessageDto
 */
export interface SendAllMessageDto {
    /**
     * 
     * @type {string}
     * @memberof SendAllMessageDto
     */
    'message'?: string;
    /**
     * 
     * @type {Array<FilesDto>}
     * @memberof SendAllMessageDto
     */
    'chatSupportImage'?: Array<FilesDto>;
}
/**
 * 
 * @export
 * @interface SendOtpDto
 */
export interface SendOtpDto {
    /**
     * 
     * @type {string}
     * @memberof SendOtpDto
     */
    'emailAddress': string;
}
/**
 * 
 * @export
 * @interface SettingDto
 */
export interface SettingDto {
    /**
     * 
     * @type {boolean}
     * @memberof SettingDto
     */
    'sendMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SettingDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingDto
     */
    'province'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingDto
     */
    'streetLine'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingDto
     */
    'building'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingDto
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingDto
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingDto
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface SettingNotification
 */
export interface SettingNotification {
    /**
     * 
     * @type {number}
     * @memberof SettingNotification
     */
    'system': number;
    /**
     * 
     * @type {number}
     * @memberof SettingNotification
     */
    'email': number;
    /**
     * 
     * @type {number}
     * @memberof SettingNotification
     */
    'read': number;
    /**
     * 
     * @type {number}
     * @memberof SettingNotification
     */
    'storageDay': number;
    /**
     * 
     * @type {number}
     * @memberof SettingNotification
     */
    'userId': number;
    /**
     * 
     * @type {User}
     * @memberof SettingNotification
     */
    'user': User;
    /**
     * 
     * @type {number}
     * @memberof SettingNotification
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SettingNotification
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SettingNotification
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SettingNotification
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface SettingNotifyDto
 */
export interface SettingNotifyDto {
    /**
     * 
     * @type {boolean}
     * @memberof SettingNotifyDto
     */
    'system': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingNotifyDto
     */
    'email': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingNotifyDto
     */
    'read': boolean;
    /**
     * 
     * @type {number}
     * @memberof SettingNotifyDto
     */
    'storageDay': number;
}
/**
 * 
 * @export
 * @interface ShipBackSellerItem
 */
export interface ShipBackSellerItem {
    /**
     * 
     * @type {number}
     * @memberof ShipBackSellerItem
     */
    'shipBackSellerRequestId': number;
    /**
     * 
     * @type {ShipBackSellerRequest}
     * @memberof ShipBackSellerItem
     */
    'shipBackSellerRequest': ShipBackSellerRequest;
    /**
     * 
     * @type {number}
     * @memberof ShipBackSellerItem
     */
    'storageItemId': number;
    /**
     * 
     * @type {StorageItem}
     * @memberof ShipBackSellerItem
     */
    'storageItem': StorageItem;
    /**
     * 
     * @type {number}
     * @memberof ShipBackSellerItem
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ShipBackSellerItem
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ShipBackSellerItem
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ShipBackSellerItem
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface ShipBackSellerRequest
 */
export interface ShipBackSellerRequest {
    /**
     * 
     * @type {number}
     * @memberof ShipBackSellerRequest
     */
    'userId': number;
    /**
     * 
     * @type {User}
     * @memberof ShipBackSellerRequest
     */
    'user': User;
    /**
     * 
     * @type {number}
     * @memberof ShipBackSellerRequest
     */
    'requestedUserId': number;
    /**
     * 
     * @type {User}
     * @memberof ShipBackSellerRequest
     */
    'requestedUser': User;
    /**
     * 
     * @type {string}
     * @memberof ShipBackSellerRequest
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof ShipBackSellerRequest
     */
    'packedStaffId': number;
    /**
     * 
     * @type {Admin}
     * @memberof ShipBackSellerRequest
     */
    'packedStaff': Admin;
    /**
     * 
     * @type {number}
     * @memberof ShipBackSellerRequest
     */
    'shippedStaffId': number;
    /**
     * 
     * @type {Admin}
     * @memberof ShipBackSellerRequest
     */
    'shippedStaff': Admin;
    /**
     * 
     * @type {number}
     * @memberof ShipBackSellerRequest
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ShipBackSellerRequest
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ShipBackSellerRequest
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ShipBackSellerRequest
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface ShipToBuyerControllerFindAll200Response
 */
export interface ShipToBuyerControllerFindAll200Response {
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerControllerFindAll200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerControllerFindAll200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerControllerFindAll200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<ShipToBuyerRequest>}
     * @memberof ShipToBuyerControllerFindAll200Response
     */
    'content'?: Array<ShipToBuyerRequest>;
}
/**
 * 
 * @export
 * @interface ShipToBuyerControllerFindAll200ResponseAllOf
 */
export interface ShipToBuyerControllerFindAll200ResponseAllOf {
    /**
     * 
     * @type {Array<ShipToBuyerRequest>}
     * @memberof ShipToBuyerControllerFindAll200ResponseAllOf
     */
    'content'?: Array<ShipToBuyerRequest>;
}
/**
 * 
 * @export
 * @interface ShipToBuyerItem
 */
export interface ShipToBuyerItem {
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerItem
     */
    'shipToBuyerRequestId': number;
    /**
     * 
     * @type {ShipToBuyerRequest}
     * @memberof ShipToBuyerItem
     */
    'shipToBuyerRequest': ShipToBuyerRequest;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerItem
     */
    'storageItemId': number;
    /**
     * 
     * @type {StorageItem}
     * @memberof ShipToBuyerItem
     */
    'storageItem': StorageItem;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerItem
     */
    'orderAccountEbayId': number;
    /**
     * 
     * @type {AccountEbay}
     * @memberof ShipToBuyerItem
     */
    'orderAccountEbay': AccountEbay;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'orderStatus': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'orderTransactionId': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'orderItemId': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'orderItemName': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'orderItemSKU': string;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerItem
     */
    'orderItemPrice': number;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'orderItemPriceCurrency': string;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerItem
     */
    'itemPrice': number;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'hsCode': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'ioss': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'orderBuyerId': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'orderBuyerEiasToken': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'orderBuyerCountry': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'orderShippingAddress': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'orderShippingAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'orderShippingName': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'orderShippingTelNo': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'orderShippingZipCode': string;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerItem
     */
    'insuranceFee': number;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerItem
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerItem
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface ShipToBuyerRequest
 */
export interface ShipToBuyerRequest {
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'userId': number;
    /**
     * 
     * @type {User}
     * @memberof ShipToBuyerRequest
     */
    'user': User;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'requestedUserId': number;
    /**
     * 
     * @type {User}
     * @memberof ShipToBuyerRequest
     */
    'requestedUser': User;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'packedStaffId': number;
    /**
     * 
     * @type {Admin}
     * @memberof ShipToBuyerRequest
     */
    'packedStaff': Admin;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'packedAt': string;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'shippedStaffId': number;
    /**
     * 
     * @type {Admin}
     * @memberof ShipToBuyerRequest
     */
    'shippedStaff': Admin;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'shippedAt': string;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'length': number;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'weight': number;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'orderBuyerId': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'orderBuyerEiasToken': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'orderBuyerCountry': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'shippingAddress': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'shippingAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'shippingName': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'shippingTelNo': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'shippingZipCode': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'hsCode': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'ioss': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'shippingCarrier': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'shippingPlan': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'lineItemId': string;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'quantityPurchased': number;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'shippoShippingLabelId': number;
    /**
     * 
     * @type {ShippoShippingLabel}
     * @memberof ShipToBuyerRequest
     */
    'shippoShippingLabel': ShippoShippingLabel;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'shippoShipFee': number;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'shipFee': number;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'profitShipFee': number;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'insuranceFee': number;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'chargeAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'chargeAmountYen': number;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'refundRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'paymentId': number;
    /**
     * 
     * @type {Payment}
     * @memberof ShipToBuyerRequest
     */
    'payment'?: Payment;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'canceledUserId': number;
    /**
     * 
     * @type {User}
     * @memberof ShipToBuyerRequest
     */
    'canceledUser'?: User;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'refundAt': string;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'refundAmountYen': number;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'refundPaymentId': number;
    /**
     * 
     * @type {Payment}
     * @memberof ShipToBuyerRequest
     */
    'refundPayment': Payment;
    /**
     * 
     * @type {ShipToBuyerItem}
     * @memberof ShipToBuyerRequest
     */
    'shipToBuyerItem': ShipToBuyerItem;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'shipByDate': string;
    /**
     * 
     * @type {number}
     * @memberof ShipToBuyerRequest
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ShipToBuyerRequest
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface ShippingAddress
 */
export interface ShippingAddress {
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'Street1': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'Street2': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'CityName': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'StateOrProvince': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'Country': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'CountryName': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'Phone': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'PostalCode': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'AddressID': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    'AddressOwner': string;
}
/**
 * 
 * @export
 * @interface ShippingDetails
 */
export interface ShippingDetails {
    /**
     * 
     * @type {SalesTax}
     * @memberof ShippingDetails
     */
    'SalesTax': SalesTax;
    /**
     * 
     * @type {ShippingServiceOptions}
     * @memberof ShippingDetails
     */
    'ShippingServiceOptions': ShippingServiceOptions;
    /**
     * 
     * @type {string}
     * @memberof ShippingDetails
     */
    'SellingManagerSalesRecordNumber': string;
    /**
     * 
     * @type {TaxTable}
     * @memberof ShippingDetails
     */
    'TaxTable': TaxTable;
}
/**
 * 
 * @export
 * @interface ShippingDetails2
 */
export interface ShippingDetails2 {
    /**
     * 
     * @type {string}
     * @memberof ShippingDetails2
     */
    'SellingManagerSalesRecordNumber': string;
}
/**
 * 
 * @export
 * @interface ShippingFulfillmentDetails
 */
export interface ShippingFulfillmentDetails {
    /**
     * 
     * @type {Array<LineItemReference>}
     * @memberof ShippingFulfillmentDetails
     */
    'lineItems': Array<LineItemReference>;
    /**
     * 
     * @type {string}
     * @memberof ShippingFulfillmentDetails
     */
    'shippedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingFulfillmentDetails
     */
    'shippingCarrierCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingFulfillmentDetails
     */
    'trackingNumber'?: string;
}
/**
 * 
 * @export
 * @interface ShippingOption
 */
export interface ShippingOption {
    /**
     * 
     * @type {string}
     * @memberof ShippingOption
     */
    'costType': string;
    /**
     * 
     * @type {Currency}
     * @memberof ShippingOption
     */
    'insuranceFee': Currency;
    /**
     * 
     * @type {string}
     * @memberof ShippingOption
     */
    'insuranceOffered': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOption
     */
    'optionType': string;
    /**
     * 
     * @type {Currency}
     * @memberof ShippingOption
     */
    'packageHandlingCost': Currency;
    /**
     * 
     * @type {string}
     * @memberof ShippingOption
     */
    'rateTableId': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOption
     */
    'shippingDiscountProfileId': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOption
     */
    'shippingPromotionOffered': string;
    /**
     * 
     * @type {Array<ShippingService>}
     * @memberof ShippingOption
     */
    'shippingServices': Array<ShippingService>;
}
/**
 * 
 * @export
 * @interface ShippingPackageDetails
 */
export interface ShippingPackageDetails {
    /**
     * 
     * @type {string}
     * @memberof ShippingPackageDetails
     */
    'ShippingIrregular': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingPackageDetails
     */
    'ShippingPackage': string;
    /**
     * 
     * @type {MeasurementSystem}
     * @memberof ShippingPackageDetails
     */
    'WeightMajor': MeasurementSystem;
    /**
     * 
     * @type {MeasurementSystem}
     * @memberof ShippingPackageDetails
     */
    'WeightMinor': MeasurementSystem;
}
/**
 * 
 * @export
 * @interface ShippingPackageInfo
 */
export interface ShippingPackageInfo {
    /**
     * 
     * @type {string}
     * @memberof ShippingPackageInfo
     */
    'EstimatedDeliveryTimeMin': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingPackageInfo
     */
    'EstimatedDeliveryTimeMax': string;
}
/**
 * 
 * @export
 * @interface ShippingService
 */
export interface ShippingService {
    /**
     * 
     * @type {Currency}
     * @memberof ShippingService
     */
    'additionalShippingCost': Currency;
    /**
     * 
     * @type {string}
     * @memberof ShippingService
     */
    'buyerResponsibleForPickup': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingService
     */
    'buyerResponsibleForShipping': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingService
     */
    'freeShipping': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingService
     */
    'shippingCarrierCode': string;
    /**
     * 
     * @type {Currency}
     * @memberof ShippingService
     */
    'shippingCost': Currency;
    /**
     * 
     * @type {string}
     * @memberof ShippingService
     */
    'shippingServiceCode': string;
    /**
     * 
     * @type {RegionFilter}
     * @memberof ShippingService
     */
    'shipToLocations': RegionFilter;
    /**
     * 
     * @type {number}
     * @memberof ShippingService
     */
    'sortOrder': number;
    /**
     * 
     * @type {Currency}
     * @memberof ShippingService
     */
    'surcharge': Currency;
}
/**
 * 
 * @export
 * @interface ShippingServiceCost
 */
export interface ShippingServiceCost {
    /**
     * 
     * @type {string}
     * @memberof ShippingServiceCost
     */
    'currencyID': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingServiceCost
     */
    '_@ttribute': string;
}
/**
 * 
 * @export
 * @interface ShippingServiceOptions
 */
export interface ShippingServiceOptions {
    /**
     * 
     * @type {string}
     * @memberof ShippingServiceOptions
     */
    'ShippingService': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingServiceOptions
     */
    'ShippingServicePriority': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingServiceOptions
     */
    'ExpeditedService': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingServiceOptions
     */
    'ShippingTimeMin': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingServiceOptions
     */
    'ShippingTimeMax': string;
}
/**
 * 
 * @export
 * @interface ShippingServiceSelected
 */
export interface ShippingServiceSelected {
    /**
     * 
     * @type {string}
     * @memberof ShippingServiceSelected
     */
    'ShippingService': string;
    /**
     * 
     * @type {ShippingServiceCost}
     * @memberof ShippingServiceSelected
     */
    'ShippingServiceCost': ShippingServiceCost;
}
/**
 * 
 * @export
 * @interface ShippingServiceSelected2
 */
export interface ShippingServiceSelected2 {
    /**
     * 
     * @type {ShippingPackageInfo}
     * @memberof ShippingServiceSelected2
     */
    'ShippingPackageInfo': ShippingPackageInfo;
}
/**
 * 
 * @export
 * @interface ShippoShippingLabel
 */
export interface ShippoShippingLabel {
    /**
     * 
     * @type {string}
     * @memberof ShippoShippingLabel
     */
    'transactionId': string;
    /**
     * 
     * @type {string}
     * @memberof ShippoShippingLabel
     */
    'transactionCreatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ShippoShippingLabel
     */
    'rate': string;
    /**
     * 
     * @type {string}
     * @memberof ShippoShippingLabel
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof ShippoShippingLabel
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ShippoShippingLabel
     */
    'trackingNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ShippoShippingLabel
     */
    'trackingStatus': string;
    /**
     * 
     * @type {string}
     * @memberof ShippoShippingLabel
     */
    'eta': string;
    /**
     * 
     * @type {string}
     * @memberof ShippoShippingLabel
     */
    'trackingUrlProvider': string;
    /**
     * 
     * @type {string}
     * @memberof ShippoShippingLabel
     */
    'labelUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ShippoShippingLabel
     */
    'commercialInvoiceUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ShippoShippingLabel
     */
    'parcel': string;
    /**
     * 
     * @type {string}
     * @memberof ShippoShippingLabel
     */
    'billing': string;
    /**
     * 
     * @type {string}
     * @memberof ShippoShippingLabel
     */
    'qrCodeUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof ShippoShippingLabel
     */
    'handleActionOnEbay': boolean;
    /**
     * 
     * @type {StorageRequest}
     * @memberof ShippoShippingLabel
     */
    'storageRequest': StorageRequest;
    /**
     * 
     * @type {ShipToBuyerRequest}
     * @memberof ShippoShippingLabel
     */
    'shipToBuyerRequest': ShipToBuyerRequest;
    /**
     * 
     * @type {number}
     * @memberof ShippoShippingLabel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ShippoShippingLabel
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ShippoShippingLabel
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ShippoShippingLabel
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface Status
 */
export interface Status {
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    'PaymentHoldStatus': string;
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    'InquiryStatus': string;
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    'ReturnStatus': string;
}
/**
 * 
 * @export
 * @interface StorageItem
 */
export interface StorageItem {
    /**
     * 
     * @type {number}
     * @memberof StorageItem
     */
    'storageRequestId': number;
    /**
     * 
     * @type {StorageRequest}
     * @memberof StorageItem
     */
    'storageRequest': StorageRequest;
    /**
     * 
     * @type {string}
     * @memberof StorageItem
     */
    'internalSku': string;
    /**
     * 
     * @type {number}
     * @memberof StorageItem
     */
    'ebayReturnId': number;
    /**
     * 
     * @type {EbayReturns}
     * @memberof StorageItem
     */
    'ebayReturns'?: EbayReturns;
    /**
     * 
     * @type {string}
     * @memberof StorageItem
     */
    'itemSku': string;
    /**
     * 
     * @type {string}
     * @memberof StorageItem
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof StorageItem
     */
    'itemEditName': string;
    /**
     * 
     * @type {number}
     * @memberof StorageItem
     */
    'itemPrice': number;
    /**
     * 
     * @type {number}
     * @memberof StorageItem
     */
    'storageLength': number;
    /**
     * 
     * @type {number}
     * @memberof StorageItem
     */
    'storageWidth': number;
    /**
     * 
     * @type {number}
     * @memberof StorageItem
     */
    'storageHeight': number;
    /**
     * 
     * @type {number}
     * @memberof StorageItem
     */
    'storageWeight': number;
    /**
     * 
     * @type {number}
     * @memberof StorageItem
     */
    'storageVolumeWeight': number;
    /**
     * 
     * @type {number}
     * @memberof StorageItem
     */
    'storageUseWeight': number;
    /**
     * 
     * @type {number}
     * @memberof StorageItem
     */
    'baseFee': number;
    /**
     * 
     * @type {number}
     * @memberof StorageItem
     */
    'realBaseFee': number;
    /**
     * 
     * @type {number}
     * @memberof StorageItem
     */
    'appliedCouponRate': number;
    /**
     * 
     * @type {string}
     * @memberof StorageItem
     */
    'userNote': string;
    /**
     * 
     * @type {string}
     * @memberof StorageItem
     */
    'staffComment': string;
    /**
     * 
     * @type {string}
     * @memberof StorageItem
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof StorageItem
     */
    'requestStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageItem
     */
    'inquiryStatus': string;
    /**
     * 
     * @type {string}
     * @memberof StorageItem
     */
    'internalDiscussStatus': string;
    /**
     * 
     * @type {string}
     * @memberof StorageItem
     */
    'lastTimeEditStorageColumn': string;
    /**
     * 
     * @type {boolean}
     * @memberof StorageItem
     */
    'isRevised': boolean;
    /**
     * 
     * @type {string}
     * @memberof StorageItem
     */
    'storageFromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageItem
     */
    'storageToDate'?: string;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof StorageItem
     */
    'photos': Array<Asset>;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof StorageItem
     */
    'videos': Array<Asset>;
    /**
     * 
     * @type {Array<StorageItemRack>}
     * @memberof StorageItem
     */
    'storageItemRacks': Array<StorageItemRack>;
    /**
     * 
     * @type {Array<StorageMonthlyPayment>}
     * @memberof StorageItem
     */
    'storageMonthlyPayments': Array<StorageMonthlyPayment>;
    /**
     * 
     * @type {Array<ShipToBuyerItem>}
     * @memberof StorageItem
     */
    'shipToBuyerItems': Array<ShipToBuyerItem>;
    /**
     * 
     * @type {ShippoShippingLabel}
     * @memberof StorageItem
     */
    'shippoShippingLabel': ShippoShippingLabel;
    /**
     * 
     * @type {ShipToBuyerItem}
     * @memberof StorageItem
     */
    'shipToBuyerItem': ShipToBuyerItem;
    /**
     * 
     * @type {ShipToBuyerRequest}
     * @memberof StorageItem
     */
    'shipToBuyerRequest': ShipToBuyerRequest;
    /**
     * 
     * @type {ShipBackSellerItem}
     * @memberof StorageItem
     */
    'shipBackSellerItem': ShipBackSellerItem;
    /**
     * 
     * @type {ShipBackSellerRequest}
     * @memberof StorageItem
     */
    'shipBackSellerRequest': ShipBackSellerRequest;
    /**
     * 
     * @type {TakePhotoRequest}
     * @memberof StorageItem
     */
    'takePhotoRequest': TakePhotoRequest;
    /**
     * 
     * @type {Array<TakePhotoRequest>}
     * @memberof StorageItem
     */
    'takePhotoRequests': Array<TakePhotoRequest>;
    /**
     * 
     * @type {DisposalRequest}
     * @memberof StorageItem
     */
    'disposalRequest': DisposalRequest;
    /**
     * 
     * @type {ItemMessageGroup}
     * @memberof StorageItem
     */
    'itemMessageGroup': ItemMessageGroup;
    /**
     * 
     * @type {Admin}
     * @memberof StorageItem
     */
    'assign': Admin;
    /**
     * 
     * @type {StorageItemRack}
     * @memberof StorageItem
     */
    'storageItemRack': StorageItemRack;
    /**
     * 
     * @type {StorageItemNote}
     * @memberof StorageItem
     */
    'storageItemNote': StorageItemNote;
    /**
     * 
     * @type {number}
     * @memberof StorageItem
     */
    'totalFee': number;
    /**
     * 
     * @type {boolean}
     * @memberof StorageItem
     */
    'totalFeeStatus': boolean;
    /**
     * 
     * @type {number}
     * @memberof StorageItem
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof StorageItem
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof StorageItem
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof StorageItem
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface StorageItemControllerStorageManagementAdmin200Response
 */
export interface StorageItemControllerStorageManagementAdmin200Response {
    /**
     * 
     * @type {number}
     * @memberof StorageItemControllerStorageManagementAdmin200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof StorageItemControllerStorageManagementAdmin200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof StorageItemControllerStorageManagementAdmin200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<StorageItem>}
     * @memberof StorageItemControllerStorageManagementAdmin200Response
     */
    'content'?: Array<StorageItem>;
}
/**
 * 
 * @export
 * @interface StorageItemControllerStorageManagementAdmin200ResponseAllOf
 */
export interface StorageItemControllerStorageManagementAdmin200ResponseAllOf {
    /**
     * 
     * @type {Array<StorageItem>}
     * @memberof StorageItemControllerStorageManagementAdmin200ResponseAllOf
     */
    'content'?: Array<StorageItem>;
}
/**
 * 
 * @export
 * @interface StorageItemDto
 */
export interface StorageItemDto {
    /**
     * 
     * @type {string}
     * @memberof StorageItemDto
     */
    'itemName': string;
    /**
     * 
     * @type {number}
     * @memberof StorageItemDto
     */
    'itemPrice': number;
    /**
     * 
     * @type {string}
     * @memberof StorageItemDto
     */
    'userNote': string;
    /**
     * 
     * @type {number}
     * @memberof StorageItemDto
     */
    'reportLength'?: number;
    /**
     * 
     * @type {number}
     * @memberof StorageItemDto
     */
    'reportWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof StorageItemDto
     */
    'reportHeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof StorageItemDto
     */
    'reportWeight'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof StorageItemDto
     */
    'patternIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface StorageItemNote
 */
export interface StorageItemNote {
    /**
     * 
     * @type {number}
     * @memberof StorageItemNote
     */
    'storageItemId': number;
    /**
     * 
     * @type {StorageItem}
     * @memberof StorageItemNote
     */
    'storageItem': StorageItem;
    /**
     * 
     * @type {number}
     * @memberof StorageItemNote
     */
    'staffId': number;
    /**
     * 
     * @type {Admin}
     * @memberof StorageItemNote
     */
    'staff': Admin;
    /**
     * 
     * @type {number}
     * @memberof StorageItemNote
     */
    'userId': number;
    /**
     * 
     * @type {User}
     * @memberof StorageItemNote
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof StorageItemNote
     */
    'content': string;
    /**
     * 
     * @type {number}
     * @memberof StorageItemNote
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof StorageItemNote
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof StorageItemNote
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof StorageItemNote
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface StorageItemNoteControllerFindAllByItemStorageId200Response
 */
export interface StorageItemNoteControllerFindAllByItemStorageId200Response {
    /**
     * 
     * @type {number}
     * @memberof StorageItemNoteControllerFindAllByItemStorageId200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof StorageItemNoteControllerFindAllByItemStorageId200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof StorageItemNoteControllerFindAllByItemStorageId200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<StorageItemNote>}
     * @memberof StorageItemNoteControllerFindAllByItemStorageId200Response
     */
    'content'?: Array<StorageItemNote>;
}
/**
 * 
 * @export
 * @interface StorageItemNoteControllerFindAllByItemStorageId200ResponseAllOf
 */
export interface StorageItemNoteControllerFindAllByItemStorageId200ResponseAllOf {
    /**
     * 
     * @type {Array<StorageItemNote>}
     * @memberof StorageItemNoteControllerFindAllByItemStorageId200ResponseAllOf
     */
    'content'?: Array<StorageItemNote>;
}
/**
 * 
 * @export
 * @interface StorageItemRack
 */
export interface StorageItemRack {
    /**
     * 
     * @type {number}
     * @memberof StorageItemRack
     */
    'storageItemId': number;
    /**
     * 
     * @type {StorageItem}
     * @memberof StorageItemRack
     */
    'storageItem': StorageItem;
    /**
     * 
     * @type {number}
     * @memberof StorageItemRack
     */
    'rackId': number;
    /**
     * 
     * @type {Rack}
     * @memberof StorageItemRack
     */
    'rack': Rack;
    /**
     * 
     * @type {number}
     * @memberof StorageItemRack
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof StorageItemRack
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof StorageItemRack
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof StorageItemRack
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface StorageMonthlyPayment
 */
export interface StorageMonthlyPayment {
    /**
     * 
     * @type {number}
     * @memberof StorageMonthlyPayment
     */
    'storageItemId': number;
    /**
     * 
     * @type {StorageItem}
     * @memberof StorageMonthlyPayment
     */
    'storageItem': StorageItem;
    /**
     * 
     * @type {number}
     * @memberof StorageMonthlyPayment
     */
    'chargeAmount': number;
    /**
     * 
     * @type {number}
     * @memberof StorageMonthlyPayment
     */
    'chargeAmountYen': number;
    /**
     * 
     * @type {string}
     * @memberof StorageMonthlyPayment
     */
    'storageFromDate': string;
    /**
     * 
     * @type {string}
     * @memberof StorageMonthlyPayment
     */
    'storageToDate': string;
    /**
     * 
     * @type {number}
     * @memberof StorageMonthlyPayment
     */
    'paymentId': number;
    /**
     * 
     * @type {Payment}
     * @memberof StorageMonthlyPayment
     */
    'payment': Payment;
    /**
     * 
     * @type {number}
     * @memberof StorageMonthlyPayment
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof StorageMonthlyPayment
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof StorageMonthlyPayment
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof StorageMonthlyPayment
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface StorageRequest
 */
export interface StorageRequest {
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'userId': number;
    /**
     * 
     * @type {User}
     * @memberof StorageRequest
     */
    'user': User;
    /**
     * 
     * @type {boolean}
     * @memberof StorageRequest
     */
    'sellerPayFlag': boolean;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'requestedUserId': number;
    /**
     * 
     * @type {User}
     * @memberof StorageRequest
     */
    'requestedUser': User;
    /**
     * 
     * @type {PlanVersionPattern}
     * @memberof StorageRequest
     */
    'planVersionPattern': PlanVersionPattern;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'planVersionPatternId': number;
    /**
     * 
     * @type {string}
     * @memberof StorageRequest
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof StorageRequest
     */
    'checkItemFinishAt': string;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'reportLength': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'reportWidth': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'reportHeight': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'reportWeight': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'reportVolumeWeight': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'reportUseWeight': number;
    /**
     * 
     * @type {boolean}
     * @memberof StorageRequest
     */
    'isReportTrue': boolean;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'realLength': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'realWidth': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'realHeight': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'realWeight': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'realVolumeWeight': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'realUseWeight': number;
    /**
     * 
     * @type {string}
     * @memberof StorageRequest
     */
    'staffComment': string;
    /**
     * 
     * @type {string}
     * @memberof StorageRequest
     */
    'shipFromAddress': string;
    /**
     * 
     * @type {string}
     * @memberof StorageRequest
     */
    'shipFromAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof StorageRequest
     */
    'shipFromName': string;
    /**
     * 
     * @type {string}
     * @memberof StorageRequest
     */
    'shipFromTelNo': string;
    /**
     * 
     * @type {string}
     * @memberof StorageRequest
     */
    'shippingCarrier': string;
    /**
     * 
     * @type {string}
     * @memberof StorageRequest
     */
    'shippingPlan': string;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'shippoShippingLabelId': number;
    /**
     * 
     * @type {ShippoShippingLabel}
     * @memberof StorageRequest
     */
    'shippoShippingLabel'?: ShippoShippingLabel;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'shippoShipFee': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'shipFee': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'realShipFee': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'profitShipFee': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'insuranceFee': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'baseFee': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'realBaseFee': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'unboxVideoFee': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'couponId': number;
    /**
     * 
     * @type {Coupon}
     * @memberof StorageRequest
     */
    'coupon'?: Coupon;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'appliedCouponRate': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'storageRequestTotalFee': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'storageRequestTotalFeeYen': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'refundRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'chargeAmount': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'chargeAmountYen': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'paymentId': number;
    /**
     * 
     * @type {Payment}
     * @memberof StorageRequest
     */
    'payment'?: Payment;
    /**
     * 
     * @type {string}
     * @memberof StorageRequest
     */
    'estimateDeliveryAt': string;
    /**
     * 
     * @type {string}
     * @memberof StorageRequest
     */
    'deliveredAt': string;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'canceledUserId': number;
    /**
     * 
     * @type {User}
     * @memberof StorageRequest
     */
    'canceledUser'?: User;
    /**
     * 
     * @type {string}
     * @memberof StorageRequest
     */
    'refundAt': string;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'refundAmountYen': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'refundPaymentId': number;
    /**
     * 
     * @type {Payment}
     * @memberof StorageRequest
     */
    'refundPayment': Payment;
    /**
     * 
     * @type {StorageItem}
     * @memberof StorageRequest
     */
    'storageItem': StorageItem;
    /**
     * 
     * @type {boolean}
     * @memberof StorageRequest
     */
    'actionOnEbay': boolean;
    /**
     * 
     * @type {string}
     * @memberof StorageRequest
     */
    'trackingNumberProvided': string;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof StorageRequest
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof StorageRequest
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof StorageRequest
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface StorageRequestControllerGetAll200Response
 */
export interface StorageRequestControllerGetAll200Response {
    /**
     * 
     * @type {number}
     * @memberof StorageRequestControllerGetAll200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequestControllerGetAll200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequestControllerGetAll200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<StorageRequest>}
     * @memberof StorageRequestControllerGetAll200Response
     */
    'content'?: Array<StorageRequest>;
}
/**
 * 
 * @export
 * @interface StorageRequestControllerGetAll200ResponseAllOf
 */
export interface StorageRequestControllerGetAll200ResponseAllOf {
    /**
     * 
     * @type {Array<StorageRequest>}
     * @memberof StorageRequestControllerGetAll200ResponseAllOf
     */
    'content'?: Array<StorageRequest>;
}
/**
 * 
 * @export
 * @interface StorageRequestDto
 */
export interface StorageRequestDto {
    /**
     * 
     * @type {number}
     * @memberof StorageRequestDto
     */
    'planVersionPatternId': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequestDto
     */
    'ebayReturnId': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequestDto
     */
    'reportLength': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequestDto
     */
    'reportWidth': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequestDto
     */
    'reportHeight': number;
    /**
     * 
     * @type {number}
     * @memberof StorageRequestDto
     */
    'reportWeight': number;
    /**
     * 
     * @type {string}
     * @memberof StorageRequestDto
     */
    'shippingCarrier'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageRequestDto
     */
    'shippingPlan'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageRequestDto
     */
    'shippingRate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StorageRequestDto
     */
    'isInsurance': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StorageRequestDto
     */
    'isUnboxVideo': boolean;
    /**
     * 
     * @type {string}
     * @memberof StorageRequestDto
     */
    'trackingNumberProvided'?: string;
    /**
     * 
     * @type {number}
     * @memberof StorageRequestDto
     */
    'couponId'?: number;
}
/**
 * 
 * @export
 * @interface Storefront
 */
export interface Storefront {
    /**
     * 
     * @type {string}
     * @memberof Storefront
     */
    'StoreCategoryID': string;
    /**
     * 
     * @type {string}
     * @memberof Storefront
     */
    'StoreCategory2ID': string;
    /**
     * 
     * @type {string}
     * @memberof Storefront
     */
    'StoreURL': string;
}
/**
 * 
 * @export
 * @interface SubContentMonthPaymentDetail
 */
export interface SubContentMonthPaymentDetail {
    /**
     * 
     * @type {string}
     * @memberof SubContentMonthPaymentDetail
     */
    'title': string;
    /**
     * 
     * @type {AccountEbay}
     * @memberof SubContentMonthPaymentDetail
     */
    'accountEbay': AccountEbay;
    /**
     * 
     * @type {StorageItem}
     * @memberof SubContentMonthPaymentDetail
     */
    'storageItem': StorageItem;
    /**
     * 
     * @type {number}
     * @memberof SubContentMonthPaymentDetail
     */
    'chargeAmount': number;
    /**
     * 
     * @type {number}
     * @memberof SubContentMonthPaymentDetail
     */
    'chargeAmountYen': number;
    /**
     * 
     * @type {boolean}
     * @memberof SubContentMonthPaymentDetail
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface Subtotal
 */
export interface Subtotal {
    /**
     * 
     * @type {string}
     * @memberof Subtotal
     */
    'currencyID': string;
    /**
     * 
     * @type {string}
     * @memberof Subtotal
     */
    '_@ttribute': string;
}
/**
 * 
 * @export
 * @interface SystemNotification
 */
export interface SystemNotification {
    /**
     * 
     * @type {string}
     * @memberof SystemNotification
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SystemNotification
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof SystemNotification
     */
    'timeSend': string;
    /**
     * 
     * @type {number}
     * @memberof SystemNotification
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SystemNotification
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SystemNotification
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SystemNotification
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface SystemNotificationControllerGet200Response
 */
export interface SystemNotificationControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof SystemNotificationControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof SystemNotificationControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SystemNotificationControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<SystemNotification>}
     * @memberof SystemNotificationControllerGet200Response
     */
    'content'?: Array<SystemNotification>;
}
/**
 * 
 * @export
 * @interface SystemNotificationControllerGet200ResponseAllOf
 */
export interface SystemNotificationControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<SystemNotification>}
     * @memberof SystemNotificationControllerGet200ResponseAllOf
     */
    'content'?: Array<SystemNotification>;
}
/**
 * 
 * @export
 * @interface SystemNotificationUser
 */
export interface SystemNotificationUser {
    /**
     * 
     * @type {number}
     * @memberof SystemNotificationUser
     */
    'systemNotificationId': number;
    /**
     * 
     * @type {number}
     * @memberof SystemNotificationUser
     */
    'userId': number;
    /**
     * 
     * @type {number}
     * @memberof SystemNotificationUser
     */
    'readFlag': number;
    /**
     * 
     * @type {SystemNotification}
     * @memberof SystemNotificationUser
     */
    'systemNotification': SystemNotification;
    /**
     * 
     * @type {User}
     * @memberof SystemNotificationUser
     */
    'user': User;
    /**
     * 
     * @type {number}
     * @memberof SystemNotificationUser
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SystemNotificationUser
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SystemNotificationUser
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SystemNotificationUser
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface TakePhotoRequest
 */
export interface TakePhotoRequest {
    /**
     * 
     * @type {number}
     * @memberof TakePhotoRequest
     */
    'requestedUserId': number;
    /**
     * 
     * @type {User}
     * @memberof TakePhotoRequest
     */
    'requestedUser': User;
    /**
     * 
     * @type {number}
     * @memberof TakePhotoRequest
     */
    'storageItemId': number;
    /**
     * 
     * @type {StorageItem}
     * @memberof TakePhotoRequest
     */
    'storageItem': StorageItem;
    /**
     * 
     * @type {string}
     * @memberof TakePhotoRequest
     */
    'userMessage': string;
    /**
     * 
     * @type {string}
     * @memberof TakePhotoRequest
     */
    'userMessageTranslated': string;
    /**
     * 
     * @type {string}
     * @memberof TakePhotoRequest
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof TakePhotoRequest
     */
    'photoQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof TakePhotoRequest
     */
    'takePhotoStaffId': number;
    /**
     * 
     * @type {Admin}
     * @memberof TakePhotoRequest
     */
    'takePhotoStaff': Admin;
    /**
     * 
     * @type {string}
     * @memberof TakePhotoRequest
     */
    'takePhotoAt': string;
    /**
     * 
     * @type {number}
     * @memberof TakePhotoRequest
     */
    'uploadPhotoStaffId': number;
    /**
     * 
     * @type {Admin}
     * @memberof TakePhotoRequest
     */
    'uploadPhotoStaff': Admin;
    /**
     * 
     * @type {string}
     * @memberof TakePhotoRequest
     */
    'uploadPhotoAt': string;
    /**
     * 
     * @type {number}
     * @memberof TakePhotoRequest
     */
    'chargeAmount': number;
    /**
     * 
     * @type {number}
     * @memberof TakePhotoRequest
     */
    'chargeAmountYen': number;
    /**
     * 
     * @type {number}
     * @memberof TakePhotoRequest
     */
    'paymentId': number;
    /**
     * 
     * @type {Payment}
     * @memberof TakePhotoRequest
     */
    'payment'?: Payment;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof TakePhotoRequest
     */
    'photos': Array<Asset>;
    /**
     * 
     * @type {number}
     * @memberof TakePhotoRequest
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TakePhotoRequest
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TakePhotoRequest
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof TakePhotoRequest
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface Tax
 */
export interface Tax {
    /**
     * 
     * @type {Amount}
     * @memberof Tax
     */
    'TAmount': Amount;
    /**
     * 
     * @type {string}
     * @memberof Tax
     */
    'taxType': string;
}
/**
 * 
 * @export
 * @interface TaxAddress
 */
export interface TaxAddress {
    /**
     * 
     * @type {string}
     * @memberof TaxAddress
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxAddress
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxAddress
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxAddress
     */
    'stateOrProvince'?: string;
}
/**
 * 
 * @export
 * @interface TaxAmount
 */
export interface TaxAmount {
    /**
     * 
     * @type {string}
     * @memberof TaxAmount
     */
    'currencyID': string;
    /**
     * 
     * @type {string}
     * @memberof TaxAmount
     */
    '_@ttribute': string;
}
/**
 * 
 * @export
 * @interface TaxDetails
 */
export interface TaxDetails {
    /**
     * 
     * @type {string}
     * @memberof TaxDetails
     */
    'Imposition': string;
    /**
     * 
     * @type {string}
     * @memberof TaxDetails
     */
    'TaxDescription': string;
    /**
     * 
     * @type {TaxAmount}
     * @memberof TaxDetails
     */
    'TaxAmount': TaxAmount;
    /**
     * 
     * @type {TaxOnSubtotalAmount}
     * @memberof TaxDetails
     */
    'TaxOnSubtotalAmount': TaxOnSubtotalAmount;
    /**
     * 
     * @type {TaxOnShippingAmount}
     * @memberof TaxDetails
     */
    'TaxOnShippingAmount': TaxOnShippingAmount;
    /**
     * 
     * @type {TaxOnHandlingAmount}
     * @memberof TaxDetails
     */
    'TaxOnHandlingAmount': TaxOnHandlingAmount;
}
/**
 * 
 * @export
 * @interface TaxJurisdiction
 */
export interface TaxJurisdiction {
    /**
     * 
     * @type {string}
     * @memberof TaxJurisdiction
     */
    'SalesTaxPercent': string;
    /**
     * 
     * @type {string}
     * @memberof TaxJurisdiction
     */
    'ShippingIncludedInTax': string;
}
/**
 * 
 * @export
 * @interface TaxOnHandlingAmount
 */
export interface TaxOnHandlingAmount {
    /**
     * 
     * @type {string}
     * @memberof TaxOnHandlingAmount
     */
    'currencyID': string;
    /**
     * 
     * @type {string}
     * @memberof TaxOnHandlingAmount
     */
    '_@ttribute': string;
}
/**
 * 
 * @export
 * @interface TaxOnShippingAmount
 */
export interface TaxOnShippingAmount {
    /**
     * 
     * @type {string}
     * @memberof TaxOnShippingAmount
     */
    'currencyID': string;
    /**
     * 
     * @type {string}
     * @memberof TaxOnShippingAmount
     */
    '_@ttribute': string;
}
/**
 * 
 * @export
 * @interface TaxOnSubtotalAmount
 */
export interface TaxOnSubtotalAmount {
    /**
     * 
     * @type {string}
     * @memberof TaxOnSubtotalAmount
     */
    'currencyID': string;
    /**
     * 
     * @type {string}
     * @memberof TaxOnSubtotalAmount
     */
    '_@ttribute': string;
}
/**
 * 
 * @export
 * @interface TaxTable
 */
export interface TaxTable {
    /**
     * 
     * @type {TaxJurisdiction}
     * @memberof TaxTable
     */
    'TaxJurisdiction': TaxJurisdiction;
}
/**
 * 
 * @export
 * @interface Taxes
 */
export interface Taxes {
    /**
     * 
     * @type {TotalTaxAmount}
     * @memberof Taxes
     */
    'TotalTaxAmount': TotalTaxAmount;
    /**
     * 
     * @type {TaxDetails}
     * @memberof Taxes
     */
    'TaxDetails': TaxDetails;
}
/**
 * 
 * @export
 * @interface Total
 */
export interface Total {
    /**
     * 
     * @type {string}
     * @memberof Total
     */
    'currencyID': string;
    /**
     * 
     * @type {string}
     * @memberof Total
     */
    '_@ttribute': string;
}
/**
 * 
 * @export
 * @interface TotalTaxAmount
 */
export interface TotalTaxAmount {
    /**
     * 
     * @type {string}
     * @memberof TotalTaxAmount
     */
    'currencyID': string;
    /**
     * 
     * @type {string}
     * @memberof TotalTaxAmount
     */
    '_@ttribute': string;
}
/**
 * 
 * @export
 * @interface TrackingInfo
 */
export interface TrackingInfo {
    /**
     * 
     * @type {string}
     * @memberof TrackingInfo
     */
    'shipmentTrackingNumber': string;
    /**
     * 
     * @type {string}
     * @memberof TrackingInfo
     */
    'shippingCarrierCode': string;
}
/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {Buyer}
     * @memberof Transaction
     */
    'Buyer': Buyer;
    /**
     * 
     * @type {ShippingDetails2}
     * @memberof Transaction
     */
    'ShippingDetails': ShippingDetails2;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'CreatedDate': string;
    /**
     * 
     * @type {Item}
     * @memberof Transaction
     */
    'Item': Item;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'QuantityPurchased': string;
    /**
     * 
     * @type {Status}
     * @memberof Transaction
     */
    'Status': Status;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'TransactionID': string;
    /**
     * 
     * @type {TransactionPrice}
     * @memberof Transaction
     */
    'TransactionPrice': TransactionPrice;
    /**
     * 
     * @type {ShippingServiceSelected2}
     * @memberof Transaction
     */
    'ShippingServiceSelected': ShippingServiceSelected2;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'TransactionSiteID': string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'Platform': string;
    /**
     * 
     * @type {Variation}
     * @memberof Transaction
     */
    'Variation': Variation;
    /**
     * 
     * @type {Taxes}
     * @memberof Transaction
     */
    'Taxes': Taxes;
    /**
     * 
     * @type {ActualShippingCost}
     * @memberof Transaction
     */
    'ActualShippingCost': ActualShippingCost;
    /**
     * 
     * @type {ActualHandlingCost}
     * @memberof Transaction
     */
    'ActualHandlingCost': ActualHandlingCost;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'OrderLineItemID': string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'InventoryReservationID': string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'ExtendedOrderID': string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'eBayPlusTransaction': string;
}
/**
 * 
 * @export
 * @interface TransactionArray
 */
export interface TransactionArray {
    /**
     * 
     * @type {Transaction}
     * @memberof TransactionArray
     */
    'Transaction': Transaction;
}
/**
 * 
 * @export
 * @interface TransactionPrice
 */
export interface TransactionPrice {
    /**
     * 
     * @type {string}
     * @memberof TransactionPrice
     */
    'currencyID': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionPrice
     */
    '_@ttribute': string;
}
/**
 * 
 * @export
 * @interface TranslateMessageDto
 */
export interface TranslateMessageDto {
    /**
     * 
     * @type {number}
     * @memberof TranslateMessageDto
     */
    'internalMessageId': number;
}
/**
 * 
 * @export
 * @interface TranslatePhotoRequest
 */
export interface TranslatePhotoRequest {
    /**
     * 
     * @type {number}
     * @memberof TranslatePhotoRequest
     */
    'photoRequestId': number;
}
/**
 * 
 * @export
 * @interface UnitTime
 */
export interface UnitTime {
    /**
     * 
     * @type {string}
     * @memberof UnitTime
     */
    'unit': UnitTimeUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof UnitTime
     */
    'value': number;
}

export const UnitTimeUnitEnum = {
    Year: 'YEAR',
    Month: 'MONTH',
    Day: 'DAY',
    Hour: 'HOUR',
    CalendarDay: 'CALENDAR_DAY',
    BusinessDay: 'BUSINESS_DAY',
    Minute: 'MINUTE',
    Second: 'SECOND',
    Millisecond: 'MILLISECOND'
} as const;

export type UnitTimeUnitEnum = typeof UnitTimeUnitEnum[keyof typeof UnitTimeUnitEnum];

/**
 * 
 * @export
 * @interface UnsubscriptionInformationResponse
 */
export interface UnsubscriptionInformationResponse {
    /**
     * 
     * @type {string}
     * @memberof UnsubscriptionInformationResponse
     */
    'nextPaymentAt': string;
    /**
     * 
     * @type {string}
     * @memberof UnsubscriptionInformationResponse
     */
    'endPlanAt': string;
    /**
     * 
     * @type {string}
     * @memberof UnsubscriptionInformationResponse
     */
    'joinAgainAt': string;
}
/**
 * 
 * @export
 * @interface UpdateDisposalRequestDto
 */
export interface UpdateDisposalRequestDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateDisposalRequestDto
     */
    'disposalRequestId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDisposalRequestDto
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface UpdateEmployeeDto
 */
export interface UpdateEmployeeDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateEmployeeDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDto
     */
    'loginId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDto
     */
    'password'?: string;
    /**
     * 
     * @type {CreateEmployeeDtoPermission}
     * @memberof UpdateEmployeeDto
     */
    'permission': CreateEmployeeDtoPermission;
    /**
     * 
     * @type {Asset}
     * @memberof UpdateEmployeeDto
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEmployeeDto
     */
    'isOperation'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateGroupDto
 */
export interface UpdateGroupDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateGroupDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateGroupDto
     */
    'supportStatus': number;
}
/**
 * 
 * @export
 * @interface UpdateItemGroupDto
 */
export interface UpdateItemGroupDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateItemGroupDto
     */
    'assignId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemGroupDto
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemGroupDto
     */
    'type'?: number;
}
/**
 * 
 * @export
 * @interface UpdateMeAdminDto
 */
export interface UpdateMeAdminDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMeAdminDto
     */
    'loginId'?: string;
    /**
     * 
     * @type {Asset}
     * @memberof UpdateMeAdminDto
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeAdminDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UpdateNotiDto
 */
export interface UpdateNotiDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateNotiDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotiDto
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface UpdatePaymentSettingDto
 */
export interface UpdatePaymentSettingDto {
    /**
     * 
     * @type {number}
     * @memberof UpdatePaymentSettingDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentSettingDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentSettingDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentSettingDto
     */
    'activationDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePaymentSettingDto
     */
    'shippoSurchargeConstInUS'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePaymentSettingDto
     */
    'shippoSurchargeConstOutUS'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePaymentSettingDto
     */
    'videoRecordFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePaymentSettingDto
     */
    'disposalFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePaymentSettingDto
     */
    'takePhotoFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePaymentSettingDto
     */
    'cancellationConst'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePaymentSettingDto
     */
    'trackingNumberNotRegisteredFee'?: number;
    /**
     * 
     * @type {Array<PlanVersionDto>}
     * @memberof UpdatePaymentSettingDto
     */
    'planVersions'?: Array<PlanVersionDto>;
}
/**
 * 
 * @export
 * @interface UpdatePlanDto
 */
export interface UpdatePlanDto {
    /**
     * 
     * @type {number}
     * @memberof UpdatePlanDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UpdatePreparingDoneDto
 */
export interface UpdatePreparingDoneDto {
    /**
     * 
     * @type {number}
     * @memberof UpdatePreparingDoneDto
     */
    'shipToBuyerItemId': number;
    /**
     * 
     * @type {string}
     * @memberof UpdatePreparingDoneDto
     */
    'internalSku': string;
}
/**
 * 
 * @export
 * @interface UpdateRackDto
 */
export interface UpdateRackDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRackDto
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface UpdateShipToBuyerDto
 */
export interface UpdateShipToBuyerDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateShipToBuyerDto
     */
    'fullTextSearch'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateShipToBuyerDto
     */
    'providers'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UpdateShipToBuyerDto
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface UpdateStorageItemNoteDto
 */
export interface UpdateStorageItemNoteDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateStorageItemNoteDto
     */
    'storageItemId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateStorageItemNoteDto
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface UpdateStorageItemPhotos
 */
export interface UpdateStorageItemPhotos {
    /**
     * 
     * @type {number}
     * @memberof UpdateStorageItemPhotos
     */
    'storageItemId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateStorageItemPhotos
     */
    'photoRequestId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateStorageItemPhotos
     */
    'assetIds': Array<number>;
}
/**
 * 
 * @export
 * @interface UpdateStorageItemStatus
 */
export interface UpdateStorageItemStatus {
    /**
     * 
     * @type {number}
     * @memberof UpdateStorageItemStatus
     */
    'storageItemId': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateStorageItemStatus
     */
    'status': UpdateStorageItemStatusStatusEnum;
}

export const UpdateStorageItemStatusStatusEnum = {
    NotDelivered: 'NOT_DELIVERED',
    Delivered: 'DELIVERED',
    WaitingStorage: 'WAITING_STORAGE',
    WaitingUploadImageVideo: 'WAITING_UPLOAD_IMAGE_VIDEO',
    InStorage: 'IN_STORAGE',
    ShippedToBuyer: 'SHIPPED_TO_BUYER',
    ShippedBack: 'SHIPPED_BACK',
    Disposed: 'DISPOSED'
} as const;

export type UpdateStorageItemStatusStatusEnum = typeof UpdateStorageItemStatusStatusEnum[keyof typeof UpdateStorageItemStatusStatusEnum];

/**
 * 
 * @export
 * @interface UpdateSubUserDto
 */
export interface UpdateSubUserDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateSubUserDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateSubUserDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSubUserDto
     */
    'name'?: string;
    /**
     * 
     * @type {CreateSubUserDtoPermission}
     * @memberof UpdateSubUserDto
     */
    'permission': CreateSubUserDtoPermission;
}
/**
 * 
 * @export
 * @interface UpdateTakePhotos
 */
export interface UpdateTakePhotos {
    /**
     * 
     * @type {number}
     * @memberof UpdateTakePhotos
     */
    'photoRequestId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateTakePhotos
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface UpdateTrackingNumber
 */
export interface UpdateTrackingNumber {
    /**
     * 
     * @type {number}
     * @memberof UpdateTrackingNumber
     */
    'storageRequestId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateTrackingNumber
     */
    'storageItemId': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateTrackingNumber
     */
    'trackingNumberProvided': string;
}
/**
 * 
 * @export
 * @interface UpdateUserCardDto
 */
export interface UpdateUserCardDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCardDto
     */
    'cardToken': string;
}
/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'otp'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {Asset}
     * @memberof UpdateUserDto
     */
    'avatar'?: Asset;
}
/**
 * 
 * @export
 * @interface UploadPhotoAndVideoDto
 */
export interface UploadPhotoAndVideoDto {
    /**
     * 
     * @type {number}
     * @memberof UploadPhotoAndVideoDto
     */
    'storageItemId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UploadPhotoAndVideoDto
     */
    'photoIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UploadPhotoAndVideoDto
     */
    'videoIds': Array<number>;
}
/**
 * 
 * @export
 * @interface UploadRealVolumeDto
 */
export interface UploadRealVolumeDto {
    /**
     * 
     * @type {number}
     * @memberof UploadRealVolumeDto
     */
    'storageItemId': number;
    /**
     * 
     * @type {number}
     * @memberof UploadRealVolumeDto
     */
    'realLength'?: number;
    /**
     * 
     * @type {number}
     * @memberof UploadRealVolumeDto
     */
    'realHeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UploadRealVolumeDto
     */
    'realWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof UploadRealVolumeDto
     */
    'realWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof UploadRealVolumeDto
     */
    'staffComment'?: string;
}
/**
 * 
 * @export
 * @interface UploadStorageVolumeDto
 */
export interface UploadStorageVolumeDto {
    /**
     * 
     * @type {number}
     * @memberof UploadStorageVolumeDto
     */
    'storageItemId': number;
    /**
     * 
     * @type {number}
     * @memberof UploadStorageVolumeDto
     */
    'storageLength': number;
    /**
     * 
     * @type {number}
     * @memberof UploadStorageVolumeDto
     */
    'storageHeight': number;
    /**
     * 
     * @type {number}
     * @memberof UploadStorageVolumeDto
     */
    'storageWidth': number;
    /**
     * 
     * @type {number}
     * @memberof UploadStorageVolumeDto
     */
    'storageWeight': number;
    /**
     * 
     * @type {string}
     * @memberof UploadStorageVolumeDto
     */
    'staffComment'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'userCode': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password': string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'freeTrialMonths': number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'freeTrialRemaining': number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'membershipStatus': boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'unsubcriptionAt': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'membershipOutAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'autoPaymentFailFlg': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'blockedFlg': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'activeFlg': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'permissions': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'avatarId': number;
    /**
     * 
     * @type {Asset}
     * @memberof User
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof User
     */
    'payment': Array<Payment>;
    /**
     * 
     * @type {Array<AccountEbay>}
     * @memberof User
     */
    'AccountEbay'?: Array<AccountEbay>;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'parentId': number;
    /**
     * 
     * @type {User}
     * @memberof User
     */
    'parent': User;
    /**
     * 
     * @type {Array<User>}
     * @memberof User
     */
    'children': Array<User>;
    /**
     * 
     * @type {UserPlan}
     * @memberof User
     */
    'userPlan': UserPlan;
    /**
     * 
     * @type {UserSetting}
     * @memberof User
     */
    'userSetting': UserSetting;
    /**
     * 
     * @type {UserCard}
     * @memberof User
     */
    'userCard': UserCard;
    /**
     * 
     * @type {SettingNotification}
     * @memberof User
     */
    'settingNotification': SettingNotification;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface UserCard
 */
export interface UserCard {
    /**
     * 
     * @type {string}
     * @memberof UserCard
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof UserCard
     */
    'cardId': string;
    /**
     * 
     * @type {number}
     * @memberof UserCard
     */
    'userId': number;
    /**
     * 
     * @type {User}
     * @memberof UserCard
     */
    'user': User;
    /**
     * 
     * @type {number}
     * @memberof UserCard
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserCard
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserCard
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserCard
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface UserControllerGetAll200Response
 */
export interface UserControllerGetAll200Response {
    /**
     * 
     * @type {number}
     * @memberof UserControllerGetAll200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof UserControllerGetAll200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof UserControllerGetAll200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<User>}
     * @memberof UserControllerGetAll200Response
     */
    'content'?: Array<User>;
}
/**
 * 
 * @export
 * @interface UserControllerGetAll200ResponseAllOf
 */
export interface UserControllerGetAll200ResponseAllOf {
    /**
     * 
     * @type {Array<User>}
     * @memberof UserControllerGetAll200ResponseAllOf
     */
    'content'?: Array<User>;
}
/**
 * 
 * @export
 * @interface UserPlan
 */
export interface UserPlan {
    /**
     * 
     * @type {number}
     * @memberof UserPlan
     */
    'nextMonthPlanId': number;
    /**
     * 
     * @type {PlanVersion}
     * @memberof UserPlan
     */
    'nextMonthPlan'?: PlanVersion;
    /**
     * 
     * @type {number}
     * @memberof UserPlan
     */
    'currentMonthPlanId': number;
    /**
     * 
     * @type {PlanVersion}
     * @memberof UserPlan
     */
    'currentMonthPlan'?: PlanVersion;
    /**
     * 
     * @type {number}
     * @memberof UserPlan
     */
    'userId': number;
    /**
     * 
     * @type {User}
     * @memberof UserPlan
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof UserPlan
     */
    'planUpdatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof UserPlan
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserPlan
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserPlan
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserPlan
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface UserPlanHistories
 */
export interface UserPlanHistories {
    /**
     * 
     * @type {string}
     * @memberof UserPlanHistories
     */
    'type': UserPlanHistoriesTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof UserPlanHistories
     */
    'planVersionId': number;
    /**
     * 
     * @type {number}
     * @memberof UserPlanHistories
     */
    'userId': number;
    /**
     * 
     * @type {PlanVersion}
     * @memberof UserPlanHistories
     */
    'planVersion'?: PlanVersion;
    /**
     * 
     * @type {User}
     * @memberof UserPlanHistories
     */
    'user'?: User;
    /**
     * 
     * @type {number}
     * @memberof UserPlanHistories
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserPlanHistories
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserPlanHistories
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserPlanHistories
     */
    'deletedAt': string;
}

export const UserPlanHistoriesTypeEnum = {
    Change: 'CHANGE',
    Canceled: 'CANCELED',
    Subscription: 'SUBSCRIPTION',
    Unsubscription: 'UNSUBSCRIPTION',
    Resubscription: 'RESUBSCRIPTION',
    MembershipOut: 'MEMBERSHIP_OUT'
} as const;

export type UserPlanHistoriesTypeEnum = typeof UserPlanHistoriesTypeEnum[keyof typeof UserPlanHistoriesTypeEnum];

/**
 * 
 * @export
 * @interface UserSetting
 */
export interface UserSetting {
    /**
     * 
     * @type {boolean}
     * @memberof UserSetting
     */
    'sendMail': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSetting
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof UserSetting
     */
    'province': string;
    /**
     * 
     * @type {string}
     * @memberof UserSetting
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof UserSetting
     */
    'streetLine': string;
    /**
     * 
     * @type {string}
     * @memberof UserSetting
     */
    'building': string;
    /**
     * 
     * @type {string}
     * @memberof UserSetting
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof UserSetting
     */
    'fullName': string;
    /**
     * 
     * @type {string}
     * @memberof UserSetting
     */
    'phoneNumber': string;
    /**
     * 
     * @type {number}
     * @memberof UserSetting
     */
    'userId': number;
    /**
     * 
     * @type {User}
     * @memberof UserSetting
     */
    'user': User;
    /**
     * 
     * @type {number}
     * @memberof UserSetting
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserSetting
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserSetting
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserSetting
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface Variation
 */
export interface Variation {
    /**
     * 
     * @type {string}
     * @memberof Variation
     */
    'SKU': string;
    /**
     * 
     * @type {VariationSpecifics}
     * @memberof Variation
     */
    'VariationSpecifics': VariationSpecifics;
    /**
     * 
     * @type {string}
     * @memberof Variation
     */
    'VariationTitle': string;
    /**
     * 
     * @type {string}
     * @memberof Variation
     */
    'VariationViewItemURL': string;
}
/**
 * 
 * @export
 * @interface VariationEbay
 */
export interface VariationEbay {
    /**
     * 
     * @type {string}
     * @memberof VariationEbay
     */
    'SKU': string;
    /**
     * 
     * @type {CurrencyID}
     * @memberof VariationEbay
     */
    'StartPrice': CurrencyID;
    /**
     * 
     * @type {string}
     * @memberof VariationEbay
     */
    'Quantity': string;
    /**
     * 
     * @type {VariationSpecifics}
     * @memberof VariationEbay
     */
    'VariationSpecifics': VariationSpecifics;
    /**
     * 
     * @type {SellingStatus2}
     * @memberof VariationEbay
     */
    'SellingStatus': SellingStatus2;
    /**
     * 
     * @type {VariationProductListingDetails}
     * @memberof VariationEbay
     */
    'VariationProductListingDetails': VariationProductListingDetails;
}
/**
 * 
 * @export
 * @interface VariationProductListingDetails
 */
export interface VariationProductListingDetails {
    /**
     * 
     * @type {string}
     * @memberof VariationProductListingDetails
     */
    'UPC': string;
}
/**
 * 
 * @export
 * @interface VariationSpecificPictureSet
 */
export interface VariationSpecificPictureSet {
    /**
     * 
     * @type {string}
     * @memberof VariationSpecificPictureSet
     */
    'VariationSpecificValue': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VariationSpecificPictureSet
     */
    'PictureURL': Array<string>;
}
/**
 * 
 * @export
 * @interface VariationSpecifics
 */
export interface VariationSpecifics {
    /**
     * 
     * @type {NameValueList}
     * @memberof VariationSpecifics
     */
    'NameValueList': NameValueList;
}
/**
 * 
 * @export
 * @interface Variations
 */
export interface Variations {
    /**
     * 
     * @type {Array<VariationEbay>}
     * @memberof Variations
     */
    'Variation': Array<VariationEbay>;
    /**
     * 
     * @type {VariationSpecifics}
     * @memberof Variations
     */
    'VariationSpecificsSet': VariationSpecifics;
    /**
     * 
     * @type {Array<PictureVariationEbay>}
     * @memberof Variations
     */
    'Pictures': Array<PictureVariationEbay>;
}
/**
 * 
 * @export
 * @interface VariationsItemEbayDto
 */
export interface VariationsItemEbayDto {
    /**
     * 
     * @type {string}
     * @memberof VariationsItemEbayDto
     */
    'sku': string;
    /**
     * 
     * @type {number}
     * @memberof VariationsItemEbayDto
     */
    'startPrice': number;
    /**
     * 
     * @type {number}
     * @memberof VariationsItemEbayDto
     */
    'quantity': number;
    /**
     * 
     * @type {Array<NameValueList>}
     * @memberof VariationsItemEbayDto
     */
    'variationSpecifics': Array<NameValueList>;
    /**
     * 
     * @type {VariationProductListingDetails}
     * @memberof VariationsItemEbayDto
     */
    'variationProductListingDetails': VariationProductListingDetails;
}
/**
 * 
 * @export
 * @interface VerifySubUserDto
 */
export interface VerifySubUserDto {
    /**
     * 
     * @type {string}
     * @memberof VerifySubUserDto
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof VerifySubUserDto
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface WarehouseCheckDto
 */
export interface WarehouseCheckDto {
    /**
     * 
     * @type {string}
     * @memberof WarehouseCheckDto
     */
    'rackCode': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WarehouseCheckDto
     */
    'skuList': Array<string>;
}
/**
 * 
 * @export
 * @interface WarehouseCheckResponse
 */
export interface WarehouseCheckResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof WarehouseCheckResponse
     */
    'leftList': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WarehouseCheckResponse
     */
    'centerList': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WarehouseCheckResponse
     */
    'rightList': Array<string>;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEmployeeDto} createEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerCreateEmployee: async (createEmployeeDto: CreateEmployeeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmployeeDto' is not null or undefined
            assertParamExists('administratorControllerCreateEmployee', 'createEmployeeDto', createEmployeeDto)
            const localVarPath = `/admin/employee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerDeleteEmployee: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('administratorControllerDeleteEmployee', 'id', id)
            const localVarPath = `/admin/employee/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerGetAllEmployee: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, includeDeleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/employee/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateEmployeeDto} updateEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerUpdateEmployee: async (updateEmployeeDto: UpdateEmployeeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateEmployeeDto' is not null or undefined
            assertParamExists('administratorControllerUpdateEmployee', 'updateEmployeeDto', updateEmployeeDto)
            const localVarPath = `/admin/employee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmployeeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateMeAdminDto} updateMeAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerUpdateMe: async (updateMeAdminDto: UpdateMeAdminDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateMeAdminDto' is not null or undefined
            assertParamExists('administratorControllerUpdateMe', 'updateMeAdminDto', updateMeAdminDto)
            const localVarPath = `/admin/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMeAdminDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeDto} createEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorControllerCreateEmployee(createEmployeeDto: CreateEmployeeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Admin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorControllerCreateEmployee(createEmployeeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorControllerDeleteEmployee(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorControllerDeleteEmployee(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorControllerGetAllEmployee(page?: number, size?: number, sort?: string, fullTextSearch?: string, includeDeleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdministratorControllerGetAllEmployee200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorControllerGetAllEmployee(page, size, sort, fullTextSearch, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorControllerMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Admin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorControllerMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateEmployeeDto} updateEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorControllerUpdateEmployee(updateEmployeeDto: UpdateEmployeeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorControllerUpdateEmployee(updateEmployeeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateMeAdminDto} updateMeAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorControllerUpdateMe(updateMeAdminDto: UpdateMeAdminDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Admin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorControllerUpdateMe(updateMeAdminDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeDto} createEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerCreateEmployee(createEmployeeDto: CreateEmployeeDto, options?: any): AxiosPromise<Admin> {
            return localVarFp.administratorControllerCreateEmployee(createEmployeeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerDeleteEmployee(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.administratorControllerDeleteEmployee(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerGetAllEmployee(page?: number, size?: number, sort?: string, fullTextSearch?: string, includeDeleted?: boolean, options?: any): AxiosPromise<AdministratorControllerGetAllEmployee200Response> {
            return localVarFp.administratorControllerGetAllEmployee(page, size, sort, fullTextSearch, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerMe(options?: any): AxiosPromise<Admin> {
            return localVarFp.administratorControllerMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateEmployeeDto} updateEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerUpdateEmployee(updateEmployeeDto: UpdateEmployeeDto, options?: any): AxiosPromise<object> {
            return localVarFp.administratorControllerUpdateEmployee(updateEmployeeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateMeAdminDto} updateMeAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerUpdateMe(updateMeAdminDto: UpdateMeAdminDto, options?: any): AxiosPromise<Admin> {
            return localVarFp.administratorControllerUpdateMe(updateMeAdminDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @param {CreateEmployeeDto} createEmployeeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public administratorControllerCreateEmployee(createEmployeeDto: CreateEmployeeDto, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).administratorControllerCreateEmployee(createEmployeeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public administratorControllerDeleteEmployee(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).administratorControllerDeleteEmployee(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public administratorControllerGetAllEmployee(page?: number, size?: number, sort?: string, fullTextSearch?: string, includeDeleted?: boolean, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).administratorControllerGetAllEmployee(page, size, sort, fullTextSearch, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public administratorControllerMe(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).administratorControllerMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateEmployeeDto} updateEmployeeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public administratorControllerUpdateEmployee(updateEmployeeDto: UpdateEmployeeDto, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).administratorControllerUpdateEmployee(updateEmployeeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateMeAdminDto} updateMeAdminDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public administratorControllerUpdateMe(updateMeAdminDto: UpdateMeAdminDto, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).administratorControllerUpdateMe(updateMeAdminDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminSettingApi - axios parameter creator
 * @export
 */
export const AdminSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSettingControllerFindSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-setting/find-setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminSettingDto} adminSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSettingControllerUpSert: async (adminSettingDto: AdminSettingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminSettingDto' is not null or undefined
            assertParamExists('adminSettingControllerUpSert', 'adminSettingDto', adminSettingDto)
            const localVarPath = `/admin-setting/update-setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSettingApi - functional programming interface
 * @export
 */
export const AdminSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSettingControllerFindSetting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSettingResponseType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSettingControllerFindSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminSettingDto} adminSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSettingControllerUpSert(adminSettingDto: AdminSettingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSettingControllerUpSert(adminSettingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminSettingApi - factory interface
 * @export
 */
export const AdminSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSettingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSettingControllerFindSetting(options?: any): AxiosPromise<AdminSettingResponseType> {
            return localVarFp.adminSettingControllerFindSetting(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminSettingDto} adminSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSettingControllerUpSert(adminSettingDto: AdminSettingDto, options?: any): AxiosPromise<void> {
            return localVarFp.adminSettingControllerUpSert(adminSettingDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminSettingApi - object-oriented interface
 * @export
 * @class AdminSettingApi
 * @extends {BaseAPI}
 */
export class AdminSettingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSettingApi
     */
    public adminSettingControllerFindSetting(options?: AxiosRequestConfig) {
        return AdminSettingApiFp(this.configuration).adminSettingControllerFindSetting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminSettingDto} adminSettingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSettingApi
     */
    public adminSettingControllerUpSert(adminSettingDto: AdminSettingDto, options?: AxiosRequestConfig) {
        return AdminSettingApiFp(this.configuration).adminSettingControllerUpSert(adminSettingDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFolderDto} createFolderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerCreateFolder: async (createFolderDto: CreateFolderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFolderDto' is not null or undefined
            assertParamExists('assetControllerCreateFolder', 'createFolderDto', createFolderDto)
            const localVarPath = `/assets/create-folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFolderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {DeleteAssetDto} deleteAssetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerDelete: async (id: string, deleteAssetDto: DeleteAssetDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assetControllerDelete', 'id', id)
            // verify required parameter 'deleteAssetDto' is not null or undefined
            assertParamExists('assetControllerDelete', 'deleteAssetDto', deleteAssetDto)
            const localVarPath = `/assets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteAssetDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerDiscover: async (folderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('assetControllerDiscover', 'folderId', folderId)
            const localVarPath = `/assets/{folderId}/discover`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerGetByPath: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assets/get-by-path/{path}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {string} folder 
         * @param {number} [assetFolderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerUploadFile: async (file: File, folder: string, assetFolderId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('assetControllerUploadFile', 'file', file)
            // verify required parameter 'folder' is not null or undefined
            assertParamExists('assetControllerUploadFile', 'folder', folder)
            const localVarPath = `/assets/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (folder !== undefined) { 
                localVarFormParams.append('folder', folder as any);
            }
    
            if (assetFolderId !== undefined) { 
                localVarFormParams.append('assetFolderId', assetFolderId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateFolderDto} createFolderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetControllerCreateFolder(createFolderDto: CreateFolderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetControllerCreateFolder(createFolderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {DeleteAssetDto} deleteAssetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetControllerDelete(id: string, deleteAssetDto: DeleteAssetDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetControllerDelete(id, deleteAssetDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetControllerDiscover(folderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Asset>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetControllerDiscover(folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetControllerGetByPath(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetControllerGetByPath(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {string} folder 
         * @param {number} [assetFolderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetControllerUploadFile(file: File, folder: string, assetFolderId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetControllerUploadFile(file, folder, assetFolderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateFolderDto} createFolderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerCreateFolder(createFolderDto: CreateFolderDto, options?: any): AxiosPromise<object> {
            return localVarFp.assetControllerCreateFolder(createFolderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {DeleteAssetDto} deleteAssetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerDelete(id: string, deleteAssetDto: DeleteAssetDto, options?: any): AxiosPromise<void> {
            return localVarFp.assetControllerDelete(id, deleteAssetDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerDiscover(folderId: string, options?: any): AxiosPromise<Array<Asset>> {
            return localVarFp.assetControllerDiscover(folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerGetByPath(options?: any): AxiosPromise<void> {
            return localVarFp.assetControllerGetByPath(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {string} folder 
         * @param {number} [assetFolderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerUploadFile(file: File, folder: string, assetFolderId?: number, options?: any): AxiosPromise<object> {
            return localVarFp.assetControllerUploadFile(file, folder, assetFolderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI {
    /**
     * 
     * @param {CreateFolderDto} createFolderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetControllerCreateFolder(createFolderDto: CreateFolderDto, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetControllerCreateFolder(createFolderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {DeleteAssetDto} deleteAssetDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetControllerDelete(id: string, deleteAssetDto: DeleteAssetDto, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetControllerDelete(id, deleteAssetDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetControllerDiscover(folderId: string, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetControllerDiscover(folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetControllerGetByPath(options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetControllerGetByPath(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {string} folder 
     * @param {number} [assetFolderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetControllerUploadFile(file: File, folder: string, assetFolderId?: number, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetControllerUploadFile(file, folder, assetFolderId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminLogin: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerAdminLogin', 'loginDto', loginDto)
            const localVarPath = `/auth/admin/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerChangePassword: async (changePasswordDto: ChangePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordDto' is not null or undefined
            assertParamExists('authControllerChangePassword', 'changePasswordDto', changePasswordDto)
            const localVarPath = `/auth/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmailDto} emailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerEmailExists: async (emailDto: EmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailDto' is not null or undefined
            assertParamExists('authControllerEmailExists', 'emailDto', emailDto)
            const localVarPath = `/auth/check-email-exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginIdDto} loginIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLoginIdExists: async (loginIdDto: LoginIdDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginIdDto' is not null or undefined
            assertParamExists('authControllerLoginIdExists', 'loginIdDto', loginIdDto)
            const localVarPath = `/auth/check-login-id-exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginIdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterUserDto} registerUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegisterUserAccount: async (registerUserDto: RegisterUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerUserDto' is not null or undefined
            assertParamExists('authControllerRegisterUserAccount', 'registerUserDto', registerUserDto)
            const localVarPath = `/auth/user/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestPasswordResetDto} requestPasswordResetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRequestPasswordReset: async (requestPasswordResetDto: RequestPasswordResetDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestPasswordResetDto' is not null or undefined
            assertParamExists('authControllerRequestPasswordReset', 'requestPasswordResetDto', requestPasswordResetDto)
            const localVarPath = `/auth/request-password-reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestPasswordResetDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerUserLogin: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerUserLogin', 'loginDto', loginDto)
            const localVarPath = `/auth/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OtpDto} otpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyPasswordReset: async (otpDto: OtpDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'otpDto' is not null or undefined
            assertParamExists('authControllerVerifyPasswordReset', 'otpDto', otpDto)
            const localVarPath = `/auth/verify-password-reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(otpDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OtpDto} otpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyRegisterOtp: async (otpDto: OtpDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'otpDto' is not null or undefined
            assertParamExists('authControllerVerifyRegisterOtp', 'otpDto', otpDto)
            const localVarPath = `/auth/user/verify-register-otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(otpDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAdminLogin(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerAdminLogin(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerChangePassword(changePasswordDto: ChangePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerChangePassword(changePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmailDto} emailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerEmailExists(emailDto: EmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerEmailExists(emailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginIdDto} loginIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLoginIdExists(loginIdDto: LoginIdDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLoginIdExists(loginIdDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterUserDto} registerUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRegisterUserAccount(registerUserDto: RegisterUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRegisterUserAccount(registerUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestPasswordResetDto} requestPasswordResetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRequestPasswordReset(requestPasswordResetDto: RequestPasswordResetDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRequestPasswordReset(requestPasswordResetDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerUserLogin(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerUserLogin(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OtpDto} otpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerVerifyPasswordReset(otpDto: OtpDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerVerifyPasswordReset(otpDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OtpDto} otpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerVerifyRegisterOtp(otpDto: OtpDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerVerifyRegisterOtp(otpDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminLogin(loginDto: LoginDto, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.authControllerAdminLogin(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerChangePassword(changePasswordDto: ChangePasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerChangePassword(changePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailDto} emailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerEmailExists(emailDto: EmailDto, options?: any): AxiosPromise<object> {
            return localVarFp.authControllerEmailExists(emailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginIdDto} loginIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLoginIdExists(loginIdDto: LoginIdDto, options?: any): AxiosPromise<object> {
            return localVarFp.authControllerLoginIdExists(loginIdDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterUserDto} registerUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegisterUserAccount(registerUserDto: RegisterUserDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerRegisterUserAccount(registerUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestPasswordResetDto} requestPasswordResetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRequestPasswordReset(requestPasswordResetDto: RequestPasswordResetDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerRequestPasswordReset(requestPasswordResetDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerUserLogin(loginDto: LoginDto, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.authControllerUserLogin(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OtpDto} otpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyPasswordReset(otpDto: OtpDto, options?: any): AxiosPromise<object> {
            return localVarFp.authControllerVerifyPasswordReset(otpDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OtpDto} otpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyRegisterOtp(otpDto: OtpDto, options?: any): AxiosPromise<User> {
            return localVarFp.authControllerVerifyRegisterOtp(otpDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Login
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerAdminLogin(loginDto: LoginDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerAdminLogin(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChangePasswordDto} changePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerChangePassword(changePasswordDto: ChangePasswordDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerChangePassword(changePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailDto} emailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerEmailExists(emailDto: EmailDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerEmailExists(emailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginIdDto} loginIdDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLoginIdExists(loginIdDto: LoginIdDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLoginIdExists(loginIdDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterUserDto} registerUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRegisterUserAccount(registerUserDto: RegisterUserDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerRegisterUserAccount(registerUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestPasswordResetDto} requestPasswordResetDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRequestPasswordReset(requestPasswordResetDto: RequestPasswordResetDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerRequestPasswordReset(requestPasswordResetDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerUserLogin(loginDto: LoginDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerUserLogin(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OtpDto} otpDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerVerifyPasswordReset(otpDto: OtpDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerVerifyPasswordReset(otpDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OtpDto} otpDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerVerifyRegisterOtp(otpDto: OtpDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerVerifyRegisterOtp(otpDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BizNotificationsApi - axios parameter creator
 * @export
 */
export const BizNotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bizNotificationControllerGetAll: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/biz-notifications/get-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MaskAsRead} maskAsRead 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bizNotificationControllerMaskAsRead: async (maskAsRead: MaskAsRead, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'maskAsRead' is not null or undefined
            assertParamExists('bizNotificationControllerMaskAsRead', 'maskAsRead', maskAsRead)
            const localVarPath = `/biz-notifications/mask-as-read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(maskAsRead, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bizNotificationControllerTestBiz1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/biz-notifications/test-biz1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bizNotificationControllerTestBiz2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/biz-notifications/test-biz2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BizNotificationsApi - functional programming interface
 * @export
 */
export const BizNotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BizNotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bizNotificationControllerGetAll(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BizNotificationControllerGetAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bizNotificationControllerGetAll(page, size, sort, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MaskAsRead} maskAsRead 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bizNotificationControllerMaskAsRead(maskAsRead: MaskAsRead, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BizNotificationControllerGetAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bizNotificationControllerMaskAsRead(maskAsRead, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bizNotificationControllerTestBiz1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bizNotificationControllerTestBiz1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bizNotificationControllerTestBiz2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bizNotificationControllerTestBiz2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BizNotificationsApi - factory interface
 * @export
 */
export const BizNotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BizNotificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bizNotificationControllerGetAll(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: any): AxiosPromise<BizNotificationControllerGetAll200Response> {
            return localVarFp.bizNotificationControllerGetAll(page, size, sort, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MaskAsRead} maskAsRead 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bizNotificationControllerMaskAsRead(maskAsRead: MaskAsRead, options?: any): AxiosPromise<BizNotificationControllerGetAll200Response> {
            return localVarFp.bizNotificationControllerMaskAsRead(maskAsRead, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bizNotificationControllerTestBiz1(options?: any): AxiosPromise<void> {
            return localVarFp.bizNotificationControllerTestBiz1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bizNotificationControllerTestBiz2(options?: any): AxiosPromise<void> {
            return localVarFp.bizNotificationControllerTestBiz2(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BizNotificationsApi - object-oriented interface
 * @export
 * @class BizNotificationsApi
 * @extends {BaseAPI}
 */
export class BizNotificationsApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BizNotificationsApi
     */
    public bizNotificationControllerGetAll(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return BizNotificationsApiFp(this.configuration).bizNotificationControllerGetAll(page, size, sort, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MaskAsRead} maskAsRead 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BizNotificationsApi
     */
    public bizNotificationControllerMaskAsRead(maskAsRead: MaskAsRead, options?: AxiosRequestConfig) {
        return BizNotificationsApiFp(this.configuration).bizNotificationControllerMaskAsRead(maskAsRead, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BizNotificationsApi
     */
    public bizNotificationControllerTestBiz1(options?: AxiosRequestConfig) {
        return BizNotificationsApiFp(this.configuration).bizNotificationControllerTestBiz1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BizNotificationsApi
     */
    public bizNotificationControllerTestBiz2(options?: AxiosRequestConfig) {
        return BizNotificationsApiFp(this.configuration).bizNotificationControllerTestBiz2(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChatSupportGroupApi - axios parameter creator
 * @export
 */
export const ChatSupportGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSupportGroupControllerCountStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat-support-group/count-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [supportStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSupportGroupControllerGetAll: async (page?: number, size?: number, supportStatus?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat-support-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (supportStatus !== undefined) {
                localVarQueryParameter['supportStatus'] = supportStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateGroupDto} updateGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSupportGroupControllerUpdateStatus: async (updateGroupDto: UpdateGroupDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateGroupDto' is not null or undefined
            assertParamExists('chatSupportGroupControllerUpdateStatus', 'updateGroupDto', updateGroupDto)
            const localVarPath = `/chat-support-group/update-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGroupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatSupportGroupApi - functional programming interface
 * @export
 */
export const ChatSupportGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatSupportGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatSupportGroupControllerCountStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatSupportGroupControllerCountStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [supportStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatSupportGroupControllerGetAll(page?: number, size?: number, supportStatus?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatSupportGroupControllerGetAll(page, size, supportStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateGroupDto} updateGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatSupportGroupControllerUpdateStatus(updateGroupDto: UpdateGroupDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatSupportGroupControllerUpdateStatus(updateGroupDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChatSupportGroupApi - factory interface
 * @export
 */
export const ChatSupportGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatSupportGroupApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSupportGroupControllerCountStatus(options?: any): AxiosPromise<object> {
            return localVarFp.chatSupportGroupControllerCountStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [supportStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSupportGroupControllerGetAll(page?: number, size?: number, supportStatus?: number, options?: any): AxiosPromise<void> {
            return localVarFp.chatSupportGroupControllerGetAll(page, size, supportStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateGroupDto} updateGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSupportGroupControllerUpdateStatus(updateGroupDto: UpdateGroupDto, options?: any): AxiosPromise<void> {
            return localVarFp.chatSupportGroupControllerUpdateStatus(updateGroupDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatSupportGroupApi - object-oriented interface
 * @export
 * @class ChatSupportGroupApi
 * @extends {BaseAPI}
 */
export class ChatSupportGroupApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatSupportGroupApi
     */
    public chatSupportGroupControllerCountStatus(options?: AxiosRequestConfig) {
        return ChatSupportGroupApiFp(this.configuration).chatSupportGroupControllerCountStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {number} [supportStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatSupportGroupApi
     */
    public chatSupportGroupControllerGetAll(page?: number, size?: number, supportStatus?: number, options?: AxiosRequestConfig) {
        return ChatSupportGroupApiFp(this.configuration).chatSupportGroupControllerGetAll(page, size, supportStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateGroupDto} updateGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatSupportGroupApi
     */
    public chatSupportGroupControllerUpdateStatus(updateGroupDto: UpdateGroupDto, options?: AxiosRequestConfig) {
        return ChatSupportGroupApiFp(this.configuration).chatSupportGroupControllerUpdateStatus(updateGroupDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChatSupportMessageApi - axios parameter creator
 * @export
 */
export const ChatSupportMessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSupportMessageControllerCountUnreac: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat-support-message/count-unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupChatId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSupportMessageControllerGetAll: async (groupChatId: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupChatId' is not null or undefined
            assertParamExists('chatSupportMessageControllerGetAll', 'groupChatId', groupChatId)
            const localVarPath = `/chat-support-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (groupChatId !== undefined) {
                localVarQueryParameter['groupChatId'] = groupChatId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReadMessageDto} readMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSupportMessageControllerRead: async (readMessageDto: ReadMessageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readMessageDto' is not null or undefined
            assertParamExists('chatSupportMessageControllerRead', 'readMessageDto', readMessageDto)
            const localVarPath = `/chat-support-message/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(readMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendAllMessageDto} sendAllMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSupportMessageControllerSendAll: async (sendAllMessageDto: SendAllMessageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendAllMessageDto' is not null or undefined
            assertParamExists('chatSupportMessageControllerSendAll', 'sendAllMessageDto', sendAllMessageDto)
            const localVarPath = `/chat-support-message/send-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendAllMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatSupportMessageApi - functional programming interface
 * @export
 */
export const ChatSupportMessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatSupportMessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatSupportMessageControllerCountUnreac(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatSupportMessageControllerCountUnreac(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} groupChatId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatSupportMessageControllerGetAll(groupChatId: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatSupportMessageControllerGetAll(groupChatId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReadMessageDto} readMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatSupportMessageControllerRead(readMessageDto: ReadMessageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatSupportMessageControllerRead(readMessageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendAllMessageDto} sendAllMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatSupportMessageControllerSendAll(sendAllMessageDto: SendAllMessageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatSupportMessageControllerSendAll(sendAllMessageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChatSupportMessageApi - factory interface
 * @export
 */
export const ChatSupportMessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatSupportMessageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSupportMessageControllerCountUnreac(options?: any): AxiosPromise<void> {
            return localVarFp.chatSupportMessageControllerCountUnreac(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} groupChatId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSupportMessageControllerGetAll(groupChatId: number, page?: number, size?: number, options?: any): AxiosPromise<void> {
            return localVarFp.chatSupportMessageControllerGetAll(groupChatId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReadMessageDto} readMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSupportMessageControllerRead(readMessageDto: ReadMessageDto, options?: any): AxiosPromise<void> {
            return localVarFp.chatSupportMessageControllerRead(readMessageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendAllMessageDto} sendAllMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSupportMessageControllerSendAll(sendAllMessageDto: SendAllMessageDto, options?: any): AxiosPromise<void> {
            return localVarFp.chatSupportMessageControllerSendAll(sendAllMessageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatSupportMessageApi - object-oriented interface
 * @export
 * @class ChatSupportMessageApi
 * @extends {BaseAPI}
 */
export class ChatSupportMessageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatSupportMessageApi
     */
    public chatSupportMessageControllerCountUnreac(options?: AxiosRequestConfig) {
        return ChatSupportMessageApiFp(this.configuration).chatSupportMessageControllerCountUnreac(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} groupChatId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatSupportMessageApi
     */
    public chatSupportMessageControllerGetAll(groupChatId: number, page?: number, size?: number, options?: AxiosRequestConfig) {
        return ChatSupportMessageApiFp(this.configuration).chatSupportMessageControllerGetAll(groupChatId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReadMessageDto} readMessageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatSupportMessageApi
     */
    public chatSupportMessageControllerRead(readMessageDto: ReadMessageDto, options?: AxiosRequestConfig) {
        return ChatSupportMessageApiFp(this.configuration).chatSupportMessageControllerRead(readMessageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendAllMessageDto} sendAllMessageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatSupportMessageApi
     */
    public chatSupportMessageControllerSendAll(sendAllMessageDto: SendAllMessageDto, options?: AxiosRequestConfig) {
        return ChatSupportMessageApiFp(this.configuration).chatSupportMessageControllerSendAll(sendAllMessageDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CouponApi - axios parameter creator
 * @export
 */
export const CouponApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateMultipleCouponsDto} createMultipleCouponsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerCheckValidCodes: async (createMultipleCouponsDto: CreateMultipleCouponsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMultipleCouponsDto' is not null or undefined
            assertParamExists('couponControllerCheckValidCodes', 'createMultipleCouponsDto', createMultipleCouponsDto)
            const localVarPath = `/coupon/check-valid-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMultipleCouponsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateMultipleCouponsDto} createMultipleCouponsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerCreateMultipleCoupons: async (createMultipleCouponsDto: CreateMultipleCouponsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMultipleCouponsDto' is not null or undefined
            assertParamExists('couponControllerCreateMultipleCoupons', 'createMultipleCouponsDto', createMultipleCouponsDto)
            const localVarPath = `/coupon/many`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMultipleCouponsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSingleCouponDto} createSingleCouponDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerCreateSingleCoupon: async (createSingleCouponDto: CreateSingleCouponDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSingleCouponDto' is not null or undefined
            assertParamExists('couponControllerCreateSingleCoupon', 'createSingleCouponDto', createSingleCouponDto)
            const localVarPath = `/coupon/one`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSingleCouponDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerDeleteCoupon: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('couponControllerDeleteCoupon', 'id', id)
            const localVarPath = `/coupon/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditSingleCouponDto} editSingleCouponDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerEditCoupon: async (editSingleCouponDto: EditSingleCouponDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editSingleCouponDto' is not null or undefined
            assertParamExists('couponControllerEditCoupon', 'editSingleCouponDto', editSingleCouponDto)
            const localVarPath = `/coupon/one`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editSingleCouponDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerGetCoupon: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('couponControllerGetCoupon', 'id', id)
            const localVarPath = `/coupon/one/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerGetMail: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/coupon/mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerMyListCoupons: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/coupon/user/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerReadListCoupons: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/coupon/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CouponApi - functional programming interface
 * @export
 */
export const CouponApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CouponApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateMultipleCouponsDto} createMultipleCouponsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponControllerCheckValidCodes(createMultipleCouponsDto: CreateMultipleCouponsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckValidCouponResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponControllerCheckValidCodes(createMultipleCouponsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateMultipleCouponsDto} createMultipleCouponsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponControllerCreateMultipleCoupons(createMultipleCouponsDto: CreateMultipleCouponsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Coupon>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponControllerCreateMultipleCoupons(createMultipleCouponsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateSingleCouponDto} createSingleCouponDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponControllerCreateSingleCoupon(createSingleCouponDto: CreateSingleCouponDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Coupon>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponControllerCreateSingleCoupon(createSingleCouponDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponControllerDeleteCoupon(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponControllerDeleteCoupon(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditSingleCouponDto} editSingleCouponDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponControllerEditCoupon(editSingleCouponDto: EditSingleCouponDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Coupon>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponControllerEditCoupon(editSingleCouponDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponControllerGetCoupon(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Coupon>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponControllerGetCoupon(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponControllerGetMail(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponControllerGetMail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponControllerMyListCoupons(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Coupon>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponControllerMyListCoupons(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponControllerReadListCoupons(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponControllerReadListCoupons200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponControllerReadListCoupons(page, size, sort, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CouponApi - factory interface
 * @export
 */
export const CouponApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CouponApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateMultipleCouponsDto} createMultipleCouponsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerCheckValidCodes(createMultipleCouponsDto: CreateMultipleCouponsDto, options?: any): AxiosPromise<CheckValidCouponResponseDTO> {
            return localVarFp.couponControllerCheckValidCodes(createMultipleCouponsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateMultipleCouponsDto} createMultipleCouponsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerCreateMultipleCoupons(createMultipleCouponsDto: CreateMultipleCouponsDto, options?: any): AxiosPromise<Array<Coupon>> {
            return localVarFp.couponControllerCreateMultipleCoupons(createMultipleCouponsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSingleCouponDto} createSingleCouponDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerCreateSingleCoupon(createSingleCouponDto: CreateSingleCouponDto, options?: any): AxiosPromise<Coupon> {
            return localVarFp.couponControllerCreateSingleCoupon(createSingleCouponDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerDeleteCoupon(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.couponControllerDeleteCoupon(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditSingleCouponDto} editSingleCouponDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerEditCoupon(editSingleCouponDto: EditSingleCouponDto, options?: any): AxiosPromise<Coupon> {
            return localVarFp.couponControllerEditCoupon(editSingleCouponDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerGetCoupon(id: number, options?: any): AxiosPromise<Coupon> {
            return localVarFp.couponControllerGetCoupon(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerGetMail(options?: any): AxiosPromise<MailResponseDTO> {
            return localVarFp.couponControllerGetMail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerMyListCoupons(options?: any): AxiosPromise<Array<Coupon>> {
            return localVarFp.couponControllerMyListCoupons(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponControllerReadListCoupons(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: any): AxiosPromise<CouponControllerReadListCoupons200Response> {
            return localVarFp.couponControllerReadListCoupons(page, size, sort, fullTextSearch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CouponApi - object-oriented interface
 * @export
 * @class CouponApi
 * @extends {BaseAPI}
 */
export class CouponApi extends BaseAPI {
    /**
     * 
     * @param {CreateMultipleCouponsDto} createMultipleCouponsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponControllerCheckValidCodes(createMultipleCouponsDto: CreateMultipleCouponsDto, options?: AxiosRequestConfig) {
        return CouponApiFp(this.configuration).couponControllerCheckValidCodes(createMultipleCouponsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateMultipleCouponsDto} createMultipleCouponsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponControllerCreateMultipleCoupons(createMultipleCouponsDto: CreateMultipleCouponsDto, options?: AxiosRequestConfig) {
        return CouponApiFp(this.configuration).couponControllerCreateMultipleCoupons(createMultipleCouponsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSingleCouponDto} createSingleCouponDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponControllerCreateSingleCoupon(createSingleCouponDto: CreateSingleCouponDto, options?: AxiosRequestConfig) {
        return CouponApiFp(this.configuration).couponControllerCreateSingleCoupon(createSingleCouponDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponControllerDeleteCoupon(id: number, options?: AxiosRequestConfig) {
        return CouponApiFp(this.configuration).couponControllerDeleteCoupon(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditSingleCouponDto} editSingleCouponDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponControllerEditCoupon(editSingleCouponDto: EditSingleCouponDto, options?: AxiosRequestConfig) {
        return CouponApiFp(this.configuration).couponControllerEditCoupon(editSingleCouponDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponControllerGetCoupon(id: number, options?: AxiosRequestConfig) {
        return CouponApiFp(this.configuration).couponControllerGetCoupon(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponControllerGetMail(options?: AxiosRequestConfig) {
        return CouponApiFp(this.configuration).couponControllerGetMail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponControllerMyListCoupons(options?: AxiosRequestConfig) {
        return CouponApiFp(this.configuration).couponControllerMyListCoupons(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponControllerReadListCoupons(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return CouponApiFp(this.configuration).couponControllerReadListCoupons(page, size, sort, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EbayManagerApi - axios parameter creator
 * @export
 */
export const EbayManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangeNameDto} changeNameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerChangeName: async (changeNameDto: ChangeNameDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeNameDto' is not null or undefined
            assertParamExists('ebayManagerControllerChangeName', 'changeNameDto', changeNameDto)
            const localVarPath = `/ebay-manager/change-name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeNameDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConnectAccountEbayDto} connectAccountEbayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerConnectAccountEbay: async (connectAccountEbayDto: ConnectAccountEbayDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectAccountEbayDto' is not null or undefined
            assertParamExists('ebayManagerControllerConnectAccountEbay', 'connectAccountEbayDto', connectAccountEbayDto)
            const localVarPath = `/ebay-manager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectAccountEbayDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateShippingFulfillmentDetailsDto} createShippingFulfillmentDetailsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerCreateShippingFulfillmentFulfillment: async (createShippingFulfillmentDetailsDto: CreateShippingFulfillmentDetailsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createShippingFulfillmentDetailsDto' is not null or undefined
            assertParamExists('ebayManagerControllerCreateShippingFulfillmentFulfillment', 'createShippingFulfillmentDetailsDto', createShippingFulfillmentDetailsDto)
            const localVarPath = `/ebay-manager/create-shipping-fulfillment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createShippingFulfillmentDetailsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerDeleteAccountEbay: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ebayManagerControllerDeleteAccountEbay', 'id', id)
            const localVarPath = `/ebay-manager/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {number} [userId] 
         * @param {boolean} [isActivated] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerGetAllAccountEbay: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, userId?: number, isActivated?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ebay-manager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (isActivated !== undefined) {
                localVarQueryParameter['isActivated'] = isActivated;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerGetAllAccountEbayExpiredRefreshToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ebay-manager/get-expired-account-ebay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerGetAllOrderReturnFromEbay: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ebay-manager/get-all-order-from-ebay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetDetailItemEbayXMLDto} getDetailItemEbayXMLDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerGetItemEbayXML: async (getDetailItemEbayXMLDto: GetDetailItemEbayXMLDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getDetailItemEbayXMLDto' is not null or undefined
            assertParamExists('ebayManagerControllerGetItemEbayXML', 'getDetailItemEbayXMLDto', getDetailItemEbayXMLDto)
            const localVarPath = `/ebay-manager/get-detail-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getDetailItemEbayXMLDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} userEbayId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerGetOneOrderReturnFromEbay: async (orderId: string, userEbayId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ebayManagerControllerGetOneOrderReturnFromEbay', 'orderId', orderId)
            // verify required parameter 'userEbayId' is not null or undefined
            assertParamExists('ebayManagerControllerGetOneOrderReturnFromEbay', 'userEbayId', userEbayId)
            const localVarPath = `/ebay-manager/get-one-order-from-ebay/{orderId}/{userEbayId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"userEbayId"}}`, encodeURIComponent(String(userEbayId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userEbayId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerGetShippingPolicyByAccountEbayId: async (userEbayId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userEbayId' is not null or undefined
            assertParamExists('ebayManagerControllerGetShippingPolicyByAccountEbayId', 'userEbayId', userEbayId)
            const localVarPath = `/ebay-manager/get-shipping-policy/{userEbayId}`
                .replace(`{${"userEbayId"}}`, encodeURIComponent(String(userEbayId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerGetUrl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ebay-manager/url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NotifyEbayReturnCommon} notifyEbayReturnCommon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerHandleNotification: async (notifyEbayReturnCommon: NotifyEbayReturnCommon, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notifyEbayReturnCommon' is not null or undefined
            assertParamExists('ebayManagerControllerHandleNotification', 'notifyEbayReturnCommon', notifyEbayReturnCommon)
            const localVarPath = `/ebay-manager/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notifyEbayReturnCommon, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReviseFixedPriceItemEbayDto} reviseFixedPriceItemEbayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerReviseFixedPriceItemEbay: async (reviseFixedPriceItemEbayDto: ReviseFixedPriceItemEbayDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviseFixedPriceItemEbayDto' is not null or undefined
            assertParamExists('ebayManagerControllerReviseFixedPriceItemEbay', 'reviseFixedPriceItemEbayDto', reviseFixedPriceItemEbayDto)
            const localVarPath = `/ebay-manager/revise-fixed-price-item-ebay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviseFixedPriceItemEbayDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReviseItemEbayDto} reviseItemEbayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerReviseItemEbay: async (reviseItemEbayDto: ReviseItemEbayDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviseItemEbayDto' is not null or undefined
            assertParamExists('ebayManagerControllerReviseItemEbay', 'reviseItemEbayDto', reviseItemEbayDto)
            const localVarPath = `/ebay-manager/revise-item-ebay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviseItemEbayDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GrantedLimitDto} grantedLimitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerStressLimit: async (grantedLimitDto: GrantedLimitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grantedLimitDto' is not null or undefined
            assertParamExists('ebayManagerControllerStressLimit', 'grantedLimitDto', grantedLimitDto)
            const localVarPath = `/ebay-manager/stress-limit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grantedLimitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EbayManagerApi - functional programming interface
 * @export
 */
export const EbayManagerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EbayManagerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChangeNameDto} changeNameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayManagerControllerChangeName(changeNameDto: ChangeNameDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayManagerControllerChangeName(changeNameDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConnectAccountEbayDto} connectAccountEbayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayManagerControllerConnectAccountEbay(connectAccountEbayDto: ConnectAccountEbayDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OmitTypeClass>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayManagerControllerConnectAccountEbay(connectAccountEbayDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateShippingFulfillmentDetailsDto} createShippingFulfillmentDetailsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayManagerControllerCreateShippingFulfillmentFulfillment(createShippingFulfillmentDetailsDto: CreateShippingFulfillmentDetailsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayManagerControllerCreateShippingFulfillmentFulfillment(createShippingFulfillmentDetailsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayManagerControllerDeleteAccountEbay(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayManagerControllerDeleteAccountEbay(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {number} [userId] 
         * @param {boolean} [isActivated] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayManagerControllerGetAllAccountEbay(page?: number, size?: number, sort?: string, fullTextSearch?: string, userId?: number, isActivated?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EbayManagerControllerGetAllAccountEbay200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayManagerControllerGetAllAccountEbay(page, size, sort, fullTextSearch, userId, isActivated, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayManagerControllerGetAllAccountEbayExpiredRefreshToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OmitTypeClass>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayManagerControllerGetAllAccountEbayExpiredRefreshToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayManagerControllerGetAllOrderReturnFromEbay(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomOrderFromEbay>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayManagerControllerGetAllOrderReturnFromEbay(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetDetailItemEbayXMLDto} getDetailItemEbayXMLDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayManagerControllerGetItemEbayXML(getDetailItemEbayXMLDto: GetDetailItemEbayXMLDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayManagerControllerGetItemEbayXML(getDetailItemEbayXMLDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} userEbayId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayManagerControllerGetOneOrderReturnFromEbay(orderId: string, userEbayId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderXML>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayManagerControllerGetOneOrderReturnFromEbay(orderId, userEbayId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userEbayId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayManagerControllerGetShippingPolicyByAccountEbayId(userEbayId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetShippingPolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayManagerControllerGetShippingPolicyByAccountEbayId(userEbayId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayManagerControllerGetUrl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayManagerControllerGetUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NotifyEbayReturnCommon} notifyEbayReturnCommon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayManagerControllerHandleNotification(notifyEbayReturnCommon: NotifyEbayReturnCommon, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayManagerControllerHandleNotification(notifyEbayReturnCommon, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReviseFixedPriceItemEbayDto} reviseFixedPriceItemEbayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayManagerControllerReviseFixedPriceItemEbay(reviseFixedPriceItemEbayDto: ReviseFixedPriceItemEbayDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayManagerControllerReviseFixedPriceItemEbay(reviseFixedPriceItemEbayDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReviseItemEbayDto} reviseItemEbayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayManagerControllerReviseItemEbay(reviseItemEbayDto: ReviseItemEbayDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayManagerControllerReviseItemEbay(reviseItemEbayDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GrantedLimitDto} grantedLimitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayManagerControllerStressLimit(grantedLimitDto: GrantedLimitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayManagerControllerStressLimit(grantedLimitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EbayManagerApi - factory interface
 * @export
 */
export const EbayManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EbayManagerApiFp(configuration)
    return {
        /**
         * 
         * @param {ChangeNameDto} changeNameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerChangeName(changeNameDto: ChangeNameDto, options?: any): AxiosPromise<void> {
            return localVarFp.ebayManagerControllerChangeName(changeNameDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConnectAccountEbayDto} connectAccountEbayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerConnectAccountEbay(connectAccountEbayDto: ConnectAccountEbayDto, options?: any): AxiosPromise<OmitTypeClass> {
            return localVarFp.ebayManagerControllerConnectAccountEbay(connectAccountEbayDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateShippingFulfillmentDetailsDto} createShippingFulfillmentDetailsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerCreateShippingFulfillmentFulfillment(createShippingFulfillmentDetailsDto: CreateShippingFulfillmentDetailsDto, options?: any): AxiosPromise<object> {
            return localVarFp.ebayManagerControllerCreateShippingFulfillmentFulfillment(createShippingFulfillmentDetailsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerDeleteAccountEbay(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.ebayManagerControllerDeleteAccountEbay(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {number} [userId] 
         * @param {boolean} [isActivated] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerGetAllAccountEbay(page?: number, size?: number, sort?: string, fullTextSearch?: string, userId?: number, isActivated?: boolean, options?: any): AxiosPromise<EbayManagerControllerGetAllAccountEbay200Response> {
            return localVarFp.ebayManagerControllerGetAllAccountEbay(page, size, sort, fullTextSearch, userId, isActivated, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerGetAllAccountEbayExpiredRefreshToken(options?: any): AxiosPromise<Array<OmitTypeClass>> {
            return localVarFp.ebayManagerControllerGetAllAccountEbayExpiredRefreshToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerGetAllOrderReturnFromEbay(options?: any): AxiosPromise<Array<CustomOrderFromEbay>> {
            return localVarFp.ebayManagerControllerGetAllOrderReturnFromEbay(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetDetailItemEbayXMLDto} getDetailItemEbayXMLDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerGetItemEbayXML(getDetailItemEbayXMLDto: GetDetailItemEbayXMLDto, options?: any): AxiosPromise<Item> {
            return localVarFp.ebayManagerControllerGetItemEbayXML(getDetailItemEbayXMLDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} userEbayId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerGetOneOrderReturnFromEbay(orderId: string, userEbayId: string, options?: any): AxiosPromise<OrderXML> {
            return localVarFp.ebayManagerControllerGetOneOrderReturnFromEbay(orderId, userEbayId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userEbayId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerGetShippingPolicyByAccountEbayId(userEbayId: number, options?: any): AxiosPromise<GetShippingPolicy> {
            return localVarFp.ebayManagerControllerGetShippingPolicyByAccountEbayId(userEbayId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerGetUrl(options?: any): AxiosPromise<string> {
            return localVarFp.ebayManagerControllerGetUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotifyEbayReturnCommon} notifyEbayReturnCommon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerHandleNotification(notifyEbayReturnCommon: NotifyEbayReturnCommon, options?: any): AxiosPromise<void> {
            return localVarFp.ebayManagerControllerHandleNotification(notifyEbayReturnCommon, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReviseFixedPriceItemEbayDto} reviseFixedPriceItemEbayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerReviseFixedPriceItemEbay(reviseFixedPriceItemEbayDto: ReviseFixedPriceItemEbayDto, options?: any): AxiosPromise<object> {
            return localVarFp.ebayManagerControllerReviseFixedPriceItemEbay(reviseFixedPriceItemEbayDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReviseItemEbayDto} reviseItemEbayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerReviseItemEbay(reviseItemEbayDto: ReviseItemEbayDto, options?: any): AxiosPromise<object> {
            return localVarFp.ebayManagerControllerReviseItemEbay(reviseItemEbayDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GrantedLimitDto} grantedLimitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayManagerControllerStressLimit(grantedLimitDto: GrantedLimitDto, options?: any): AxiosPromise<void> {
            return localVarFp.ebayManagerControllerStressLimit(grantedLimitDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EbayManagerApi - object-oriented interface
 * @export
 * @class EbayManagerApi
 * @extends {BaseAPI}
 */
export class EbayManagerApi extends BaseAPI {
    /**
     * 
     * @param {ChangeNameDto} changeNameDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayManagerApi
     */
    public ebayManagerControllerChangeName(changeNameDto: ChangeNameDto, options?: AxiosRequestConfig) {
        return EbayManagerApiFp(this.configuration).ebayManagerControllerChangeName(changeNameDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConnectAccountEbayDto} connectAccountEbayDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayManagerApi
     */
    public ebayManagerControllerConnectAccountEbay(connectAccountEbayDto: ConnectAccountEbayDto, options?: AxiosRequestConfig) {
        return EbayManagerApiFp(this.configuration).ebayManagerControllerConnectAccountEbay(connectAccountEbayDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateShippingFulfillmentDetailsDto} createShippingFulfillmentDetailsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayManagerApi
     */
    public ebayManagerControllerCreateShippingFulfillmentFulfillment(createShippingFulfillmentDetailsDto: CreateShippingFulfillmentDetailsDto, options?: AxiosRequestConfig) {
        return EbayManagerApiFp(this.configuration).ebayManagerControllerCreateShippingFulfillmentFulfillment(createShippingFulfillmentDetailsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayManagerApi
     */
    public ebayManagerControllerDeleteAccountEbay(id: number, options?: AxiosRequestConfig) {
        return EbayManagerApiFp(this.configuration).ebayManagerControllerDeleteAccountEbay(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {number} [userId] 
     * @param {boolean} [isActivated] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayManagerApi
     */
    public ebayManagerControllerGetAllAccountEbay(page?: number, size?: number, sort?: string, fullTextSearch?: string, userId?: number, isActivated?: boolean, options?: AxiosRequestConfig) {
        return EbayManagerApiFp(this.configuration).ebayManagerControllerGetAllAccountEbay(page, size, sort, fullTextSearch, userId, isActivated, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayManagerApi
     */
    public ebayManagerControllerGetAllAccountEbayExpiredRefreshToken(options?: AxiosRequestConfig) {
        return EbayManagerApiFp(this.configuration).ebayManagerControllerGetAllAccountEbayExpiredRefreshToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayManagerApi
     */
    public ebayManagerControllerGetAllOrderReturnFromEbay(options?: AxiosRequestConfig) {
        return EbayManagerApiFp(this.configuration).ebayManagerControllerGetAllOrderReturnFromEbay(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetDetailItemEbayXMLDto} getDetailItemEbayXMLDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayManagerApi
     */
    public ebayManagerControllerGetItemEbayXML(getDetailItemEbayXMLDto: GetDetailItemEbayXMLDto, options?: AxiosRequestConfig) {
        return EbayManagerApiFp(this.configuration).ebayManagerControllerGetItemEbayXML(getDetailItemEbayXMLDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {string} userEbayId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayManagerApi
     */
    public ebayManagerControllerGetOneOrderReturnFromEbay(orderId: string, userEbayId: string, options?: AxiosRequestConfig) {
        return EbayManagerApiFp(this.configuration).ebayManagerControllerGetOneOrderReturnFromEbay(orderId, userEbayId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userEbayId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayManagerApi
     */
    public ebayManagerControllerGetShippingPolicyByAccountEbayId(userEbayId: number, options?: AxiosRequestConfig) {
        return EbayManagerApiFp(this.configuration).ebayManagerControllerGetShippingPolicyByAccountEbayId(userEbayId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayManagerApi
     */
    public ebayManagerControllerGetUrl(options?: AxiosRequestConfig) {
        return EbayManagerApiFp(this.configuration).ebayManagerControllerGetUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotifyEbayReturnCommon} notifyEbayReturnCommon 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayManagerApi
     */
    public ebayManagerControllerHandleNotification(notifyEbayReturnCommon: NotifyEbayReturnCommon, options?: AxiosRequestConfig) {
        return EbayManagerApiFp(this.configuration).ebayManagerControllerHandleNotification(notifyEbayReturnCommon, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReviseFixedPriceItemEbayDto} reviseFixedPriceItemEbayDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayManagerApi
     */
    public ebayManagerControllerReviseFixedPriceItemEbay(reviseFixedPriceItemEbayDto: ReviseFixedPriceItemEbayDto, options?: AxiosRequestConfig) {
        return EbayManagerApiFp(this.configuration).ebayManagerControllerReviseFixedPriceItemEbay(reviseFixedPriceItemEbayDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReviseItemEbayDto} reviseItemEbayDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayManagerApi
     */
    public ebayManagerControllerReviseItemEbay(reviseItemEbayDto: ReviseItemEbayDto, options?: AxiosRequestConfig) {
        return EbayManagerApiFp(this.configuration).ebayManagerControllerReviseItemEbay(reviseItemEbayDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GrantedLimitDto} grantedLimitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayManagerApi
     */
    public ebayManagerControllerStressLimit(grantedLimitDto: GrantedLimitDto, options?: AxiosRequestConfig) {
        return EbayManagerApiFp(this.configuration).ebayManagerControllerStressLimit(grantedLimitDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EbayReturnManagerApi - axios parameter creator
 * @export
 */
export const EbayReturnManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {number} [userEbayId] 
         * @param {boolean} [locationUS] 
         * @param {number} [userId] 
         * @param {string} [sortByReturnRequestDate] 
         * @param {string} [sortByStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayReturnControllerGetAllEbayReturnsOfUser: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, userEbayId?: number, locationUS?: boolean, userId?: number, sortByReturnRequestDate?: string, sortByStatus?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ebay-return-manager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (userEbayId !== undefined) {
                localVarQueryParameter['userEbayId'] = userEbayId;
            }

            if (locationUS !== undefined) {
                localVarQueryParameter['locationUS'] = locationUS;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (sortByReturnRequestDate !== undefined) {
                localVarQueryParameter['sortByReturnRequestDate'] = sortByReturnRequestDate;
            }

            if (sortByStatus !== undefined) {
                localVarQueryParameter['sortByStatus'] = sortByStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayReturnControllerGetDetailById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ebayReturnControllerGetDetailById', 'id', id)
            const localVarPath = `/ebay-return-manager/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayReturnControllerReadFile: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('ebayReturnControllerReadFile', 'file', file)
            const localVarPath = `/ebay-return-manager/read-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayReturnControllerSyncEbayReturn: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ebay-return-manager/sync-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayReturnControllerUpdateConditionName: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ebay-return-manager/update-condition-display-name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EbayReturnManagerApi - functional programming interface
 * @export
 */
export const EbayReturnManagerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EbayReturnManagerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {number} [userEbayId] 
         * @param {boolean} [locationUS] 
         * @param {number} [userId] 
         * @param {string} [sortByReturnRequestDate] 
         * @param {string} [sortByStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayReturnControllerGetAllEbayReturnsOfUser(page?: number, size?: number, sort?: string, fullTextSearch?: string, userEbayId?: number, locationUS?: boolean, userId?: number, sortByReturnRequestDate?: string, sortByStatus?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EbayReturnControllerGetAllEbayReturnsOfUser200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayReturnControllerGetAllEbayReturnsOfUser(page, size, sort, fullTextSearch, userEbayId, locationUS, userId, sortByReturnRequestDate, sortByStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayReturnControllerGetDetailById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EbayReturns>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayReturnControllerGetDetailById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayReturnControllerReadFile(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayReturnControllerReadFile(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayReturnControllerSyncEbayReturn(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayReturnControllerSyncEbayReturn(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ebayReturnControllerUpdateConditionName(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ebayReturnControllerUpdateConditionName(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EbayReturnManagerApi - factory interface
 * @export
 */
export const EbayReturnManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EbayReturnManagerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {number} [userEbayId] 
         * @param {boolean} [locationUS] 
         * @param {number} [userId] 
         * @param {string} [sortByReturnRequestDate] 
         * @param {string} [sortByStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayReturnControllerGetAllEbayReturnsOfUser(page?: number, size?: number, sort?: string, fullTextSearch?: string, userEbayId?: number, locationUS?: boolean, userId?: number, sortByReturnRequestDate?: string, sortByStatus?: string, options?: any): AxiosPromise<EbayReturnControllerGetAllEbayReturnsOfUser200Response> {
            return localVarFp.ebayReturnControllerGetAllEbayReturnsOfUser(page, size, sort, fullTextSearch, userEbayId, locationUS, userId, sortByReturnRequestDate, sortByStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayReturnControllerGetDetailById(id: number, options?: any): AxiosPromise<EbayReturns> {
            return localVarFp.ebayReturnControllerGetDetailById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayReturnControllerReadFile(file: File, options?: any): AxiosPromise<boolean> {
            return localVarFp.ebayReturnControllerReadFile(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayReturnControllerSyncEbayReturn(options?: any): AxiosPromise<void> {
            return localVarFp.ebayReturnControllerSyncEbayReturn(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ebayReturnControllerUpdateConditionName(options?: any): AxiosPromise<void> {
            return localVarFp.ebayReturnControllerUpdateConditionName(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EbayReturnManagerApi - object-oriented interface
 * @export
 * @class EbayReturnManagerApi
 * @extends {BaseAPI}
 */
export class EbayReturnManagerApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {number} [userEbayId] 
     * @param {boolean} [locationUS] 
     * @param {number} [userId] 
     * @param {string} [sortByReturnRequestDate] 
     * @param {string} [sortByStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayReturnManagerApi
     */
    public ebayReturnControllerGetAllEbayReturnsOfUser(page?: number, size?: number, sort?: string, fullTextSearch?: string, userEbayId?: number, locationUS?: boolean, userId?: number, sortByReturnRequestDate?: string, sortByStatus?: string, options?: AxiosRequestConfig) {
        return EbayReturnManagerApiFp(this.configuration).ebayReturnControllerGetAllEbayReturnsOfUser(page, size, sort, fullTextSearch, userEbayId, locationUS, userId, sortByReturnRequestDate, sortByStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayReturnManagerApi
     */
    public ebayReturnControllerGetDetailById(id: number, options?: AxiosRequestConfig) {
        return EbayReturnManagerApiFp(this.configuration).ebayReturnControllerGetDetailById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayReturnManagerApi
     */
    public ebayReturnControllerReadFile(file: File, options?: AxiosRequestConfig) {
        return EbayReturnManagerApiFp(this.configuration).ebayReturnControllerReadFile(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayReturnManagerApi
     */
    public ebayReturnControllerSyncEbayReturn(options?: AxiosRequestConfig) {
        return EbayReturnManagerApiFp(this.configuration).ebayReturnControllerSyncEbayReturn(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbayReturnManagerApi
     */
    public ebayReturnControllerUpdateConditionName(options?: AxiosRequestConfig) {
        return EbayReturnManagerApiFp(this.configuration).ebayReturnControllerUpdateConditionName(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExchangeRateApi - axios parameter creator
 * @export
 */
export const ExchangeRateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} fromTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangeRateControllerGetRate: async (fromTo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromTo' is not null or undefined
            assertParamExists('exchangeRateControllerGetRate', 'fromTo', fromTo)
            const localVarPath = `/exchange-rate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromTo !== undefined) {
                localVarQueryParameter['fromTo'] = fromTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExchangeRateApi - functional programming interface
 * @export
 */
export const ExchangeRateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExchangeRateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} fromTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangeRateControllerGetRate(fromTo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeRate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangeRateControllerGetRate(fromTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExchangeRateApi - factory interface
 * @export
 */
export const ExchangeRateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExchangeRateApiFp(configuration)
    return {
        /**
         * 
         * @param {string} fromTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangeRateControllerGetRate(fromTo: string, options?: any): AxiosPromise<ExchangeRate> {
            return localVarFp.exchangeRateControllerGetRate(fromTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExchangeRateApi - object-oriented interface
 * @export
 * @class ExchangeRateApi
 * @extends {BaseAPI}
 */
export class ExchangeRateApi extends BaseAPI {
    /**
     * 
     * @param {string} fromTo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeRateApi
     */
    public exchangeRateControllerGetRate(fromTo: string, options?: AxiosRequestConfig) {
        return ExchangeRateApiFp(this.configuration).exchangeRateControllerGetRate(fromTo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileExportApi - axios parameter creator
 * @export
 */
export const FileExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'USER_ALL' | 'USER_SHIPPED' | 'USER_IN_STOCK' | 'USER_ITEM_SHIP_PREPARE' | 'USER_ITEM_SHIPPED' | 'USER_ITEM_DISPOSAL' | 'USER_CANCEL' | 'USER_INQUIRY'} [tab] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileExportControllerExportCsv: async (tab?: 'USER_ALL' | 'USER_SHIPPED' | 'USER_IN_STOCK' | 'USER_ITEM_SHIP_PREPARE' | 'USER_ITEM_SHIPPED' | 'USER_ITEM_DISPOSAL' | 'USER_CANCEL' | 'USER_INQUIRY', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/file-export/item-to-csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tab !== undefined) {
                localVarQueryParameter['tab'] = tab;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileExportControllerReadFile: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('fileExportControllerReadFile', 'file', file)
            const localVarPath = `/file-export/read-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileExportApi - functional programming interface
 * @export
 */
export const FileExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileExportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {'USER_ALL' | 'USER_SHIPPED' | 'USER_IN_STOCK' | 'USER_ITEM_SHIP_PREPARE' | 'USER_ITEM_SHIPPED' | 'USER_ITEM_DISPOSAL' | 'USER_CANCEL' | 'USER_INQUIRY'} [tab] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileExportControllerExportCsv(tab?: 'USER_ALL' | 'USER_SHIPPED' | 'USER_IN_STOCK' | 'USER_ITEM_SHIP_PREPARE' | 'USER_ITEM_SHIPPED' | 'USER_ITEM_DISPOSAL' | 'USER_CANCEL' | 'USER_INQUIRY', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileExportControllerExportCsv(tab, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileExportControllerReadFile(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileExportControllerReadFile(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileExportApi - factory interface
 * @export
 */
export const FileExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileExportApiFp(configuration)
    return {
        /**
         * 
         * @param {'USER_ALL' | 'USER_SHIPPED' | 'USER_IN_STOCK' | 'USER_ITEM_SHIP_PREPARE' | 'USER_ITEM_SHIPPED' | 'USER_ITEM_DISPOSAL' | 'USER_CANCEL' | 'USER_INQUIRY'} [tab] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileExportControllerExportCsv(tab?: 'USER_ALL' | 'USER_SHIPPED' | 'USER_IN_STOCK' | 'USER_ITEM_SHIP_PREPARE' | 'USER_ITEM_SHIPPED' | 'USER_ITEM_DISPOSAL' | 'USER_CANCEL' | 'USER_INQUIRY', options?: any): AxiosPromise<void> {
            return localVarFp.fileExportControllerExportCsv(tab, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileExportControllerReadFile(file: File, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.fileExportControllerReadFile(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileExportApi - object-oriented interface
 * @export
 * @class FileExportApi
 * @extends {BaseAPI}
 */
export class FileExportApi extends BaseAPI {
    /**
     * 
     * @param {'USER_ALL' | 'USER_SHIPPED' | 'USER_IN_STOCK' | 'USER_ITEM_SHIP_PREPARE' | 'USER_ITEM_SHIPPED' | 'USER_ITEM_DISPOSAL' | 'USER_CANCEL' | 'USER_INQUIRY'} [tab] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileExportApi
     */
    public fileExportControllerExportCsv(tab?: 'USER_ALL' | 'USER_SHIPPED' | 'USER_IN_STOCK' | 'USER_ITEM_SHIP_PREPARE' | 'USER_ITEM_SHIPPED' | 'USER_ITEM_DISPOSAL' | 'USER_CANCEL' | 'USER_INQUIRY', options?: AxiosRequestConfig) {
        return FileExportApiFp(this.configuration).fileExportControllerExportCsv(tab, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileExportApi
     */
    public fileExportControllerReadFile(file: File, options?: AxiosRequestConfig) {
        return FileExportApiFp(this.configuration).fileExportControllerReadFile(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemInquiryMessageApi - axios parameter creator
 * @export
 */
export const ItemInquiryMessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} itemMessageGroupId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemInquiryControllerGetAll: async (itemMessageGroupId: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemMessageGroupId' is not null or undefined
            assertParamExists('itemInquiryControllerGetAll', 'itemMessageGroupId', itemMessageGroupId)
            const localVarPath = `/item-inquiry-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (itemMessageGroupId !== undefined) {
                localVarQueryParameter['itemMessageGroupId'] = itemMessageGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemInquiryMessageApi - functional programming interface
 * @export
 */
export const ItemInquiryMessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemInquiryMessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} itemMessageGroupId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemInquiryControllerGetAll(itemMessageGroupId: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemInquiryControllerGetAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemInquiryControllerGetAll(itemMessageGroupId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemInquiryMessageApi - factory interface
 * @export
 */
export const ItemInquiryMessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemInquiryMessageApiFp(configuration)
    return {
        /**
         * 
         * @param {number} itemMessageGroupId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemInquiryControllerGetAll(itemMessageGroupId: number, page?: number, size?: number, options?: any): AxiosPromise<ItemInquiryControllerGetAll200Response> {
            return localVarFp.itemInquiryControllerGetAll(itemMessageGroupId, page, size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemInquiryMessageApi - object-oriented interface
 * @export
 * @class ItemInquiryMessageApi
 * @extends {BaseAPI}
 */
export class ItemInquiryMessageApi extends BaseAPI {
    /**
     * 
     * @param {number} itemMessageGroupId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemInquiryMessageApi
     */
    public itemInquiryControllerGetAll(itemMessageGroupId: number, page?: number, size?: number, options?: AxiosRequestConfig) {
        return ItemInquiryMessageApiFp(this.configuration).itemInquiryControllerGetAll(itemMessageGroupId, page, size, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemInternalMessageApi - axios parameter creator
 * @export
 */
export const ItemInternalMessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} itemMessageGroupId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemIntenalControllerGetAll: async (itemMessageGroupId: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemMessageGroupId' is not null or undefined
            assertParamExists('itemIntenalControllerGetAll', 'itemMessageGroupId', itemMessageGroupId)
            const localVarPath = `/item-internal-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (itemMessageGroupId !== undefined) {
                localVarQueryParameter['itemMessageGroupId'] = itemMessageGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TranslateMessageDto} translateMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemIntenalControllerTranslateInternalMessage: async (translateMessageDto: TranslateMessageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'translateMessageDto' is not null or undefined
            assertParamExists('itemIntenalControllerTranslateInternalMessage', 'translateMessageDto', translateMessageDto)
            const localVarPath = `/item-internal-message/translate-internal-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translateMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemInternalMessageApi - functional programming interface
 * @export
 */
export const ItemInternalMessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemInternalMessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} itemMessageGroupId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemIntenalControllerGetAll(itemMessageGroupId: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemIntenalControllerGetAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemIntenalControllerGetAll(itemMessageGroupId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TranslateMessageDto} translateMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemIntenalControllerTranslateInternalMessage(translateMessageDto: TranslateMessageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemIntenalControllerTranslateInternalMessage(translateMessageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemInternalMessageApi - factory interface
 * @export
 */
export const ItemInternalMessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemInternalMessageApiFp(configuration)
    return {
        /**
         * 
         * @param {number} itemMessageGroupId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemIntenalControllerGetAll(itemMessageGroupId: number, page?: number, size?: number, options?: any): AxiosPromise<ItemIntenalControllerGetAll200Response> {
            return localVarFp.itemIntenalControllerGetAll(itemMessageGroupId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TranslateMessageDto} translateMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemIntenalControllerTranslateInternalMessage(translateMessageDto: TranslateMessageDto, options?: any): AxiosPromise<string> {
            return localVarFp.itemIntenalControllerTranslateInternalMessage(translateMessageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemInternalMessageApi - object-oriented interface
 * @export
 * @class ItemInternalMessageApi
 * @extends {BaseAPI}
 */
export class ItemInternalMessageApi extends BaseAPI {
    /**
     * 
     * @param {number} itemMessageGroupId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemInternalMessageApi
     */
    public itemIntenalControllerGetAll(itemMessageGroupId: number, page?: number, size?: number, options?: AxiosRequestConfig) {
        return ItemInternalMessageApiFp(this.configuration).itemIntenalControllerGetAll(itemMessageGroupId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TranslateMessageDto} translateMessageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemInternalMessageApi
     */
    public itemIntenalControllerTranslateInternalMessage(translateMessageDto: TranslateMessageDto, options?: AxiosRequestConfig) {
        return ItemInternalMessageApiFp(this.configuration).itemIntenalControllerTranslateInternalMessage(translateMessageDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemMessageGroupApi - axios parameter creator
 * @export
 */
export const ItemMessageGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ItemMessageGroup} itemMessageGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemMessageGroupControllerCreate: async (itemMessageGroup: ItemMessageGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemMessageGroup' is not null or undefined
            assertParamExists('itemMessageGroupControllerCreate', 'itemMessageGroup', itemMessageGroup)
            const localVarPath = `/item-message-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemMessageGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemMessageGroupControllerGetAll: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('itemMessageGroupControllerGetAll', 'id', id)
            const localVarPath = `/item-message-group/find-by-storage-item-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storageItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemMessageGroupControllerStaffUnread: async (storageItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storageItemId' is not null or undefined
            assertParamExists('itemMessageGroupControllerStaffUnread', 'storageItemId', storageItemId)
            const localVarPath = `/item-message-group/staff-unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (storageItemId !== undefined) {
                localVarQueryParameter['storageItemId'] = storageItemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateItemGroupDto} updateItemGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemMessageGroupControllerUpdate: async (id: number, updateItemGroupDto: UpdateItemGroupDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('itemMessageGroupControllerUpdate', 'id', id)
            // verify required parameter 'updateItemGroupDto' is not null or undefined
            assertParamExists('itemMessageGroupControllerUpdate', 'updateItemGroupDto', updateItemGroupDto)
            const localVarPath = `/item-message-group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateItemGroupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storageItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemMessageGroupControllerUserUnread: async (storageItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storageItemId' is not null or undefined
            assertParamExists('itemMessageGroupControllerUserUnread', 'storageItemId', storageItemId)
            const localVarPath = `/item-message-group/user-unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (storageItemId !== undefined) {
                localVarQueryParameter['storageItemId'] = storageItemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemMessageGroupApi - functional programming interface
 * @export
 */
export const ItemMessageGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemMessageGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ItemMessageGroup} itemMessageGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemMessageGroupControllerCreate(itemMessageGroup: ItemMessageGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemMessageGroupControllerCreate(itemMessageGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemMessageGroupControllerGetAll(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemMessageGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemMessageGroupControllerGetAll(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storageItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemMessageGroupControllerStaffUnread(storageItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemMessageGroupControllerStaffUnread(storageItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateItemGroupDto} updateItemGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemMessageGroupControllerUpdate(id: number, updateItemGroupDto: UpdateItemGroupDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemMessageGroupControllerUpdate(id, updateItemGroupDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storageItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemMessageGroupControllerUserUnread(storageItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemMessageGroupControllerUserUnread(storageItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemMessageGroupApi - factory interface
 * @export
 */
export const ItemMessageGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemMessageGroupApiFp(configuration)
    return {
        /**
         * 
         * @param {ItemMessageGroup} itemMessageGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemMessageGroupControllerCreate(itemMessageGroup: ItemMessageGroup, options?: any): AxiosPromise<object> {
            return localVarFp.itemMessageGroupControllerCreate(itemMessageGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemMessageGroupControllerGetAll(id: number, options?: any): AxiosPromise<Array<ItemMessageGroup>> {
            return localVarFp.itemMessageGroupControllerGetAll(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storageItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemMessageGroupControllerStaffUnread(storageItemId: number, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.itemMessageGroupControllerStaffUnread(storageItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateItemGroupDto} updateItemGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemMessageGroupControllerUpdate(id: number, updateItemGroupDto: UpdateItemGroupDto, options?: any): AxiosPromise<void> {
            return localVarFp.itemMessageGroupControllerUpdate(id, updateItemGroupDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storageItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemMessageGroupControllerUserUnread(storageItemId: number, options?: any): AxiosPromise<void> {
            return localVarFp.itemMessageGroupControllerUserUnread(storageItemId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemMessageGroupApi - object-oriented interface
 * @export
 * @class ItemMessageGroupApi
 * @extends {BaseAPI}
 */
export class ItemMessageGroupApi extends BaseAPI {
    /**
     * 
     * @param {ItemMessageGroup} itemMessageGroup 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemMessageGroupApi
     */
    public itemMessageGroupControllerCreate(itemMessageGroup: ItemMessageGroup, options?: AxiosRequestConfig) {
        return ItemMessageGroupApiFp(this.configuration).itemMessageGroupControllerCreate(itemMessageGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemMessageGroupApi
     */
    public itemMessageGroupControllerGetAll(id: number, options?: AxiosRequestConfig) {
        return ItemMessageGroupApiFp(this.configuration).itemMessageGroupControllerGetAll(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storageItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemMessageGroupApi
     */
    public itemMessageGroupControllerStaffUnread(storageItemId: number, options?: AxiosRequestConfig) {
        return ItemMessageGroupApiFp(this.configuration).itemMessageGroupControllerStaffUnread(storageItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateItemGroupDto} updateItemGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemMessageGroupApi
     */
    public itemMessageGroupControllerUpdate(id: number, updateItemGroupDto: UpdateItemGroupDto, options?: AxiosRequestConfig) {
        return ItemMessageGroupApiFp(this.configuration).itemMessageGroupControllerUpdate(id, updateItemGroupDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storageItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemMessageGroupApi
     */
    public itemMessageGroupControllerUserUnread(storageItemId: number, options?: AxiosRequestConfig) {
        return ItemMessageGroupApiFp(this.configuration).itemMessageGroupControllerUserUnread(storageItemId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MailsApi - axios parameter creator
 * @export
 */
export const MailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailControllerTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mails/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailsApi - functional programming interface
 * @export
 */
export const MailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailControllerTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mailControllerTest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailsApi - factory interface
 * @export
 */
export const MailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailControllerTest(options?: any): AxiosPromise<void> {
            return localVarFp.mailControllerTest(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailsApi - object-oriented interface
 * @export
 * @class MailsApi
 * @extends {BaseAPI}
 */
export class MailsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailsApi
     */
    public mailControllerTest(options?: AxiosRequestConfig) {
        return MailsApiFp(this.configuration).mailControllerTest(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentSettingsApi - axios parameter creator
 * @export
 */
export const PaymentSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentSettingControllerCanCreate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment-settings/can-create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreatePaymentSettingDto} createPaymentSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentSettingControllerCreatePaymentSetting: async (createPaymentSettingDto: CreatePaymentSettingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPaymentSettingDto' is not null or undefined
            assertParamExists('paymentSettingControllerCreatePaymentSetting', 'createPaymentSettingDto', createPaymentSettingDto)
            const localVarPath = `/payment-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPaymentSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {'active' | 'draft' | 'fixed' | 'disabled'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentSettingControllerGetAllSetting: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, status?: 'active' | 'draft' | 'fixed' | 'disabled', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'active' | 'draft' | 'fixed' | 'disabled'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentSettingControllerGetByStatus: async (status?: 'active' | 'draft' | 'fixed' | 'disabled', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment-settings/get-by-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentSettingControllerGetPaymentSettingById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paymentSettingControllerGetPaymentSettingById', 'id', id)
            const localVarPath = `/payment-settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePaymentSettingDto} updatePaymentSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentSettingControllerUpdatePaymentSetting: async (updatePaymentSettingDto: UpdatePaymentSettingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePaymentSettingDto' is not null or undefined
            assertParamExists('paymentSettingControllerUpdatePaymentSetting', 'updatePaymentSettingDto', updatePaymentSettingDto)
            const localVarPath = `/payment-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePaymentSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentSettingsApi - functional programming interface
 * @export
 */
export const PaymentSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentSettingControllerCanCreate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentSettingControllerCanCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreatePaymentSettingDto} createPaymentSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentSettingControllerCreatePaymentSetting(createPaymentSettingDto: CreatePaymentSettingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentSettingControllerCreatePaymentSetting(createPaymentSettingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {'active' | 'draft' | 'fixed' | 'disabled'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentSettingControllerGetAllSetting(page?: number, size?: number, sort?: string, fullTextSearch?: string, status?: 'active' | 'draft' | 'fixed' | 'disabled', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentSettingControllerGetAllSetting200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentSettingControllerGetAllSetting(page, size, sort, fullTextSearch, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'active' | 'draft' | 'fixed' | 'disabled'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentSettingControllerGetByStatus(status?: 'active' | 'draft' | 'fixed' | 'disabled', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentSettingControllerGetByStatus(status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentSettingControllerGetPaymentSettingById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentSettingControllerGetPaymentSettingById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdatePaymentSettingDto} updatePaymentSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentSettingControllerUpdatePaymentSetting(updatePaymentSettingDto: UpdatePaymentSettingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentSettingControllerUpdatePaymentSetting(updatePaymentSettingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentSettingsApi - factory interface
 * @export
 */
export const PaymentSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentSettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentSettingControllerCanCreate(options?: any): AxiosPromise<void> {
            return localVarFp.paymentSettingControllerCanCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreatePaymentSettingDto} createPaymentSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentSettingControllerCreatePaymentSetting(createPaymentSettingDto: CreatePaymentSettingDto, options?: any): AxiosPromise<PaymentSetting> {
            return localVarFp.paymentSettingControllerCreatePaymentSetting(createPaymentSettingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {'active' | 'draft' | 'fixed' | 'disabled'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentSettingControllerGetAllSetting(page?: number, size?: number, sort?: string, fullTextSearch?: string, status?: 'active' | 'draft' | 'fixed' | 'disabled', options?: any): AxiosPromise<PaymentSettingControllerGetAllSetting200Response> {
            return localVarFp.paymentSettingControllerGetAllSetting(page, size, sort, fullTextSearch, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'active' | 'draft' | 'fixed' | 'disabled'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentSettingControllerGetByStatus(status?: 'active' | 'draft' | 'fixed' | 'disabled', options?: any): AxiosPromise<PaymentSetting> {
            return localVarFp.paymentSettingControllerGetByStatus(status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentSettingControllerGetPaymentSettingById(id: number, options?: any): AxiosPromise<PaymentSetting> {
            return localVarFp.paymentSettingControllerGetPaymentSettingById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePaymentSettingDto} updatePaymentSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentSettingControllerUpdatePaymentSetting(updatePaymentSettingDto: UpdatePaymentSettingDto, options?: any): AxiosPromise<PaymentSetting> {
            return localVarFp.paymentSettingControllerUpdatePaymentSetting(updatePaymentSettingDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentSettingsApi - object-oriented interface
 * @export
 * @class PaymentSettingsApi
 * @extends {BaseAPI}
 */
export class PaymentSettingsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentSettingsApi
     */
    public paymentSettingControllerCanCreate(options?: AxiosRequestConfig) {
        return PaymentSettingsApiFp(this.configuration).paymentSettingControllerCanCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreatePaymentSettingDto} createPaymentSettingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentSettingsApi
     */
    public paymentSettingControllerCreatePaymentSetting(createPaymentSettingDto: CreatePaymentSettingDto, options?: AxiosRequestConfig) {
        return PaymentSettingsApiFp(this.configuration).paymentSettingControllerCreatePaymentSetting(createPaymentSettingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {'active' | 'draft' | 'fixed' | 'disabled'} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentSettingsApi
     */
    public paymentSettingControllerGetAllSetting(page?: number, size?: number, sort?: string, fullTextSearch?: string, status?: 'active' | 'draft' | 'fixed' | 'disabled', options?: AxiosRequestConfig) {
        return PaymentSettingsApiFp(this.configuration).paymentSettingControllerGetAllSetting(page, size, sort, fullTextSearch, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'active' | 'draft' | 'fixed' | 'disabled'} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentSettingsApi
     */
    public paymentSettingControllerGetByStatus(status?: 'active' | 'draft' | 'fixed' | 'disabled', options?: AxiosRequestConfig) {
        return PaymentSettingsApiFp(this.configuration).paymentSettingControllerGetByStatus(status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentSettingsApi
     */
    public paymentSettingControllerGetPaymentSettingById(id: number, options?: AxiosRequestConfig) {
        return PaymentSettingsApiFp(this.configuration).paymentSettingControllerGetPaymentSettingById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePaymentSettingDto} updatePaymentSettingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentSettingsApi
     */
    public paymentSettingControllerUpdatePaymentSetting(updatePaymentSettingDto: UpdatePaymentSettingDto, options?: AxiosRequestConfig) {
        return PaymentSettingsApiFp(this.configuration).paymentSettingControllerUpdatePaymentSetting(updatePaymentSettingDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MonthPaymentDetailDto} monthPaymentDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerExportCsv: async (monthPaymentDetailDto: MonthPaymentDetailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monthPaymentDetailDto' is not null or undefined
            assertParamExists('paymentControllerExportCsv', 'monthPaymentDetailDto', monthPaymentDetailDto)
            const localVarPath = `/payments/export-cvs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monthPaymentDetailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerExportPdfCount: async (month: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('paymentControllerExportPdfCount', 'month', month)
            const localVarPath = `/payments/export-pdf-count/{month}`
                .replace(`{${"month"}}`, encodeURIComponent(String(month)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MonthPaymentDetailDto} monthPaymentDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerGetMonthPaymentDetail: async (monthPaymentDetailDto: MonthPaymentDetailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monthPaymentDetailDto' is not null or undefined
            assertParamExists('paymentControllerGetMonthPaymentDetail', 'monthPaymentDetailDto', monthPaymentDetailDto)
            const localVarPath = `/payments/get-month-payment-detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monthPaymentDetailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerGetMonthPaymentList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payments/get-month-payment-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ManualPaymentDto} manualPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerManualChargePayment: async (manualPaymentDto: ManualPaymentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manualPaymentDto' is not null or undefined
            assertParamExists('paymentControllerManualChargePayment', 'manualPaymentDto', manualPaymentDto)
            const localVarPath = `/payments/manual-charge-payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manualPaymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MonthPaymentDetailDto} monthPaymentDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerExportCsv(monthPaymentDetailDto: MonthPaymentDetailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerExportCsv(monthPaymentDetailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerExportPdfCount(month: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerExportPdfCount(month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MonthPaymentDetailDto} monthPaymentDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerGetMonthPaymentDetail(monthPaymentDetailDto: MonthPaymentDetailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonthPaymentDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerGetMonthPaymentDetail(monthPaymentDetailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerGetMonthPaymentList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonthPaymentListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerGetMonthPaymentList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ManualPaymentDto} manualPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerManualChargePayment(manualPaymentDto: ManualPaymentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerManualChargePayment(manualPaymentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * 
         * @param {MonthPaymentDetailDto} monthPaymentDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerExportCsv(monthPaymentDetailDto: MonthPaymentDetailDto, options?: any): AxiosPromise<void> {
            return localVarFp.paymentControllerExportCsv(monthPaymentDetailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerExportPdfCount(month: string, options?: any): AxiosPromise<number> {
            return localVarFp.paymentControllerExportPdfCount(month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MonthPaymentDetailDto} monthPaymentDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerGetMonthPaymentDetail(monthPaymentDetailDto: MonthPaymentDetailDto, options?: any): AxiosPromise<Array<MonthPaymentDetail>> {
            return localVarFp.paymentControllerGetMonthPaymentDetail(monthPaymentDetailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerGetMonthPaymentList(options?: any): AxiosPromise<Array<MonthPaymentListItem>> {
            return localVarFp.paymentControllerGetMonthPaymentList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ManualPaymentDto} manualPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerManualChargePayment(manualPaymentDto: ManualPaymentDto, options?: any): AxiosPromise<Payment> {
            return localVarFp.paymentControllerManualChargePayment(manualPaymentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * 
     * @param {MonthPaymentDetailDto} monthPaymentDetailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentControllerExportCsv(monthPaymentDetailDto: MonthPaymentDetailDto, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).paymentControllerExportCsv(monthPaymentDetailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentControllerExportPdfCount(month: string, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).paymentControllerExportPdfCount(month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MonthPaymentDetailDto} monthPaymentDetailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentControllerGetMonthPaymentDetail(monthPaymentDetailDto: MonthPaymentDetailDto, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).paymentControllerGetMonthPaymentDetail(monthPaymentDetailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentControllerGetMonthPaymentList(options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).paymentControllerGetMonthPaymentList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManualPaymentDto} manualPaymentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentControllerManualChargePayment(manualPaymentDto: ManualPaymentDto, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).paymentControllerManualChargePayment(manualPaymentDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PermissionsApi - axios parameter creator
 * @export
 */
export const PermissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionControllerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PermissionsApi - functional programming interface
 * @export
 */
export const PermissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PermissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionControllerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionControllerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PermissionsApi - factory interface
 * @export
 */
export const PermissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PermissionsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionControllerGet(options?: any): AxiosPromise<void> {
            return localVarFp.permissionControllerGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PermissionsApi - object-oriented interface
 * @export
 * @class PermissionsApi
 * @extends {BaseAPI}
 */
export class PermissionsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public permissionControllerGet(options?: AxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).permissionControllerGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlansApi - axios parameter creator
 * @export
 */
export const PlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangeUserPlan} changeUserPlan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerChangeUserPlan: async (changeUserPlan: ChangeUserPlan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeUserPlan' is not null or undefined
            assertParamExists('planControllerChangeUserPlan', 'changeUserPlan', changeUserPlan)
            const localVarPath = `/plans/change-user-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeUserPlan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerCheckDeleteAvailable: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planControllerCheckDeleteAvailable', 'id', id)
            const localVarPath = `/plans/check-delete-account-ebay/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerCheckUnsubcriptionAvailable: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plans/check-unsubscription-available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreatePlanDto} createPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerCreatePlan: async (createPlanDto: CreatePlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPlanDto' is not null or undefined
            assertParamExists('planControllerCreatePlan', 'createPlanDto', createPlanDto)
            const localVarPath = `/plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerExtraFeeWhenChargeAfter21: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planControllerExtraFeeWhenChargeAfter21', 'id', id)
            const localVarPath = `/plans/plan-version/extra-fee-after-21/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerGetAllPlan: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerGetAllPlanVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plans/plan-version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerGetMyPlan: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plans/my-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerGetPlanById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planControllerGetPlanById', 'id', id)
            const localVarPath = `/plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerGetPlanVersionById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planControllerGetPlanVersionById', 'id', id)
            const localVarPath = `/plans/plan-version/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerGetPlanVersionByIdForUpdatePlan: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planControllerGetPlanVersionByIdForUpdatePlan', 'id', id)
            const localVarPath = `/plans/plan-version-for-update-plan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerPlanByUser: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planControllerPlanByUser', 'id', id)
            const localVarPath = `/plans/plan-by-user-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerResubscription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plans/resubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerUnsubcription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plans/unsubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerUnsubcriptionInformation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plans/unsubscription-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePlanDto} updatePlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerUpdatePlan: async (updatePlanDto: UpdatePlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePlanDto' is not null or undefined
            assertParamExists('planControllerUpdatePlan', 'updatePlanDto', updatePlanDto)
            const localVarPath = `/plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlansApi - functional programming interface
 * @export
 */
export const PlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChangeUserPlan} changeUserPlan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerChangeUserPlan(changeUserPlan: ChangeUserPlan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerChangeUserPlan(changeUserPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerCheckDeleteAvailable(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckUnsubcriptionAvailableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerCheckDeleteAvailable(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerCheckUnsubcriptionAvailable(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckUnsubcriptionAvailableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerCheckUnsubcriptionAvailable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreatePlanDto} createPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerCreatePlan(createPlanDto: CreatePlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerCreatePlan(createPlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerExtraFeeWhenChargeAfter21(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerExtraFeeWhenChargeAfter21(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerGetAllPlan(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Plan>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerGetAllPlan(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerGetAllPlanVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlanVersion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerGetAllPlanVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerGetMyPlan(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerGetMyPlan(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerGetPlanById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerGetPlanById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerGetPlanVersionById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerGetPlanVersionById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerGetPlanVersionByIdForUpdatePlan(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanVersionExtendDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerGetPlanVersionByIdForUpdatePlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerPlanByUser(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerPlanByUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerResubscription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerResubscription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerUnsubcription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerUnsubcription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerUnsubcriptionInformation(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnsubscriptionInformationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerUnsubcriptionInformation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdatePlanDto} updatePlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planControllerUpdatePlan(updatePlanDto: UpdatePlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planControllerUpdatePlan(updatePlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlansApi - factory interface
 * @export
 */
export const PlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlansApiFp(configuration)
    return {
        /**
         * 
         * @param {ChangeUserPlan} changeUserPlan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerChangeUserPlan(changeUserPlan: ChangeUserPlan, options?: any): AxiosPromise<void> {
            return localVarFp.planControllerChangeUserPlan(changeUserPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerCheckDeleteAvailable(id: number, options?: any): AxiosPromise<CheckUnsubcriptionAvailableResponse> {
            return localVarFp.planControllerCheckDeleteAvailable(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerCheckUnsubcriptionAvailable(options?: any): AxiosPromise<CheckUnsubcriptionAvailableResponse> {
            return localVarFp.planControllerCheckUnsubcriptionAvailable(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreatePlanDto} createPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerCreatePlan(createPlanDto: CreatePlanDto, options?: any): AxiosPromise<Plan> {
            return localVarFp.planControllerCreatePlan(createPlanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerExtraFeeWhenChargeAfter21(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.planControllerExtraFeeWhenChargeAfter21(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerGetAllPlan(options?: any): AxiosPromise<Array<Plan>> {
            return localVarFp.planControllerGetAllPlan(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerGetAllPlanVersion(options?: any): AxiosPromise<Array<PlanVersion>> {
            return localVarFp.planControllerGetAllPlanVersion(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerGetMyPlan(options?: any): AxiosPromise<object> {
            return localVarFp.planControllerGetMyPlan(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerGetPlanById(id: number, options?: any): AxiosPromise<Plan> {
            return localVarFp.planControllerGetPlanById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerGetPlanVersionById(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.planControllerGetPlanVersionById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerGetPlanVersionByIdForUpdatePlan(id: number, options?: any): AxiosPromise<PlanVersionExtendDto> {
            return localVarFp.planControllerGetPlanVersionByIdForUpdatePlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerPlanByUser(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.planControllerPlanByUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerResubscription(options?: any): AxiosPromise<void> {
            return localVarFp.planControllerResubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerUnsubcription(options?: any): AxiosPromise<void> {
            return localVarFp.planControllerUnsubcription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerUnsubcriptionInformation(options?: any): AxiosPromise<UnsubscriptionInformationResponse> {
            return localVarFp.planControllerUnsubcriptionInformation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePlanDto} updatePlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planControllerUpdatePlan(updatePlanDto: UpdatePlanDto, options?: any): AxiosPromise<object> {
            return localVarFp.planControllerUpdatePlan(updatePlanDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlansApi - object-oriented interface
 * @export
 * @class PlansApi
 * @extends {BaseAPI}
 */
export class PlansApi extends BaseAPI {
    /**
     * 
     * @param {ChangeUserPlan} changeUserPlan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public planControllerChangeUserPlan(changeUserPlan: ChangeUserPlan, options?: AxiosRequestConfig) {
        return PlansApiFp(this.configuration).planControllerChangeUserPlan(changeUserPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public planControllerCheckDeleteAvailable(id: number, options?: AxiosRequestConfig) {
        return PlansApiFp(this.configuration).planControllerCheckDeleteAvailable(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public planControllerCheckUnsubcriptionAvailable(options?: AxiosRequestConfig) {
        return PlansApiFp(this.configuration).planControllerCheckUnsubcriptionAvailable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreatePlanDto} createPlanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public planControllerCreatePlan(createPlanDto: CreatePlanDto, options?: AxiosRequestConfig) {
        return PlansApiFp(this.configuration).planControllerCreatePlan(createPlanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public planControllerExtraFeeWhenChargeAfter21(id: number, options?: AxiosRequestConfig) {
        return PlansApiFp(this.configuration).planControllerExtraFeeWhenChargeAfter21(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public planControllerGetAllPlan(options?: AxiosRequestConfig) {
        return PlansApiFp(this.configuration).planControllerGetAllPlan(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public planControllerGetAllPlanVersion(options?: AxiosRequestConfig) {
        return PlansApiFp(this.configuration).planControllerGetAllPlanVersion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public planControllerGetMyPlan(options?: AxiosRequestConfig) {
        return PlansApiFp(this.configuration).planControllerGetMyPlan(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public planControllerGetPlanById(id: number, options?: AxiosRequestConfig) {
        return PlansApiFp(this.configuration).planControllerGetPlanById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public planControllerGetPlanVersionById(id: number, options?: AxiosRequestConfig) {
        return PlansApiFp(this.configuration).planControllerGetPlanVersionById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public planControllerGetPlanVersionByIdForUpdatePlan(id: number, options?: AxiosRequestConfig) {
        return PlansApiFp(this.configuration).planControllerGetPlanVersionByIdForUpdatePlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public planControllerPlanByUser(id: number, options?: AxiosRequestConfig) {
        return PlansApiFp(this.configuration).planControllerPlanByUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public planControllerResubscription(options?: AxiosRequestConfig) {
        return PlansApiFp(this.configuration).planControllerResubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public planControllerUnsubcription(options?: AxiosRequestConfig) {
        return PlansApiFp(this.configuration).planControllerUnsubcription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public planControllerUnsubcriptionInformation(options?: AxiosRequestConfig) {
        return PlansApiFp(this.configuration).planControllerUnsubcriptionInformation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePlanDto} updatePlanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public planControllerUpdatePlan(updatePlanDto: UpdatePlanDto, options?: AxiosRequestConfig) {
        return PlansApiFp(this.configuration).planControllerUpdatePlan(updatePlanDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RacksApi - axios parameter creator
 * @export
 */
export const RacksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRackDto} createRackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rackControllerCreate: async (createRackDto: CreateRackDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRackDto' is not null or undefined
            assertParamExists('rackControllerCreate', 'createRackDto', createRackDto)
            const localVarPath = `/racks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRackDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rackControllerDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rackControllerDelete', 'id', id)
            const localVarPath = `/racks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rackControllerFindAll: async (page?: number, size?: number, fullTextSearch?: string, includeDeleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/racks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rackControllerFindOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rackControllerFindOne', 'id', id)
            const localVarPath = `/racks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateRackDto} updateRackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rackControllerUpdate: async (id: number, updateRackDto: UpdateRackDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rackControllerUpdate', 'id', id)
            // verify required parameter 'updateRackDto' is not null or undefined
            assertParamExists('rackControllerUpdate', 'updateRackDto', updateRackDto)
            const localVarPath = `/racks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRackDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WarehouseCheckDto} warehouseCheckDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rackControllerWarehouseCheck: async (warehouseCheckDto: WarehouseCheckDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseCheckDto' is not null or undefined
            assertParamExists('rackControllerWarehouseCheck', 'warehouseCheckDto', warehouseCheckDto)
            const localVarPath = `/racks/warehouse-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(warehouseCheckDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RacksApi - functional programming interface
 * @export
 */
export const RacksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RacksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRackDto} createRackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rackControllerCreate(createRackDto: CreateRackDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rackControllerCreate(createRackDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rackControllerDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rackControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rackControllerFindAll(page?: number, size?: number, fullTextSearch?: string, includeDeleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RackControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rackControllerFindAll(page, size, fullTextSearch, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rackControllerFindOne(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rack>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rackControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateRackDto} updateRackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rackControllerUpdate(id: number, updateRackDto: UpdateRackDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rackControllerUpdate(id, updateRackDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WarehouseCheckDto} warehouseCheckDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rackControllerWarehouseCheck(warehouseCheckDto: WarehouseCheckDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rackControllerWarehouseCheck(warehouseCheckDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RacksApi - factory interface
 * @export
 */
export const RacksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RacksApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateRackDto} createRackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rackControllerCreate(createRackDto: CreateRackDto, options?: any): AxiosPromise<void> {
            return localVarFp.rackControllerCreate(createRackDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rackControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.rackControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rackControllerFindAll(page?: number, size?: number, fullTextSearch?: string, includeDeleted?: boolean, options?: any): AxiosPromise<RackControllerFindAll200Response> {
            return localVarFp.rackControllerFindAll(page, size, fullTextSearch, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rackControllerFindOne(id: number, options?: any): AxiosPromise<Rack> {
            return localVarFp.rackControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateRackDto} updateRackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rackControllerUpdate(id: number, updateRackDto: UpdateRackDto, options?: any): AxiosPromise<void> {
            return localVarFp.rackControllerUpdate(id, updateRackDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WarehouseCheckDto} warehouseCheckDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rackControllerWarehouseCheck(warehouseCheckDto: WarehouseCheckDto, options?: any): AxiosPromise<WarehouseCheckResponse> {
            return localVarFp.rackControllerWarehouseCheck(warehouseCheckDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RacksApi - object-oriented interface
 * @export
 * @class RacksApi
 * @extends {BaseAPI}
 */
export class RacksApi extends BaseAPI {
    /**
     * 
     * @param {CreateRackDto} createRackDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RacksApi
     */
    public rackControllerCreate(createRackDto: CreateRackDto, options?: AxiosRequestConfig) {
        return RacksApiFp(this.configuration).rackControllerCreate(createRackDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RacksApi
     */
    public rackControllerDelete(id: number, options?: AxiosRequestConfig) {
        return RacksApiFp(this.configuration).rackControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RacksApi
     */
    public rackControllerFindAll(page?: number, size?: number, fullTextSearch?: string, includeDeleted?: boolean, options?: AxiosRequestConfig) {
        return RacksApiFp(this.configuration).rackControllerFindAll(page, size, fullTextSearch, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RacksApi
     */
    public rackControllerFindOne(id: number, options?: AxiosRequestConfig) {
        return RacksApiFp(this.configuration).rackControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateRackDto} updateRackDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RacksApi
     */
    public rackControllerUpdate(id: number, updateRackDto: UpdateRackDto, options?: AxiosRequestConfig) {
        return RacksApiFp(this.configuration).rackControllerUpdate(id, updateRackDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarehouseCheckDto} warehouseCheckDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RacksApi
     */
    public rackControllerWarehouseCheck(warehouseCheckDto: WarehouseCheckDto, options?: AxiosRequestConfig) {
        return RacksApiFp(this.configuration).rackControllerWarehouseCheck(warehouseCheckDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RevenuesApi - axios parameter creator
 * @export
 */
export const RevenuesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MonthlyDetailDto} monthlyDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revenueControllerMonthlyDetail: async (monthlyDetailDto: MonthlyDetailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monthlyDetailDto' is not null or undefined
            assertParamExists('revenueControllerMonthlyDetail', 'monthlyDetailDto', monthlyDetailDto)
            const localVarPath = `/revenues/monthly-detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monthlyDetailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MonthlyDetailDto} monthlyDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revenueControllerMonthlyDetailExportPdf: async (monthlyDetailDto: MonthlyDetailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monthlyDetailDto' is not null or undefined
            assertParamExists('revenueControllerMonthlyDetailExportPdf', 'monthlyDetailDto', monthlyDetailDto)
            const localVarPath = `/revenues/monthly-detail-export-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monthlyDetailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revenueControllerOverview: async (page: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('revenueControllerOverview', 'page', page)
            const localVarPath = `/revenues/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revenueControllerOverviewExportPdf: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/revenues/overview-export-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revenueControllerTotalMonth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/revenues/month-in-total`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RevenuesApi - functional programming interface
 * @export
 */
export const RevenuesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RevenuesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MonthlyDetailDto} monthlyDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revenueControllerMonthlyDetail(monthlyDetailDto: MonthlyDetailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RevenueMonthDetailResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revenueControllerMonthlyDetail(monthlyDetailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MonthlyDetailDto} monthlyDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revenueControllerMonthlyDetailExportPdf(monthlyDetailDto: MonthlyDetailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revenueControllerMonthlyDetailExportPdf(monthlyDetailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revenueControllerOverview(page: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverViewResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revenueControllerOverview(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revenueControllerOverviewExportPdf(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revenueControllerOverviewExportPdf(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revenueControllerTotalMonth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revenueControllerTotalMonth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RevenuesApi - factory interface
 * @export
 */
export const RevenuesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RevenuesApiFp(configuration)
    return {
        /**
         * 
         * @param {MonthlyDetailDto} monthlyDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revenueControllerMonthlyDetail(monthlyDetailDto: MonthlyDetailDto, options?: any): AxiosPromise<RevenueMonthDetailResponseDto> {
            return localVarFp.revenueControllerMonthlyDetail(monthlyDetailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MonthlyDetailDto} monthlyDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revenueControllerMonthlyDetailExportPdf(monthlyDetailDto: MonthlyDetailDto, options?: any): AxiosPromise<void> {
            return localVarFp.revenueControllerMonthlyDetailExportPdf(monthlyDetailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revenueControllerOverview(page: number, options?: any): AxiosPromise<OverViewResponseDto> {
            return localVarFp.revenueControllerOverview(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revenueControllerOverviewExportPdf(options?: any): AxiosPromise<void> {
            return localVarFp.revenueControllerOverviewExportPdf(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revenueControllerTotalMonth(options?: any): AxiosPromise<number> {
            return localVarFp.revenueControllerTotalMonth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RevenuesApi - object-oriented interface
 * @export
 * @class RevenuesApi
 * @extends {BaseAPI}
 */
export class RevenuesApi extends BaseAPI {
    /**
     * 
     * @param {MonthlyDetailDto} monthlyDetailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevenuesApi
     */
    public revenueControllerMonthlyDetail(monthlyDetailDto: MonthlyDetailDto, options?: AxiosRequestConfig) {
        return RevenuesApiFp(this.configuration).revenueControllerMonthlyDetail(monthlyDetailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MonthlyDetailDto} monthlyDetailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevenuesApi
     */
    public revenueControllerMonthlyDetailExportPdf(monthlyDetailDto: MonthlyDetailDto, options?: AxiosRequestConfig) {
        return RevenuesApiFp(this.configuration).revenueControllerMonthlyDetailExportPdf(monthlyDetailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevenuesApi
     */
    public revenueControllerOverview(page: number, options?: AxiosRequestConfig) {
        return RevenuesApiFp(this.configuration).revenueControllerOverview(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevenuesApi
     */
    public revenueControllerOverviewExportPdf(options?: AxiosRequestConfig) {
        return RevenuesApiFp(this.configuration).revenueControllerOverviewExportPdf(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevenuesApi
     */
    public revenueControllerTotalMonth(options?: AxiosRequestConfig) {
        return RevenuesApiFp(this.configuration).revenueControllerTotalMonth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SchedulerApi - axios parameter creator
 * @export
 */
export const SchedulerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulerControllerAt1st: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scheduler/1st`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulerControllerAt21th: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scheduler/21th`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulerControllerAt25th: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scheduler/25th`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulerControllerEveryday: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scheduler/everyday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulerControllerTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scheduler/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulerControllerUpdate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scheduler/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchedulerApi - functional programming interface
 * @export
 */
export const SchedulerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchedulerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async schedulerControllerAt1st(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.schedulerControllerAt1st(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async schedulerControllerAt21th(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.schedulerControllerAt21th(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async schedulerControllerAt25th(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.schedulerControllerAt25th(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async schedulerControllerEveryday(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.schedulerControllerEveryday(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async schedulerControllerTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.schedulerControllerTest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async schedulerControllerUpdate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.schedulerControllerUpdate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SchedulerApi - factory interface
 * @export
 */
export const SchedulerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchedulerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulerControllerAt1st(options?: any): AxiosPromise<void> {
            return localVarFp.schedulerControllerAt1st(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulerControllerAt21th(options?: any): AxiosPromise<void> {
            return localVarFp.schedulerControllerAt21th(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulerControllerAt25th(options?: any): AxiosPromise<void> {
            return localVarFp.schedulerControllerAt25th(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulerControllerEveryday(options?: any): AxiosPromise<void> {
            return localVarFp.schedulerControllerEveryday(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulerControllerTest(options?: any): AxiosPromise<void> {
            return localVarFp.schedulerControllerTest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedulerControllerUpdate(options?: any): AxiosPromise<void> {
            return localVarFp.schedulerControllerUpdate(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchedulerApi - object-oriented interface
 * @export
 * @class SchedulerApi
 * @extends {BaseAPI}
 */
export class SchedulerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public schedulerControllerAt1st(options?: AxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).schedulerControllerAt1st(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public schedulerControllerAt21th(options?: AxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).schedulerControllerAt21th(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public schedulerControllerAt25th(options?: AxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).schedulerControllerAt25th(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public schedulerControllerEveryday(options?: AxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).schedulerControllerEveryday(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public schedulerControllerTest(options?: AxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).schedulerControllerTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public schedulerControllerUpdate(options?: AxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).schedulerControllerUpdate(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingNotifyApi - axios parameter creator
 * @export
 */
export const SettingNotifyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingNotificationControllerFindByUserId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/setting-notify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SettingNotifyDto} settingNotifyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingNotificationControllerUpSert: async (settingNotifyDto: SettingNotifyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingNotifyDto' is not null or undefined
            assertParamExists('settingNotificationControllerUpSert', 'settingNotifyDto', settingNotifyDto)
            const localVarPath = `/setting-notify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingNotifyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingNotifyApi - functional programming interface
 * @export
 */
export const SettingNotifyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingNotifyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingNotificationControllerFindByUserId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingNotificationControllerFindByUserId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SettingNotifyDto} settingNotifyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingNotificationControllerUpSert(settingNotifyDto: SettingNotifyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingNotificationControllerUpSert(settingNotifyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingNotifyApi - factory interface
 * @export
 */
export const SettingNotifyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingNotifyApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingNotificationControllerFindByUserId(options?: any): AxiosPromise<object> {
            return localVarFp.settingNotificationControllerFindByUserId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SettingNotifyDto} settingNotifyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingNotificationControllerUpSert(settingNotifyDto: SettingNotifyDto, options?: any): AxiosPromise<void> {
            return localVarFp.settingNotificationControllerUpSert(settingNotifyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingNotifyApi - object-oriented interface
 * @export
 * @class SettingNotifyApi
 * @extends {BaseAPI}
 */
export class SettingNotifyApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingNotifyApi
     */
    public settingNotificationControllerFindByUserId(options?: AxiosRequestConfig) {
        return SettingNotifyApiFp(this.configuration).settingNotificationControllerFindByUserId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SettingNotifyDto} settingNotifyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingNotifyApi
     */
    public settingNotificationControllerUpSert(settingNotifyDto: SettingNotifyDto, options?: AxiosRequestConfig) {
        return SettingNotifyApiFp(this.configuration).settingNotificationControllerUpSert(settingNotifyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SettingDto} settingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerCreate: async (settingDto: SettingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingDto' is not null or undefined
            assertParamExists('settingControllerCreate', 'settingDto', settingDto)
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerGetForUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SettingDto} settingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdate: async (settingDto: SettingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingDto' is not null or undefined
            assertParamExists('settingControllerUpdate', 'settingDto', settingDto)
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SettingDto} settingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerCreate(settingDto: SettingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerCreate(settingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerGetForUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerGetForUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SettingDto} settingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerUpdate(settingDto: SettingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerUpdate(settingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {SettingDto} settingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerCreate(settingDto: SettingDto, options?: any): AxiosPromise<UserSetting> {
            return localVarFp.settingControllerCreate(settingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerGetForUser(options?: any): AxiosPromise<UserSetting> {
            return localVarFp.settingControllerGetForUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SettingDto} settingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdate(settingDto: SettingDto, options?: any): AxiosPromise<object> {
            return localVarFp.settingControllerUpdate(settingDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * 
     * @param {SettingDto} settingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerCreate(settingDto: SettingDto, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerCreate(settingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerGetForUser(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerGetForUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SettingDto} settingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerUpdate(settingDto: SettingDto, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerUpdate(settingDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShipToBuyerApi - axios parameter creator
 * @export
 */
export const ShipToBuyerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {Array<string>} [providers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipToBuyerControllerFindAll: async (page?: number, size?: number, fullTextSearch?: string, providers?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ship-to-buyer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (providers) {
                localVarQueryParameter['providers'] = providers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateShipToBuyerDto} updateShipToBuyerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipToBuyerControllerUpdate: async (updateShipToBuyerDto: UpdateShipToBuyerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateShipToBuyerDto' is not null or undefined
            assertParamExists('shipToBuyerControllerUpdate', 'updateShipToBuyerDto', updateShipToBuyerDto)
            const localVarPath = `/ship-to-buyer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateShipToBuyerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShipToBuyerApi - functional programming interface
 * @export
 */
export const ShipToBuyerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShipToBuyerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {Array<string>} [providers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shipToBuyerControllerFindAll(page?: number, size?: number, fullTextSearch?: string, providers?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShipToBuyerControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shipToBuyerControllerFindAll(page, size, fullTextSearch, providers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateShipToBuyerDto} updateShipToBuyerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shipToBuyerControllerUpdate(updateShipToBuyerDto: UpdateShipToBuyerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shipToBuyerControllerUpdate(updateShipToBuyerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShipToBuyerApi - factory interface
 * @export
 */
export const ShipToBuyerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShipToBuyerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {Array<string>} [providers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipToBuyerControllerFindAll(page?: number, size?: number, fullTextSearch?: string, providers?: Array<string>, options?: any): AxiosPromise<ShipToBuyerControllerFindAll200Response> {
            return localVarFp.shipToBuyerControllerFindAll(page, size, fullTextSearch, providers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateShipToBuyerDto} updateShipToBuyerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipToBuyerControllerUpdate(updateShipToBuyerDto: UpdateShipToBuyerDto, options?: any): AxiosPromise<void> {
            return localVarFp.shipToBuyerControllerUpdate(updateShipToBuyerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShipToBuyerApi - object-oriented interface
 * @export
 * @class ShipToBuyerApi
 * @extends {BaseAPI}
 */
export class ShipToBuyerApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {Array<string>} [providers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipToBuyerApi
     */
    public shipToBuyerControllerFindAll(page?: number, size?: number, fullTextSearch?: string, providers?: Array<string>, options?: AxiosRequestConfig) {
        return ShipToBuyerApiFp(this.configuration).shipToBuyerControllerFindAll(page, size, fullTextSearch, providers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateShipToBuyerDto} updateShipToBuyerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipToBuyerApi
     */
    public shipToBuyerControllerUpdate(updateShipToBuyerDto: UpdateShipToBuyerDto, options?: AxiosRequestConfig) {
        return ShipToBuyerApiFp(this.configuration).shipToBuyerControllerUpdate(updateShipToBuyerDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShippoApiApi - axios parameter creator
 * @export
 */
export const ShippoApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRefunds} createRefunds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippoApiControllerCreateRefunds: async (createRefunds: CreateRefunds, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRefunds' is not null or undefined
            assertParamExists('shippoApiControllerCreateRefunds', 'createRefunds', createRefunds)
            const localVarPath = `/shippo-api/refunds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRefunds, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateShipmentDto} createShipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippoApiControllerCreateShipment: async (createShipmentDto: CreateShipmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createShipmentDto' is not null or undefined
            assertParamExists('shippoApiControllerCreateShipment', 'createShipmentDto', createShipmentDto)
            const localVarPath = `/shippo-api/shipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createShipmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateTransaction} createTransaction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippoApiControllerCreateTransaction: async (createTransaction: CreateTransaction, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTransaction' is not null or undefined
            assertParamExists('shippoApiControllerCreateTransaction', 'createTransaction', createTransaction)
            const localVarPath = `/shippo-api/transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTransaction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippoApiControllerFindRateById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('shippoApiControllerFindRateById', 'id', id)
            const localVarPath = `/shippo-api/find-rate-by-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddressDto} addressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippoApiControllerValidateAddress: async (addressDto: AddressDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressDto' is not null or undefined
            assertParamExists('shippoApiControllerValidateAddress', 'addressDto', addressDto)
            const localVarPath = `/shippo-api/validate-address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShippoApiApi - functional programming interface
 * @export
 */
export const ShippoApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShippoApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRefunds} createRefunds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippoApiControllerCreateRefunds(createRefunds: CreateRefunds, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippoApiControllerCreateRefunds(createRefunds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateShipmentDto} createShipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippoApiControllerCreateShipment(createShipmentDto: CreateShipmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateShipmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippoApiControllerCreateShipment(createShipmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateTransaction} createTransaction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippoApiControllerCreateTransaction(createTransaction: CreateTransaction, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippoApiControllerCreateTransaction(createTransaction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippoApiControllerFindRateById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippoApiControllerFindRateById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddressDto} addressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippoApiControllerValidateAddress(addressDto: AddressDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippoApiControllerValidateAddress(addressDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShippoApiApi - factory interface
 * @export
 */
export const ShippoApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShippoApiApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateRefunds} createRefunds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippoApiControllerCreateRefunds(createRefunds: CreateRefunds, options?: any): AxiosPromise<object> {
            return localVarFp.shippoApiControllerCreateRefunds(createRefunds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateShipmentDto} createShipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippoApiControllerCreateShipment(createShipmentDto: CreateShipmentDto, options?: any): AxiosPromise<CreateShipmentResponse> {
            return localVarFp.shippoApiControllerCreateShipment(createShipmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateTransaction} createTransaction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippoApiControllerCreateTransaction(createTransaction: CreateTransaction, options?: any): AxiosPromise<object> {
            return localVarFp.shippoApiControllerCreateTransaction(createTransaction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippoApiControllerFindRateById(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.shippoApiControllerFindRateById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddressDto} addressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippoApiControllerValidateAddress(addressDto: AddressDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.shippoApiControllerValidateAddress(addressDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShippoApiApi - object-oriented interface
 * @export
 * @class ShippoApiApi
 * @extends {BaseAPI}
 */
export class ShippoApiApi extends BaseAPI {
    /**
     * 
     * @param {CreateRefunds} createRefunds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippoApiApi
     */
    public shippoApiControllerCreateRefunds(createRefunds: CreateRefunds, options?: AxiosRequestConfig) {
        return ShippoApiApiFp(this.configuration).shippoApiControllerCreateRefunds(createRefunds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateShipmentDto} createShipmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippoApiApi
     */
    public shippoApiControllerCreateShipment(createShipmentDto: CreateShipmentDto, options?: AxiosRequestConfig) {
        return ShippoApiApiFp(this.configuration).shippoApiControllerCreateShipment(createShipmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateTransaction} createTransaction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippoApiApi
     */
    public shippoApiControllerCreateTransaction(createTransaction: CreateTransaction, options?: AxiosRequestConfig) {
        return ShippoApiApiFp(this.configuration).shippoApiControllerCreateTransaction(createTransaction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippoApiApi
     */
    public shippoApiControllerFindRateById(id: string, options?: AxiosRequestConfig) {
        return ShippoApiApiFp(this.configuration).shippoApiControllerFindRateById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddressDto} addressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippoApiApi
     */
    public shippoApiControllerValidateAddress(addressDto: AddressDto, options?: AxiosRequestConfig) {
        return ShippoApiApiFp(this.configuration).shippoApiControllerValidateAddress(addressDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShippoWebhookApi - axios parameter creator
 * @export
 */
export const ShippoWebhookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipporWebhookControllerCreate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shippo-webhook/transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipporWebhookControllerTrackUpdate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shippo-webhook/track-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShippoWebhookApi - functional programming interface
 * @export
 */
export const ShippoWebhookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShippoWebhookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shipporWebhookControllerCreate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shipporWebhookControllerCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shipporWebhookControllerTrackUpdate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shipporWebhookControllerTrackUpdate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShippoWebhookApi - factory interface
 * @export
 */
export const ShippoWebhookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShippoWebhookApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipporWebhookControllerCreate(options?: any): AxiosPromise<object> {
            return localVarFp.shipporWebhookControllerCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipporWebhookControllerTrackUpdate(options?: any): AxiosPromise<object> {
            return localVarFp.shipporWebhookControllerTrackUpdate(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShippoWebhookApi - object-oriented interface
 * @export
 * @class ShippoWebhookApi
 * @extends {BaseAPI}
 */
export class ShippoWebhookApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippoWebhookApi
     */
    public shipporWebhookControllerCreate(options?: AxiosRequestConfig) {
        return ShippoWebhookApiFp(this.configuration).shipporWebhookControllerCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippoWebhookApi
     */
    public shipporWebhookControllerTrackUpdate(options?: AxiosRequestConfig) {
        return ShippoWebhookApiFp(this.configuration).shipporWebhookControllerTrackUpdate(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StorageItemApi - axios parameter creator
 * @export
 */
export const StorageItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerCountForItemList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage-item/item-management/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerCountForItemListUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage-item/item-management/user-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerCountForRequestList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage-item/storage-management/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerGetOneItemManagementAdmin: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storageItemControllerGetOneItemManagementAdmin', 'id', id)
            const localVarPath = `/storage-item/item-management/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerGetOneItemManagementUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storageItemControllerGetOneItemManagementUser', 'id', id)
            const localVarPath = `/storage-item/item-management/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [status] 
         * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
         * @param {boolean} [checkbox] 
         * @param {number} [userEbayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerItemManagementAdmin: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage-item/item-management/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (tab !== undefined) {
                localVarQueryParameter['tab'] = tab;
            }

            if (checkbox !== undefined) {
                localVarQueryParameter['checkbox'] = checkbox;
            }

            if (userEbayId !== undefined) {
                localVarQueryParameter['userEbayId'] = userEbayId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [status] 
         * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
         * @param {boolean} [checkbox] 
         * @param {number} [userEbayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerItemManagementUser: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage-item/item-management/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (tab !== undefined) {
                localVarQueryParameter['tab'] = tab;
            }

            if (checkbox !== undefined) {
                localVarQueryParameter['checkbox'] = checkbox;
            }

            if (userEbayId !== undefined) {
                localVarQueryParameter['userEbayId'] = userEbayId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [status] 
         * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
         * @param {boolean} [checkbox] 
         * @param {number} [userEbayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerStorageManagementAdmin: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage-item/storage-management/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (tab !== undefined) {
                localVarQueryParameter['tab'] = tab;
            }

            if (checkbox !== undefined) {
                localVarQueryParameter['checkbox'] = checkbox;
            }

            if (userEbayId !== undefined) {
                localVarQueryParameter['userEbayId'] = userEbayId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [status] 
         * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
         * @param {boolean} [checkbox] 
         * @param {number} [userEbayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerStorageManagementOperation: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage-item/storage-management/operation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (tab !== undefined) {
                localVarQueryParameter['tab'] = tab;
            }

            if (checkbox !== undefined) {
                localVarQueryParameter['checkbox'] = checkbox;
            }

            if (userEbayId !== undefined) {
                localVarQueryParameter['userEbayId'] = userEbayId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [status] 
         * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
         * @param {boolean} [checkbox] 
         * @param {number} [userEbayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerStorageManagementStaff: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage-item/storage-management/staff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (tab !== undefined) {
                localVarQueryParameter['tab'] = tab;
            }

            if (checkbox !== undefined) {
                localVarQueryParameter['checkbox'] = checkbox;
            }

            if (userEbayId !== undefined) {
                localVarQueryParameter['userEbayId'] = userEbayId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerUpdateFlagRevise: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storageItemControllerUpdateFlagRevise', 'id', id)
            const localVarPath = `/storage-item/item-management/update-flag-revise/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageItemApi - functional programming interface
 * @export
 */
export const StorageItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageItemControllerCountForItemList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountForItemListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageItemControllerCountForItemList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageItemControllerCountForItemListUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountForItemListUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageItemControllerCountForItemListUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageItemControllerCountForRequestList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountForRequestListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageItemControllerCountForRequestList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageItemControllerGetOneItemManagementAdmin(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageItemControllerGetOneItemManagementAdmin(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageItemControllerGetOneItemManagementUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageItemControllerGetOneItemManagementUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [status] 
         * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
         * @param {boolean} [checkbox] 
         * @param {number} [userEbayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageItemControllerItemManagementAdmin(page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageItemControllerStorageManagementAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageItemControllerItemManagementAdmin(page, size, sort, fullTextSearch, from, to, status, tab, checkbox, userEbayId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [status] 
         * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
         * @param {boolean} [checkbox] 
         * @param {number} [userEbayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageItemControllerItemManagementUser(page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageItemControllerStorageManagementAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageItemControllerItemManagementUser(page, size, sort, fullTextSearch, from, to, status, tab, checkbox, userEbayId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [status] 
         * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
         * @param {boolean} [checkbox] 
         * @param {number} [userEbayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageItemControllerStorageManagementAdmin(page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageItemControllerStorageManagementAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageItemControllerStorageManagementAdmin(page, size, sort, fullTextSearch, from, to, status, tab, checkbox, userEbayId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [status] 
         * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
         * @param {boolean} [checkbox] 
         * @param {number} [userEbayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageItemControllerStorageManagementOperation(page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageItemControllerStorageManagementAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageItemControllerStorageManagementOperation(page, size, sort, fullTextSearch, from, to, status, tab, checkbox, userEbayId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [status] 
         * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
         * @param {boolean} [checkbox] 
         * @param {number} [userEbayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageItemControllerStorageManagementStaff(page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageItemControllerStorageManagementAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageItemControllerStorageManagementStaff(page, size, sort, fullTextSearch, from, to, status, tab, checkbox, userEbayId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageItemControllerUpdateFlagRevise(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountForItemListUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageItemControllerUpdateFlagRevise(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorageItemApi - factory interface
 * @export
 */
export const StorageItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageItemApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerCountForItemList(options?: any): AxiosPromise<CountForItemListResponse> {
            return localVarFp.storageItemControllerCountForItemList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerCountForItemListUser(options?: any): AxiosPromise<CountForItemListUserResponse> {
            return localVarFp.storageItemControllerCountForItemListUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerCountForRequestList(options?: any): AxiosPromise<CountForRequestListResponse> {
            return localVarFp.storageItemControllerCountForRequestList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerGetOneItemManagementAdmin(id: string, options?: any): AxiosPromise<StorageItem> {
            return localVarFp.storageItemControllerGetOneItemManagementAdmin(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerGetOneItemManagementUser(id: string, options?: any): AxiosPromise<StorageItem> {
            return localVarFp.storageItemControllerGetOneItemManagementUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [status] 
         * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
         * @param {boolean} [checkbox] 
         * @param {number} [userEbayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerItemManagementAdmin(page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options?: any): AxiosPromise<StorageItemControllerStorageManagementAdmin200Response> {
            return localVarFp.storageItemControllerItemManagementAdmin(page, size, sort, fullTextSearch, from, to, status, tab, checkbox, userEbayId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [status] 
         * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
         * @param {boolean} [checkbox] 
         * @param {number} [userEbayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerItemManagementUser(page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options?: any): AxiosPromise<StorageItemControllerStorageManagementAdmin200Response> {
            return localVarFp.storageItemControllerItemManagementUser(page, size, sort, fullTextSearch, from, to, status, tab, checkbox, userEbayId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [status] 
         * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
         * @param {boolean} [checkbox] 
         * @param {number} [userEbayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerStorageManagementAdmin(page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options?: any): AxiosPromise<StorageItemControllerStorageManagementAdmin200Response> {
            return localVarFp.storageItemControllerStorageManagementAdmin(page, size, sort, fullTextSearch, from, to, status, tab, checkbox, userEbayId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [status] 
         * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
         * @param {boolean} [checkbox] 
         * @param {number} [userEbayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerStorageManagementOperation(page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options?: any): AxiosPromise<StorageItemControllerStorageManagementAdmin200Response> {
            return localVarFp.storageItemControllerStorageManagementOperation(page, size, sort, fullTextSearch, from, to, status, tab, checkbox, userEbayId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<string>} [status] 
         * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
         * @param {boolean} [checkbox] 
         * @param {number} [userEbayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerStorageManagementStaff(page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options?: any): AxiosPromise<StorageItemControllerStorageManagementAdmin200Response> {
            return localVarFp.storageItemControllerStorageManagementStaff(page, size, sort, fullTextSearch, from, to, status, tab, checkbox, userEbayId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemControllerUpdateFlagRevise(id: number, options?: any): AxiosPromise<CountForItemListUserResponse> {
            return localVarFp.storageItemControllerUpdateFlagRevise(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StorageItemApi - object-oriented interface
 * @export
 * @class StorageItemApi
 * @extends {BaseAPI}
 */
export class StorageItemApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageItemApi
     */
    public storageItemControllerCountForItemList(options?: AxiosRequestConfig) {
        return StorageItemApiFp(this.configuration).storageItemControllerCountForItemList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageItemApi
     */
    public storageItemControllerCountForItemListUser(options?: AxiosRequestConfig) {
        return StorageItemApiFp(this.configuration).storageItemControllerCountForItemListUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageItemApi
     */
    public storageItemControllerCountForRequestList(options?: AxiosRequestConfig) {
        return StorageItemApiFp(this.configuration).storageItemControllerCountForRequestList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageItemApi
     */
    public storageItemControllerGetOneItemManagementAdmin(id: string, options?: AxiosRequestConfig) {
        return StorageItemApiFp(this.configuration).storageItemControllerGetOneItemManagementAdmin(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageItemApi
     */
    public storageItemControllerGetOneItemManagementUser(id: string, options?: AxiosRequestConfig) {
        return StorageItemApiFp(this.configuration).storageItemControllerGetOneItemManagementUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {Array<string>} [status] 
     * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
     * @param {boolean} [checkbox] 
     * @param {number} [userEbayId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageItemApi
     */
    public storageItemControllerItemManagementAdmin(page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options?: AxiosRequestConfig) {
        return StorageItemApiFp(this.configuration).storageItemControllerItemManagementAdmin(page, size, sort, fullTextSearch, from, to, status, tab, checkbox, userEbayId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {Array<string>} [status] 
     * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
     * @param {boolean} [checkbox] 
     * @param {number} [userEbayId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageItemApi
     */
    public storageItemControllerItemManagementUser(page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options?: AxiosRequestConfig) {
        return StorageItemApiFp(this.configuration).storageItemControllerItemManagementUser(page, size, sort, fullTextSearch, from, to, status, tab, checkbox, userEbayId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {Array<string>} [status] 
     * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
     * @param {boolean} [checkbox] 
     * @param {number} [userEbayId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageItemApi
     */
    public storageItemControllerStorageManagementAdmin(page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options?: AxiosRequestConfig) {
        return StorageItemApiFp(this.configuration).storageItemControllerStorageManagementAdmin(page, size, sort, fullTextSearch, from, to, status, tab, checkbox, userEbayId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {Array<string>} [status] 
     * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
     * @param {boolean} [checkbox] 
     * @param {number} [userEbayId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageItemApi
     */
    public storageItemControllerStorageManagementOperation(page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options?: AxiosRequestConfig) {
        return StorageItemApiFp(this.configuration).storageItemControllerStorageManagementOperation(page, size, sort, fullTextSearch, from, to, status, tab, checkbox, userEbayId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {Array<string>} [status] 
     * @param {'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE'} [tab] 
     * @param {boolean} [checkbox] 
     * @param {number} [userEbayId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageItemApi
     */
    public storageItemControllerStorageManagementStaff(page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, status?: Array<string>, tab?: 'STOCK' | 'SHIP' | 'PHOTO_INIT' | 'PHOTO_REQUEST' | 'DISPOSAL' | 'QUESTION' | 'CHAT' | 'ALL' | 'NOT_SHIPPED' | 'IN_TRANSIT' | 'WAITING_STOCK' | 'IN_STOCK' | 'ITEM_SHIP' | 'ITEM_DISPOSAL' | 'COMPLETE', checkbox?: boolean, userEbayId?: number, options?: AxiosRequestConfig) {
        return StorageItemApiFp(this.configuration).storageItemControllerStorageManagementStaff(page, size, sort, fullTextSearch, from, to, status, tab, checkbox, userEbayId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageItemApi
     */
    public storageItemControllerUpdateFlagRevise(id: number, options?: AxiosRequestConfig) {
        return StorageItemApiFp(this.configuration).storageItemControllerUpdateFlagRevise(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StorageItemNotesApi - axios parameter creator
 * @export
 */
export const StorageItemNotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateStorageItemNoteDto} createStorageItemNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemNoteControllerCreate: async (createStorageItemNoteDto: CreateStorageItemNoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createStorageItemNoteDto' is not null or undefined
            assertParamExists('storageItemNoteControllerCreate', 'createStorageItemNoteDto', createStorageItemNoteDto)
            const localVarPath = `/storage-item-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createStorageItemNoteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemNoteControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage-item-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemNoteControllerFindAllByItemStorageId: async (id: string, page?: number, size?: number, sort?: string, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storageItemNoteControllerFindAllByItemStorageId', 'id', id)
            const localVarPath = `/storage-item-notes/get-by-item-storage-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemNoteControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storageItemNoteControllerFindOne', 'id', id)
            const localVarPath = `/storage-item-notes/get-by-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemNoteControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storageItemNoteControllerRemove', 'id', id)
            const localVarPath = `/storage-item-notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateStorageItemNoteDto} updateStorageItemNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemNoteControllerUpdate: async (id: string, updateStorageItemNoteDto: UpdateStorageItemNoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storageItemNoteControllerUpdate', 'id', id)
            // verify required parameter 'updateStorageItemNoteDto' is not null or undefined
            assertParamExists('storageItemNoteControllerUpdate', 'updateStorageItemNoteDto', updateStorageItemNoteDto)
            const localVarPath = `/storage-item-notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStorageItemNoteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageItemNotesApi - functional programming interface
 * @export
 */
export const StorageItemNotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageItemNotesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateStorageItemNoteDto} createStorageItemNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageItemNoteControllerCreate(createStorageItemNoteDto: CreateStorageItemNoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageItemNote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageItemNoteControllerCreate(createStorageItemNoteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageItemNoteControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StorageItemNote>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageItemNoteControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageItemNoteControllerFindAllByItemStorageId(id: string, page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageItemNoteControllerFindAllByItemStorageId200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageItemNoteControllerFindAllByItemStorageId(id, page, size, sort, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageItemNoteControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageItemNote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageItemNoteControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageItemNoteControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageItemNoteControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateStorageItemNoteDto} updateStorageItemNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageItemNoteControllerUpdate(id: string, updateStorageItemNoteDto: UpdateStorageItemNoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageItemNote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageItemNoteControllerUpdate(id, updateStorageItemNoteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorageItemNotesApi - factory interface
 * @export
 */
export const StorageItemNotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageItemNotesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateStorageItemNoteDto} createStorageItemNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemNoteControllerCreate(createStorageItemNoteDto: CreateStorageItemNoteDto, options?: any): AxiosPromise<StorageItemNote> {
            return localVarFp.storageItemNoteControllerCreate(createStorageItemNoteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemNoteControllerFindAll(options?: any): AxiosPromise<Array<StorageItemNote>> {
            return localVarFp.storageItemNoteControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemNoteControllerFindAllByItemStorageId(id: string, page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: any): AxiosPromise<StorageItemNoteControllerFindAllByItemStorageId200Response> {
            return localVarFp.storageItemNoteControllerFindAllByItemStorageId(id, page, size, sort, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemNoteControllerFindOne(id: string, options?: any): AxiosPromise<StorageItemNote> {
            return localVarFp.storageItemNoteControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemNoteControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.storageItemNoteControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateStorageItemNoteDto} updateStorageItemNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageItemNoteControllerUpdate(id: string, updateStorageItemNoteDto: UpdateStorageItemNoteDto, options?: any): AxiosPromise<StorageItemNote> {
            return localVarFp.storageItemNoteControllerUpdate(id, updateStorageItemNoteDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StorageItemNotesApi - object-oriented interface
 * @export
 * @class StorageItemNotesApi
 * @extends {BaseAPI}
 */
export class StorageItemNotesApi extends BaseAPI {
    /**
     * 
     * @param {CreateStorageItemNoteDto} createStorageItemNoteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageItemNotesApi
     */
    public storageItemNoteControllerCreate(createStorageItemNoteDto: CreateStorageItemNoteDto, options?: AxiosRequestConfig) {
        return StorageItemNotesApiFp(this.configuration).storageItemNoteControllerCreate(createStorageItemNoteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageItemNotesApi
     */
    public storageItemNoteControllerFindAll(options?: AxiosRequestConfig) {
        return StorageItemNotesApiFp(this.configuration).storageItemNoteControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageItemNotesApi
     */
    public storageItemNoteControllerFindAllByItemStorageId(id: string, page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return StorageItemNotesApiFp(this.configuration).storageItemNoteControllerFindAllByItemStorageId(id, page, size, sort, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageItemNotesApi
     */
    public storageItemNoteControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return StorageItemNotesApiFp(this.configuration).storageItemNoteControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageItemNotesApi
     */
    public storageItemNoteControllerRemove(id: string, options?: AxiosRequestConfig) {
        return StorageItemNotesApiFp(this.configuration).storageItemNoteControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateStorageItemNoteDto} updateStorageItemNoteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageItemNotesApi
     */
    public storageItemNoteControllerUpdate(id: string, updateStorageItemNoteDto: UpdateStorageItemNoteDto, options?: AxiosRequestConfig) {
        return StorageItemNotesApiFp(this.configuration).storageItemNoteControllerUpdate(id, updateStorageItemNoteDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StorageProcessApi - axios parameter creator
 * @export
 */
export const StorageProcessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UpdateDisposalRequestDto} updateDisposalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerCancelDisposalRequest: async (updateDisposalRequestDto: UpdateDisposalRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDisposalRequestDto' is not null or undefined
            assertParamExists('storageProcessControllerCancelDisposalRequest', 'updateDisposalRequestDto', updateDisposalRequestDto)
            const localVarPath = `/storage-process/user/cancel-disposal-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDisposalRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CancelShipToBuyerDto} cancelShipToBuyerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerCancelShipToBuyerRequest: async (cancelShipToBuyerDto: CancelShipToBuyerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cancelShipToBuyerDto' is not null or undefined
            assertParamExists('storageProcessControllerCancelShipToBuyerRequest', 'cancelShipToBuyerDto', cancelShipToBuyerDto)
            const localVarPath = `/storage-process/user/cancel-ship-to-buyer-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelShipToBuyerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CancelStorageRequest} cancelStorageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerCancelStorageRequest: async (cancelStorageRequest: CancelStorageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cancelStorageRequest' is not null or undefined
            assertParamExists('storageProcessControllerCancelStorageRequest', 'cancelStorageRequest', cancelStorageRequest)
            const localVarPath = `/storage-process/cancel-storage-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelStorageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterStorageDto} registerStorageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerCheckRegisterStorage: async (registerStorageDto: RegisterStorageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerStorageDto' is not null or undefined
            assertParamExists('storageProcessControllerCheckRegisterStorage', 'registerStorageDto', registerStorageDto)
            const localVarPath = `/storage-process/operation/check-register-storage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerStorageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePreparingDoneDto} updatePreparingDoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerCheckSetPreparingDone: async (updatePreparingDoneDto: UpdatePreparingDoneDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePreparingDoneDto' is not null or undefined
            assertParamExists('storageProcessControllerCheckSetPreparingDone', 'updatePreparingDoneDto', updatePreparingDoneDto)
            const localVarPath = `/storage-process/operation/check-preparing-done`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePreparingDoneDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDisposalRequestDto} createDisposalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerCreateDisposalRequest: async (createDisposalRequestDto: CreateDisposalRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDisposalRequestDto' is not null or undefined
            assertParamExists('storageProcessControllerCreateDisposalRequest', 'createDisposalRequestDto', createDisposalRequestDto)
            const localVarPath = `/storage-process/user/create-disposal-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDisposalRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PhotoRequestDto} photoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerCreatePhotoRequest: async (photoRequestDto: PhotoRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'photoRequestDto' is not null or undefined
            assertParamExists('storageProcessControllerCreatePhotoRequest', 'photoRequestDto', photoRequestDto)
            const localVarPath = `/storage-process/user/create-photo-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(photoRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateShipToBuyerDto} createShipToBuyerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerCreateShipToBuyerRequest: async (createShipToBuyerDto: CreateShipToBuyerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createShipToBuyerDto' is not null or undefined
            assertParamExists('storageProcessControllerCreateShipToBuyerRequest', 'createShipToBuyerDto', createShipToBuyerDto)
            const localVarPath = `/storage-process/user/create-ship-to-buyer-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createShipToBuyerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDisposalRequestDto} updateDisposalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerDisposalItem: async (updateDisposalRequestDto: UpdateDisposalRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDisposalRequestDto' is not null or undefined
            assertParamExists('storageProcessControllerDisposalItem', 'updateDisposalRequestDto', updateDisposalRequestDto)
            const localVarPath = `/storage-process/operation/disposal-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDisposalRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadStorageVolumeDto} uploadStorageVolumeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerEditStorageVolume: async (uploadStorageVolumeDto: UploadStorageVolumeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadStorageVolumeDto' is not null or undefined
            assertParamExists('storageProcessControllerEditStorageVolume', 'uploadStorageVolumeDto', uploadStorageVolumeDto)
            const localVarPath = `/storage-process/operation/edit-storage-volume`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadStorageVolumeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterStorageDto} registerStorageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerRegisterStorage: async (registerStorageDto: RegisterStorageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerStorageDto' is not null or undefined
            assertParamExists('storageProcessControllerRegisterStorage', 'registerStorageDto', registerStorageDto)
            const localVarPath = `/storage-process/operation/register-storage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerStorageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateShipToBuyerDto} updateShipToBuyerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerSetShipToBuyMarkShipDto: async (updateShipToBuyerDto: UpdateShipToBuyerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateShipToBuyerDto' is not null or undefined
            assertParamExists('storageProcessControllerSetShipToBuyMarkShipDto', 'updateShipToBuyerDto', updateShipToBuyerDto)
            const localVarPath = `/storage-process/operation/mark-shipped`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateShipToBuyerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePreparingDoneDto} updatePreparingDoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerSetShipToBuyPreparingDone: async (updatePreparingDoneDto: UpdatePreparingDoneDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePreparingDoneDto' is not null or undefined
            assertParamExists('storageProcessControllerSetShipToBuyPreparingDone', 'updatePreparingDoneDto', updatePreparingDoneDto)
            const localVarPath = `/storage-process/operation/preparing-done`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePreparingDoneDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateStorageItemStatus} updateStorageItemStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerSetStorageStatus: async (updateStorageItemStatus: UpdateStorageItemStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateStorageItemStatus' is not null or undefined
            assertParamExists('storageProcessControllerSetStorageStatus', 'updateStorageItemStatus', updateStorageItemStatus)
            const localVarPath = `/storage-process/operation/set-state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStorageItemStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTakePhotos} updateTakePhotos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerTakePhotoRequest: async (updateTakePhotos: UpdateTakePhotos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTakePhotos' is not null or undefined
            assertParamExists('storageProcessControllerTakePhotoRequest', 'updateTakePhotos', updateTakePhotos)
            const localVarPath = `/storage-process/operation/take`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTakePhotos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerTestWriteLog: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage-process/operation/test-write-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TranslatePhotoRequest} translatePhotoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerTranslatePhotoRequest: async (translatePhotoRequest: TranslatePhotoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'translatePhotoRequest' is not null or undefined
            assertParamExists('storageProcessControllerTranslatePhotoRequest', 'translatePhotoRequest', translatePhotoRequest)
            const localVarPath = `/storage-process/operation/translate-photo-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translatePhotoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDisposalRequestDto} updateDisposalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerUpdateDisposalPrice: async (updateDisposalRequestDto: UpdateDisposalRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDisposalRequestDto' is not null or undefined
            assertParamExists('storageProcessControllerUpdateDisposalPrice', 'updateDisposalRequestDto', updateDisposalRequestDto)
            const localVarPath = `/storage-process/staff/update-disposal-price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDisposalRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadPhotoAndVideoDto} uploadPhotoAndVideoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerUpdatePhotoItem: async (uploadPhotoAndVideoDto: UploadPhotoAndVideoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadPhotoAndVideoDto' is not null or undefined
            assertParamExists('storageProcessControllerUpdatePhotoItem', 'uploadPhotoAndVideoDto', uploadPhotoAndVideoDto)
            const localVarPath = `/storage-process/staff/upload-photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadPhotoAndVideoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateStorageItemPhotos} updateStorageItemPhotos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerUpdatePhotoRequest: async (updateStorageItemPhotos: UpdateStorageItemPhotos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateStorageItemPhotos' is not null or undefined
            assertParamExists('storageProcessControllerUpdatePhotoRequest', 'updateStorageItemPhotos', updateStorageItemPhotos)
            const localVarPath = `/storage-process/staff/upload-photo-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStorageItemPhotos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTrackingNumber} updateTrackingNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerUpdateTrackingNumber: async (updateTrackingNumber: UpdateTrackingNumber, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTrackingNumber' is not null or undefined
            assertParamExists('storageProcessControllerUpdateTrackingNumber', 'updateTrackingNumber', updateTrackingNumber)
            const localVarPath = `/storage-process/user/update-tracking-number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTrackingNumber, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadRealVolumeDto} uploadRealVolumeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerUploadRealVolume: async (uploadRealVolumeDto: UploadRealVolumeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadRealVolumeDto' is not null or undefined
            assertParamExists('storageProcessControllerUploadRealVolume', 'uploadRealVolumeDto', uploadRealVolumeDto)
            const localVarPath = `/storage-process/operation/upload-real-volume`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadRealVolumeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadStorageVolumeDto} uploadStorageVolumeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerUploadStorageVolume: async (uploadStorageVolumeDto: UploadStorageVolumeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadStorageVolumeDto' is not null or undefined
            assertParamExists('storageProcessControllerUploadStorageVolume', 'uploadStorageVolumeDto', uploadStorageVolumeDto)
            const localVarPath = `/storage-process/operation/upload-storage-volume`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadStorageVolumeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageProcessApi - functional programming interface
 * @export
 */
export const StorageProcessApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageProcessApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UpdateDisposalRequestDto} updateDisposalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerCancelDisposalRequest(updateDisposalRequestDto: UpdateDisposalRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerCancelDisposalRequest(updateDisposalRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CancelShipToBuyerDto} cancelShipToBuyerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerCancelShipToBuyerRequest(cancelShipToBuyerDto: CancelShipToBuyerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerCancelShipToBuyerRequest(cancelShipToBuyerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CancelStorageRequest} cancelStorageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerCancelStorageRequest(cancelStorageRequest: CancelStorageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerCancelStorageRequest(cancelStorageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterStorageDto} registerStorageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerCheckRegisterStorage(registerStorageDto: RegisterStorageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerCheckRegisterStorage(registerStorageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdatePreparingDoneDto} updatePreparingDoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerCheckSetPreparingDone(updatePreparingDoneDto: UpdatePreparingDoneDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerCheckSetPreparingDone(updatePreparingDoneDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateDisposalRequestDto} createDisposalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerCreateDisposalRequest(createDisposalRequestDto: CreateDisposalRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DisposalRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerCreateDisposalRequest(createDisposalRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PhotoRequestDto} photoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerCreatePhotoRequest(photoRequestDto: PhotoRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TakePhotoRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerCreatePhotoRequest(photoRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateShipToBuyerDto} createShipToBuyerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerCreateShipToBuyerRequest(createShipToBuyerDto: CreateShipToBuyerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerCreateShipToBuyerRequest(createShipToBuyerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDisposalRequestDto} updateDisposalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerDisposalItem(updateDisposalRequestDto: UpdateDisposalRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerDisposalItem(updateDisposalRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UploadStorageVolumeDto} uploadStorageVolumeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerEditStorageVolume(uploadStorageVolumeDto: UploadStorageVolumeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerEditStorageVolume(uploadStorageVolumeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterStorageDto} registerStorageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerRegisterStorage(registerStorageDto: RegisterStorageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerRegisterStorage(registerStorageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateShipToBuyerDto} updateShipToBuyerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerSetShipToBuyMarkShipDto(updateShipToBuyerDto: UpdateShipToBuyerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerSetShipToBuyMarkShipDto(updateShipToBuyerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdatePreparingDoneDto} updatePreparingDoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerSetShipToBuyPreparingDone(updatePreparingDoneDto: UpdatePreparingDoneDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerSetShipToBuyPreparingDone(updatePreparingDoneDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateStorageItemStatus} updateStorageItemStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerSetStorageStatus(updateStorageItemStatus: UpdateStorageItemStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerSetStorageStatus(updateStorageItemStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateTakePhotos} updateTakePhotos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerTakePhotoRequest(updateTakePhotos: UpdateTakePhotos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerTakePhotoRequest(updateTakePhotos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerTestWriteLog(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerTestWriteLog(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TranslatePhotoRequest} translatePhotoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerTranslatePhotoRequest(translatePhotoRequest: TranslatePhotoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerTranslatePhotoRequest(translatePhotoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDisposalRequestDto} updateDisposalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerUpdateDisposalPrice(updateDisposalRequestDto: UpdateDisposalRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerUpdateDisposalPrice(updateDisposalRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UploadPhotoAndVideoDto} uploadPhotoAndVideoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerUpdatePhotoItem(uploadPhotoAndVideoDto: UploadPhotoAndVideoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerUpdatePhotoItem(uploadPhotoAndVideoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateStorageItemPhotos} updateStorageItemPhotos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerUpdatePhotoRequest(updateStorageItemPhotos: UpdateStorageItemPhotos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerUpdatePhotoRequest(updateStorageItemPhotos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateTrackingNumber} updateTrackingNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerUpdateTrackingNumber(updateTrackingNumber: UpdateTrackingNumber, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerUpdateTrackingNumber(updateTrackingNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UploadRealVolumeDto} uploadRealVolumeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerUploadRealVolume(uploadRealVolumeDto: UploadRealVolumeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerUploadRealVolume(uploadRealVolumeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UploadStorageVolumeDto} uploadStorageVolumeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageProcessControllerUploadStorageVolume(uploadStorageVolumeDto: UploadStorageVolumeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageProcessControllerUploadStorageVolume(uploadStorageVolumeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorageProcessApi - factory interface
 * @export
 */
export const StorageProcessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageProcessApiFp(configuration)
    return {
        /**
         * 
         * @param {UpdateDisposalRequestDto} updateDisposalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerCancelDisposalRequest(updateDisposalRequestDto: UpdateDisposalRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerCancelDisposalRequest(updateDisposalRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CancelShipToBuyerDto} cancelShipToBuyerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerCancelShipToBuyerRequest(cancelShipToBuyerDto: CancelShipToBuyerDto, options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerCancelShipToBuyerRequest(cancelShipToBuyerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CancelStorageRequest} cancelStorageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerCancelStorageRequest(cancelStorageRequest: CancelStorageRequest, options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerCancelStorageRequest(cancelStorageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterStorageDto} registerStorageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerCheckRegisterStorage(registerStorageDto: RegisterStorageDto, options?: any): AxiosPromise<object> {
            return localVarFp.storageProcessControllerCheckRegisterStorage(registerStorageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePreparingDoneDto} updatePreparingDoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerCheckSetPreparingDone(updatePreparingDoneDto: UpdatePreparingDoneDto, options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerCheckSetPreparingDone(updatePreparingDoneDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateDisposalRequestDto} createDisposalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerCreateDisposalRequest(createDisposalRequestDto: CreateDisposalRequestDto, options?: any): AxiosPromise<DisposalRequest> {
            return localVarFp.storageProcessControllerCreateDisposalRequest(createDisposalRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PhotoRequestDto} photoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerCreatePhotoRequest(photoRequestDto: PhotoRequestDto, options?: any): AxiosPromise<TakePhotoRequest> {
            return localVarFp.storageProcessControllerCreatePhotoRequest(photoRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateShipToBuyerDto} createShipToBuyerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerCreateShipToBuyerRequest(createShipToBuyerDto: CreateShipToBuyerDto, options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerCreateShipToBuyerRequest(createShipToBuyerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDisposalRequestDto} updateDisposalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerDisposalItem(updateDisposalRequestDto: UpdateDisposalRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerDisposalItem(updateDisposalRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadStorageVolumeDto} uploadStorageVolumeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerEditStorageVolume(uploadStorageVolumeDto: UploadStorageVolumeDto, options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerEditStorageVolume(uploadStorageVolumeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterStorageDto} registerStorageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerRegisterStorage(registerStorageDto: RegisterStorageDto, options?: any): AxiosPromise<object> {
            return localVarFp.storageProcessControllerRegisterStorage(registerStorageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateShipToBuyerDto} updateShipToBuyerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerSetShipToBuyMarkShipDto(updateShipToBuyerDto: UpdateShipToBuyerDto, options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerSetShipToBuyMarkShipDto(updateShipToBuyerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePreparingDoneDto} updatePreparingDoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerSetShipToBuyPreparingDone(updatePreparingDoneDto: UpdatePreparingDoneDto, options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerSetShipToBuyPreparingDone(updatePreparingDoneDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateStorageItemStatus} updateStorageItemStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerSetStorageStatus(updateStorageItemStatus: UpdateStorageItemStatus, options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerSetStorageStatus(updateStorageItemStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateTakePhotos} updateTakePhotos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerTakePhotoRequest(updateTakePhotos: UpdateTakePhotos, options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerTakePhotoRequest(updateTakePhotos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerTestWriteLog(options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerTestWriteLog(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TranslatePhotoRequest} translatePhotoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerTranslatePhotoRequest(translatePhotoRequest: TranslatePhotoRequest, options?: any): AxiosPromise<string> {
            return localVarFp.storageProcessControllerTranslatePhotoRequest(translatePhotoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDisposalRequestDto} updateDisposalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerUpdateDisposalPrice(updateDisposalRequestDto: UpdateDisposalRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerUpdateDisposalPrice(updateDisposalRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadPhotoAndVideoDto} uploadPhotoAndVideoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerUpdatePhotoItem(uploadPhotoAndVideoDto: UploadPhotoAndVideoDto, options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerUpdatePhotoItem(uploadPhotoAndVideoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateStorageItemPhotos} updateStorageItemPhotos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerUpdatePhotoRequest(updateStorageItemPhotos: UpdateStorageItemPhotos, options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerUpdatePhotoRequest(updateStorageItemPhotos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateTrackingNumber} updateTrackingNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerUpdateTrackingNumber(updateTrackingNumber: UpdateTrackingNumber, options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerUpdateTrackingNumber(updateTrackingNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadRealVolumeDto} uploadRealVolumeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerUploadRealVolume(uploadRealVolumeDto: UploadRealVolumeDto, options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerUploadRealVolume(uploadRealVolumeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadStorageVolumeDto} uploadStorageVolumeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageProcessControllerUploadStorageVolume(uploadStorageVolumeDto: UploadStorageVolumeDto, options?: any): AxiosPromise<void> {
            return localVarFp.storageProcessControllerUploadStorageVolume(uploadStorageVolumeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StorageProcessApi - object-oriented interface
 * @export
 * @class StorageProcessApi
 * @extends {BaseAPI}
 */
export class StorageProcessApi extends BaseAPI {
    /**
     * 
     * @param {UpdateDisposalRequestDto} updateDisposalRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerCancelDisposalRequest(updateDisposalRequestDto: UpdateDisposalRequestDto, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerCancelDisposalRequest(updateDisposalRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CancelShipToBuyerDto} cancelShipToBuyerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerCancelShipToBuyerRequest(cancelShipToBuyerDto: CancelShipToBuyerDto, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerCancelShipToBuyerRequest(cancelShipToBuyerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CancelStorageRequest} cancelStorageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerCancelStorageRequest(cancelStorageRequest: CancelStorageRequest, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerCancelStorageRequest(cancelStorageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterStorageDto} registerStorageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerCheckRegisterStorage(registerStorageDto: RegisterStorageDto, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerCheckRegisterStorage(registerStorageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePreparingDoneDto} updatePreparingDoneDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerCheckSetPreparingDone(updatePreparingDoneDto: UpdatePreparingDoneDto, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerCheckSetPreparingDone(updatePreparingDoneDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateDisposalRequestDto} createDisposalRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerCreateDisposalRequest(createDisposalRequestDto: CreateDisposalRequestDto, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerCreateDisposalRequest(createDisposalRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PhotoRequestDto} photoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerCreatePhotoRequest(photoRequestDto: PhotoRequestDto, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerCreatePhotoRequest(photoRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateShipToBuyerDto} createShipToBuyerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerCreateShipToBuyerRequest(createShipToBuyerDto: CreateShipToBuyerDto, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerCreateShipToBuyerRequest(createShipToBuyerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDisposalRequestDto} updateDisposalRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerDisposalItem(updateDisposalRequestDto: UpdateDisposalRequestDto, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerDisposalItem(updateDisposalRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UploadStorageVolumeDto} uploadStorageVolumeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerEditStorageVolume(uploadStorageVolumeDto: UploadStorageVolumeDto, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerEditStorageVolume(uploadStorageVolumeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterStorageDto} registerStorageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerRegisterStorage(registerStorageDto: RegisterStorageDto, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerRegisterStorage(registerStorageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateShipToBuyerDto} updateShipToBuyerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerSetShipToBuyMarkShipDto(updateShipToBuyerDto: UpdateShipToBuyerDto, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerSetShipToBuyMarkShipDto(updateShipToBuyerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePreparingDoneDto} updatePreparingDoneDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerSetShipToBuyPreparingDone(updatePreparingDoneDto: UpdatePreparingDoneDto, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerSetShipToBuyPreparingDone(updatePreparingDoneDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateStorageItemStatus} updateStorageItemStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerSetStorageStatus(updateStorageItemStatus: UpdateStorageItemStatus, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerSetStorageStatus(updateStorageItemStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateTakePhotos} updateTakePhotos 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerTakePhotoRequest(updateTakePhotos: UpdateTakePhotos, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerTakePhotoRequest(updateTakePhotos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerTestWriteLog(options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerTestWriteLog(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TranslatePhotoRequest} translatePhotoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerTranslatePhotoRequest(translatePhotoRequest: TranslatePhotoRequest, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerTranslatePhotoRequest(translatePhotoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDisposalRequestDto} updateDisposalRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerUpdateDisposalPrice(updateDisposalRequestDto: UpdateDisposalRequestDto, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerUpdateDisposalPrice(updateDisposalRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UploadPhotoAndVideoDto} uploadPhotoAndVideoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerUpdatePhotoItem(uploadPhotoAndVideoDto: UploadPhotoAndVideoDto, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerUpdatePhotoItem(uploadPhotoAndVideoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateStorageItemPhotos} updateStorageItemPhotos 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerUpdatePhotoRequest(updateStorageItemPhotos: UpdateStorageItemPhotos, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerUpdatePhotoRequest(updateStorageItemPhotos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateTrackingNumber} updateTrackingNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerUpdateTrackingNumber(updateTrackingNumber: UpdateTrackingNumber, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerUpdateTrackingNumber(updateTrackingNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UploadRealVolumeDto} uploadRealVolumeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerUploadRealVolume(uploadRealVolumeDto: UploadRealVolumeDto, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerUploadRealVolume(uploadRealVolumeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UploadStorageVolumeDto} uploadStorageVolumeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageProcessApi
     */
    public storageProcessControllerUploadStorageVolume(uploadStorageVolumeDto: UploadStorageVolumeDto, options?: AxiosRequestConfig) {
        return StorageProcessApiFp(this.configuration).storageProcessControllerUploadStorageVolume(uploadStorageVolumeDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StorageRequestApi - axios parameter creator
 * @export
 */
export const StorageRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddJobStorageRequestToQueueDto} addJobStorageRequestToQueueDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageRequestControllerAddJobStorageRequestToQueue: async (addJobStorageRequestToQueueDto: AddJobStorageRequestToQueueDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addJobStorageRequestToQueueDto' is not null or undefined
            assertParamExists('storageRequestControllerAddJobStorageRequestToQueue', 'addJobStorageRequestToQueueDto', addJobStorageRequestToQueueDto)
            const localVarPath = `/storage-request/admin/add-job-storage-request-to-queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addJobStorageRequestToQueueDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateStorageRequestDto} createStorageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageRequestControllerCreateStorageRequestAdmin: async (createStorageRequestDto: CreateStorageRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createStorageRequestDto' is not null or undefined
            assertParamExists('storageRequestControllerCreateStorageRequestAdmin', 'createStorageRequestDto', createStorageRequestDto)
            const localVarPath = `/storage-request/admin/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createStorageRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StorageItemDto} storageItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageRequestControllerCreateStorageRequestExtraFee: async (storageItemDto: StorageItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storageItemDto' is not null or undefined
            assertParamExists('storageRequestControllerCreateStorageRequestExtraFee', 'storageItemDto', storageItemDto)
            const localVarPath = `/storage-request/extra-fee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storageItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateStorageRequestDto} createStorageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageRequestControllerCreateStorageRequestUser: async (createStorageRequestDto: CreateStorageRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createStorageRequestDto' is not null or undefined
            assertParamExists('storageRequestControllerCreateStorageRequestUser', 'createStorageRequestDto', createStorageRequestDto)
            const localVarPath = `/storage-request/user/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createStorageRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageRequestControllerGetAll: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageRequestApi - functional programming interface
 * @export
 */
export const StorageRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddJobStorageRequestToQueueDto} addJobStorageRequestToQueueDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageRequestControllerAddJobStorageRequestToQueue(addJobStorageRequestToQueueDto: AddJobStorageRequestToQueueDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageRequestControllerAddJobStorageRequestToQueue(addJobStorageRequestToQueueDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateStorageRequestDto} createStorageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageRequestControllerCreateStorageRequestAdmin(createStorageRequestDto: CreateStorageRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageRequestControllerCreateStorageRequestAdmin(createStorageRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StorageItemDto} storageItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageRequestControllerCreateStorageRequestExtraFee(storageItemDto: StorageItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateStorageRequestExtraFeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageRequestControllerCreateStorageRequestExtraFee(storageItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateStorageRequestDto} createStorageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageRequestControllerCreateStorageRequestUser(createStorageRequestDto: CreateStorageRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageRequestControllerCreateStorageRequestUser(createStorageRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageRequestControllerGetAll(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageRequestControllerGetAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageRequestControllerGetAll(page, size, sort, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorageRequestApi - factory interface
 * @export
 */
export const StorageRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageRequestApiFp(configuration)
    return {
        /**
         * 
         * @param {AddJobStorageRequestToQueueDto} addJobStorageRequestToQueueDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageRequestControllerAddJobStorageRequestToQueue(addJobStorageRequestToQueueDto: AddJobStorageRequestToQueueDto, options?: any): AxiosPromise<void> {
            return localVarFp.storageRequestControllerAddJobStorageRequestToQueue(addJobStorageRequestToQueueDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateStorageRequestDto} createStorageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageRequestControllerCreateStorageRequestAdmin(createStorageRequestDto: CreateStorageRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.storageRequestControllerCreateStorageRequestAdmin(createStorageRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StorageItemDto} storageItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageRequestControllerCreateStorageRequestExtraFee(storageItemDto: StorageItemDto, options?: any): AxiosPromise<CreateStorageRequestExtraFeeResponse> {
            return localVarFp.storageRequestControllerCreateStorageRequestExtraFee(storageItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateStorageRequestDto} createStorageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageRequestControllerCreateStorageRequestUser(createStorageRequestDto: CreateStorageRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.storageRequestControllerCreateStorageRequestUser(createStorageRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageRequestControllerGetAll(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: any): AxiosPromise<StorageRequestControllerGetAll200Response> {
            return localVarFp.storageRequestControllerGetAll(page, size, sort, fullTextSearch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StorageRequestApi - object-oriented interface
 * @export
 * @class StorageRequestApi
 * @extends {BaseAPI}
 */
export class StorageRequestApi extends BaseAPI {
    /**
     * 
     * @param {AddJobStorageRequestToQueueDto} addJobStorageRequestToQueueDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageRequestApi
     */
    public storageRequestControllerAddJobStorageRequestToQueue(addJobStorageRequestToQueueDto: AddJobStorageRequestToQueueDto, options?: AxiosRequestConfig) {
        return StorageRequestApiFp(this.configuration).storageRequestControllerAddJobStorageRequestToQueue(addJobStorageRequestToQueueDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateStorageRequestDto} createStorageRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageRequestApi
     */
    public storageRequestControllerCreateStorageRequestAdmin(createStorageRequestDto: CreateStorageRequestDto, options?: AxiosRequestConfig) {
        return StorageRequestApiFp(this.configuration).storageRequestControllerCreateStorageRequestAdmin(createStorageRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageItemDto} storageItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageRequestApi
     */
    public storageRequestControllerCreateStorageRequestExtraFee(storageItemDto: StorageItemDto, options?: AxiosRequestConfig) {
        return StorageRequestApiFp(this.configuration).storageRequestControllerCreateStorageRequestExtraFee(storageItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateStorageRequestDto} createStorageRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageRequestApi
     */
    public storageRequestControllerCreateStorageRequestUser(createStorageRequestDto: CreateStorageRequestDto, options?: AxiosRequestConfig) {
        return StorageRequestApiFp(this.configuration).storageRequestControllerCreateStorageRequestUser(createStorageRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageRequestApi
     */
    public storageRequestControllerGetAll(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return StorageRequestApiFp(this.configuration).storageRequestControllerGetAll(page, size, sort, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemNotificationApi - axios parameter creator
 * @export
 */
export const SystemNotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateNotiDto} createNotiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemNotificationControllerCreate: async (createNotiDto: CreateNotiDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNotiDto' is not null or undefined
            assertParamExists('systemNotificationControllerCreate', 'createNotiDto', createNotiDto)
            const localVarPath = `/system-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNotiDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemNotificationControllerDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('systemNotificationControllerDelete', 'id', id)
            const localVarPath = `/system-notification/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemNotificationControllerGet: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/system-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemNotificationControllerSend: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('systemNotificationControllerSend', 'id', id)
            const localVarPath = `/system-notification/send/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateNotiDto} updateNotiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemNotificationControllerUpdate: async (id: number, updateNotiDto: UpdateNotiDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('systemNotificationControllerUpdate', 'id', id)
            // verify required parameter 'updateNotiDto' is not null or undefined
            assertParamExists('systemNotificationControllerUpdate', 'updateNotiDto', updateNotiDto)
            const localVarPath = `/system-notification/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotiDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemNotificationApi - functional programming interface
 * @export
 */
export const SystemNotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemNotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateNotiDto} createNotiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemNotificationControllerCreate(createNotiDto: CreateNotiDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemNotificationControllerCreate(createNotiDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemNotificationControllerDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemNotificationControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemNotificationControllerGet(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemNotificationControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemNotificationControllerGet(page, size, sort, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemNotificationControllerSend(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemNotificationControllerSend(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateNotiDto} updateNotiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemNotificationControllerUpdate(id: number, updateNotiDto: UpdateNotiDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemNotificationControllerUpdate(id, updateNotiDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemNotificationApi - factory interface
 * @export
 */
export const SystemNotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemNotificationApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateNotiDto} createNotiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemNotificationControllerCreate(createNotiDto: CreateNotiDto, options?: any): AxiosPromise<object> {
            return localVarFp.systemNotificationControllerCreate(createNotiDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemNotificationControllerDelete(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.systemNotificationControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemNotificationControllerGet(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: any): AxiosPromise<SystemNotificationControllerGet200Response> {
            return localVarFp.systemNotificationControllerGet(page, size, sort, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemNotificationControllerSend(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.systemNotificationControllerSend(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateNotiDto} updateNotiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemNotificationControllerUpdate(id: number, updateNotiDto: UpdateNotiDto, options?: any): AxiosPromise<object> {
            return localVarFp.systemNotificationControllerUpdate(id, updateNotiDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemNotificationApi - object-oriented interface
 * @export
 * @class SystemNotificationApi
 * @extends {BaseAPI}
 */
export class SystemNotificationApi extends BaseAPI {
    /**
     * 
     * @param {CreateNotiDto} createNotiDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemNotificationApi
     */
    public systemNotificationControllerCreate(createNotiDto: CreateNotiDto, options?: AxiosRequestConfig) {
        return SystemNotificationApiFp(this.configuration).systemNotificationControllerCreate(createNotiDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemNotificationApi
     */
    public systemNotificationControllerDelete(id: number, options?: AxiosRequestConfig) {
        return SystemNotificationApiFp(this.configuration).systemNotificationControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemNotificationApi
     */
    public systemNotificationControllerGet(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return SystemNotificationApiFp(this.configuration).systemNotificationControllerGet(page, size, sort, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemNotificationApi
     */
    public systemNotificationControllerSend(id: number, options?: AxiosRequestConfig) {
        return SystemNotificationApiFp(this.configuration).systemNotificationControllerSend(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateNotiDto} updateNotiDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemNotificationApi
     */
    public systemNotificationControllerUpdate(id: number, updateNotiDto: UpdateNotiDto, options?: AxiosRequestConfig) {
        return SystemNotificationApiFp(this.configuration).systemNotificationControllerUpdate(id, updateNotiDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemNotificationUserApi - axios parameter creator
 * @export
 */
export const SystemNotificationUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifiUserControllerCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/system-notification-user/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifiUserControllerGet: async (userId: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('notifiUserControllerGet', 'userId', userId)
            const localVarPath = `/system-notification-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifiUserControllerUpdate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notifiUserControllerUpdate', 'id', id)
            const localVarPath = `/system-notification-user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemNotificationUserApi - functional programming interface
 * @export
 */
export const SystemNotificationUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemNotificationUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifiUserControllerCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifiUserControllerCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifiUserControllerGet(userId: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotifiUserControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifiUserControllerGet(userId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifiUserControllerUpdate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifiUserControllerUpdate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemNotificationUserApi - factory interface
 * @export
 */
export const SystemNotificationUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemNotificationUserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifiUserControllerCount(options?: any): AxiosPromise<object> {
            return localVarFp.notifiUserControllerCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifiUserControllerGet(userId: string, page?: number, size?: number, options?: any): AxiosPromise<NotifiUserControllerGet200Response> {
            return localVarFp.notifiUserControllerGet(userId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifiUserControllerUpdate(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.notifiUserControllerUpdate(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemNotificationUserApi - object-oriented interface
 * @export
 * @class SystemNotificationUserApi
 * @extends {BaseAPI}
 */
export class SystemNotificationUserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemNotificationUserApi
     */
    public notifiUserControllerCount(options?: AxiosRequestConfig) {
        return SystemNotificationUserApiFp(this.configuration).notifiUserControllerCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemNotificationUserApi
     */
    public notifiUserControllerGet(userId: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return SystemNotificationUserApiFp(this.configuration).notifiUserControllerGet(userId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemNotificationUserApi
     */
    public notifiUserControllerUpdate(id: number, options?: AxiosRequestConfig) {
        return SystemNotificationUserApiFp(this.configuration).notifiUserControllerUpdate(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserManagementApi - axios parameter creator
 * @export
 */
export const UserManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerAdminGetUserDetailBasic: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userManagementControllerAdminGetUserDetailBasic', 'userId', userId)
            const localVarPath = `/user-management/basic/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerAdminGetUserDetailHistory: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userManagementControllerAdminGetUserDetailHistory', 'userId', userId)
            const localVarPath = `/user-management/{userId}/history`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} finished 
         * @param {boolean} unfinished 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerAdminGetUserDetailItemList: async (userId: number, finished: boolean, unfinished: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userManagementControllerAdminGetUserDetailItemList', 'userId', userId)
            // verify required parameter 'finished' is not null or undefined
            assertParamExists('userManagementControllerAdminGetUserDetailItemList', 'finished', finished)
            // verify required parameter 'unfinished' is not null or undefined
            assertParamExists('userManagementControllerAdminGetUserDetailItemList', 'unfinished', unfinished)
            const localVarPath = `/user-management/{userId}/item-list`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (finished !== undefined) {
                localVarQueryParameter['finished'] = finished;
            }

            if (unfinished !== undefined) {
                localVarQueryParameter['unfinished'] = unfinished;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerAdminGetUserDetailItemMoneyDetail: async (itemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('userManagementControllerAdminGetUserDetailItemMoneyDetail', 'itemId', itemId)
            const localVarPath = `/user-management/item-money-detail/{itemId}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerAdminGetUserDetailItemMoneyList: async (userId: number, page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userManagementControllerAdminGetUserDetailItemMoneyList', 'userId', userId)
            const localVarPath = `/user-management/{userId}/item-money-list`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerAdminGetUserDetailMembership: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userManagementControllerAdminGetUserDetailMembership', 'userId', userId)
            const localVarPath = `/user-management/membership/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerBlockUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userManagementControllerBlockUser', 'userId', userId)
            const localVarPath = `/user-management/block-user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerDeleteMembership: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userManagementControllerDeleteMembership', 'userId', userId)
            const localVarPath = `/user-management/delete-membership/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} itemId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerExportCsvByItem: async (itemId: number, userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('userManagementControllerExportCsvByItem', 'itemId', itemId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userManagementControllerExportCsvByItem', 'userId', userId)
            const localVarPath = `/user-management/{userId}/export-csv-by-item/{itemId}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerExportCsvByItemList: async (userId: number, page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userManagementControllerExportCsvByItemList', 'userId', userId)
            const localVarPath = `/user-management/{userId}/export-csv-by-item-list`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} finished 
         * @param {boolean} unfinished 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerExportCsvByItemList2: async (userId: number, finished: boolean, unfinished: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userManagementControllerExportCsvByItemList2', 'userId', userId)
            // verify required parameter 'finished' is not null or undefined
            assertParamExists('userManagementControllerExportCsvByItemList2', 'finished', finished)
            // verify required parameter 'unfinished' is not null or undefined
            assertParamExists('userManagementControllerExportCsvByItemList2', 'unfinished', unfinished)
            const localVarPath = `/user-management/{userId}/export-csv-by-item-list-2`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (finished !== undefined) {
                localVarQueryParameter['finished'] = finished;
            }

            if (unfinished !== undefined) {
                localVarQueryParameter['unfinished'] = unfinished;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {MonthPaymentDetailDto} monthPaymentDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerExportCsvByMonth: async (userId: number, monthPaymentDetailDto: MonthPaymentDetailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userManagementControllerExportCsvByMonth', 'userId', userId)
            // verify required parameter 'monthPaymentDetailDto' is not null or undefined
            assertParamExists('userManagementControllerExportCsvByMonth', 'monthPaymentDetailDto', monthPaymentDetailDto)
            const localVarPath = `/user-management/{userId}/export-csv-by-month`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monthPaymentDetailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerExportCsvByMonthList: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userManagementControllerExportCsvByMonthList', 'userId', userId)
            const localVarPath = `/user-management/{userId}/export-csv-by-month-list`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MonthPaymentDetailDto} monthPaymentDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerGetMonthPaymentDetail: async (monthPaymentDetailDto: MonthPaymentDetailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monthPaymentDetailDto' is not null or undefined
            assertParamExists('userManagementControllerGetMonthPaymentDetail', 'monthPaymentDetailDto', monthPaymentDetailDto)
            const localVarPath = `/user-management/get-month-payment-detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monthPaymentDetailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerGetMonthPaymentList: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userManagementControllerGetMonthPaymentList', 'userId', userId)
            const localVarPath = `/user-management/{userId}/get-month-payment-list`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserManagementApi - functional programming interface
 * @export
 */
export const UserManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagementControllerAdminGetUserDetailBasic(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserDetailBasicResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagementControllerAdminGetUserDetailBasic(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagementControllerAdminGetUserDetailHistory(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserPlanHistories>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagementControllerAdminGetUserDetailHistory(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} finished 
         * @param {boolean} unfinished 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagementControllerAdminGetUserDetailItemList(userId: number, finished: boolean, unfinished: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StorageItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagementControllerAdminGetUserDetailItemList(userId, finished, unfinished, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagementControllerAdminGetUserDetailItemMoneyDetail(itemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonthPaymentDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagementControllerAdminGetUserDetailItemMoneyDetail(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagementControllerAdminGetUserDetailItemMoneyList(userId: number, page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StorageItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagementControllerAdminGetUserDetailItemMoneyList(userId, page, size, sort, fullTextSearch, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagementControllerAdminGetUserDetailMembership(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserDetailMembershipResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagementControllerAdminGetUserDetailMembership(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagementControllerBlockUser(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagementControllerBlockUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagementControllerDeleteMembership(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagementControllerDeleteMembership(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} itemId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagementControllerExportCsvByItem(itemId: number, userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagementControllerExportCsvByItem(itemId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagementControllerExportCsvByItemList(userId: number, page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagementControllerExportCsvByItemList(userId, page, size, sort, fullTextSearch, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} finished 
         * @param {boolean} unfinished 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagementControllerExportCsvByItemList2(userId: number, finished: boolean, unfinished: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagementControllerExportCsvByItemList2(userId, finished, unfinished, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {MonthPaymentDetailDto} monthPaymentDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagementControllerExportCsvByMonth(userId: number, monthPaymentDetailDto: MonthPaymentDetailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagementControllerExportCsvByMonth(userId, monthPaymentDetailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagementControllerExportCsvByMonthList(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagementControllerExportCsvByMonthList(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MonthPaymentDetailDto} monthPaymentDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagementControllerGetMonthPaymentDetail(monthPaymentDetailDto: MonthPaymentDetailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonthPaymentDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagementControllerGetMonthPaymentDetail(monthPaymentDetailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagementControllerGetMonthPaymentList(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonthPaymentListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagementControllerGetMonthPaymentList(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserManagementApi - factory interface
 * @export
 */
export const UserManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserManagementApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerAdminGetUserDetailBasic(userId: number, options?: any): AxiosPromise<GetUserDetailBasicResponseDTO> {
            return localVarFp.userManagementControllerAdminGetUserDetailBasic(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerAdminGetUserDetailHistory(userId: number, options?: any): AxiosPromise<Array<UserPlanHistories>> {
            return localVarFp.userManagementControllerAdminGetUserDetailHistory(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} finished 
         * @param {boolean} unfinished 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerAdminGetUserDetailItemList(userId: number, finished: boolean, unfinished: boolean, options?: any): AxiosPromise<Array<StorageItem>> {
            return localVarFp.userManagementControllerAdminGetUserDetailItemList(userId, finished, unfinished, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerAdminGetUserDetailItemMoneyDetail(itemId: number, options?: any): AxiosPromise<Array<MonthPaymentDetail>> {
            return localVarFp.userManagementControllerAdminGetUserDetailItemMoneyDetail(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerAdminGetUserDetailItemMoneyList(userId: number, page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, options?: any): AxiosPromise<Array<StorageItem>> {
            return localVarFp.userManagementControllerAdminGetUserDetailItemMoneyList(userId, page, size, sort, fullTextSearch, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerAdminGetUserDetailMembership(userId: number, options?: any): AxiosPromise<GetUserDetailMembershipResponseDTO> {
            return localVarFp.userManagementControllerAdminGetUserDetailMembership(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerBlockUser(userId: number, options?: any): AxiosPromise<void> {
            return localVarFp.userManagementControllerBlockUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerDeleteMembership(userId: number, options?: any): AxiosPromise<void> {
            return localVarFp.userManagementControllerDeleteMembership(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} itemId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerExportCsvByItem(itemId: number, userId: number, options?: any): AxiosPromise<void> {
            return localVarFp.userManagementControllerExportCsvByItem(itemId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerExportCsvByItemList(userId: number, page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, options?: any): AxiosPromise<void> {
            return localVarFp.userManagementControllerExportCsvByItemList(userId, page, size, sort, fullTextSearch, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} finished 
         * @param {boolean} unfinished 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerExportCsvByItemList2(userId: number, finished: boolean, unfinished: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.userManagementControllerExportCsvByItemList2(userId, finished, unfinished, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {MonthPaymentDetailDto} monthPaymentDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerExportCsvByMonth(userId: number, monthPaymentDetailDto: MonthPaymentDetailDto, options?: any): AxiosPromise<void> {
            return localVarFp.userManagementControllerExportCsvByMonth(userId, monthPaymentDetailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerExportCsvByMonthList(userId: number, options?: any): AxiosPromise<void> {
            return localVarFp.userManagementControllerExportCsvByMonthList(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MonthPaymentDetailDto} monthPaymentDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerGetMonthPaymentDetail(monthPaymentDetailDto: MonthPaymentDetailDto, options?: any): AxiosPromise<Array<MonthPaymentDetail>> {
            return localVarFp.userManagementControllerGetMonthPaymentDetail(monthPaymentDetailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagementControllerGetMonthPaymentList(userId: number, options?: any): AxiosPromise<Array<MonthPaymentListItem>> {
            return localVarFp.userManagementControllerGetMonthPaymentList(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserManagementApi - object-oriented interface
 * @export
 * @class UserManagementApi
 * @extends {BaseAPI}
 */
export class UserManagementApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public userManagementControllerAdminGetUserDetailBasic(userId: number, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).userManagementControllerAdminGetUserDetailBasic(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public userManagementControllerAdminGetUserDetailHistory(userId: number, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).userManagementControllerAdminGetUserDetailHistory(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {boolean} finished 
     * @param {boolean} unfinished 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public userManagementControllerAdminGetUserDetailItemList(userId: number, finished: boolean, unfinished: boolean, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).userManagementControllerAdminGetUserDetailItemList(userId, finished, unfinished, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public userManagementControllerAdminGetUserDetailItemMoneyDetail(itemId: number, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).userManagementControllerAdminGetUserDetailItemMoneyDetail(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public userManagementControllerAdminGetUserDetailItemMoneyList(userId: number, page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).userManagementControllerAdminGetUserDetailItemMoneyList(userId, page, size, sort, fullTextSearch, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public userManagementControllerAdminGetUserDetailMembership(userId: number, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).userManagementControllerAdminGetUserDetailMembership(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public userManagementControllerBlockUser(userId: number, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).userManagementControllerBlockUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public userManagementControllerDeleteMembership(userId: number, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).userManagementControllerDeleteMembership(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} itemId 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public userManagementControllerExportCsvByItem(itemId: number, userId: number, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).userManagementControllerExportCsvByItem(itemId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public userManagementControllerExportCsvByItemList(userId: number, page?: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).userManagementControllerExportCsvByItemList(userId, page, size, sort, fullTextSearch, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {boolean} finished 
     * @param {boolean} unfinished 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public userManagementControllerExportCsvByItemList2(userId: number, finished: boolean, unfinished: boolean, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).userManagementControllerExportCsvByItemList2(userId, finished, unfinished, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {MonthPaymentDetailDto} monthPaymentDetailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public userManagementControllerExportCsvByMonth(userId: number, monthPaymentDetailDto: MonthPaymentDetailDto, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).userManagementControllerExportCsvByMonth(userId, monthPaymentDetailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public userManagementControllerExportCsvByMonthList(userId: number, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).userManagementControllerExportCsvByMonthList(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MonthPaymentDetailDto} monthPaymentDetailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public userManagementControllerGetMonthPaymentDetail(monthPaymentDetailDto: MonthPaymentDetailDto, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).userManagementControllerGetMonthPaymentDetail(monthPaymentDetailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public userManagementControllerGetMonthPaymentList(userId: number, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).userManagementControllerGetMonthPaymentList(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAdminGetUserDetailHistory: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerAdminGetUserDetailHistory', 'userId', userId)
            const localVarPath = `/users/admin/user-detail-history/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSubUserDto} createSubUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateSubUser: async (createSubUserDto: CreateSubUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSubUserDto' is not null or undefined
            assertParamExists('userControllerCreateSubUser', 'createSubUserDto', createSubUserDto)
            const localVarPath = `/users/sub-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSubUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCustomerMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteSubUser: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerDeleteSubUser', 'id', id)
            const localVarPath = `/users/sub-user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerFindById', 'id', id)
            const localVarPath = `/users/find-by-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {boolean} [mainUserOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAll: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, mainUserOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/get-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (mainUserOnly !== undefined) {
                localVarQueryParameter['mainUserOnly'] = mainUserOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAllSubUser: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/sub-user/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUserCard: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/user-card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendOtpDto} sendOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSendOtp: async (sendOtpDto: SendOtpDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendOtpDto' is not null or undefined
            assertParamExists('userControllerSendOtp', 'sendOtpDto', sendOtpDto)
            const localVarPath = `/users/send-otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendOtpDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdate: async (updateUserDto: UpdateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserDto' is not null or undefined
            assertParamExists('userControllerUpdate', 'updateUserDto', updateUserDto)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateSubUserDto} updateSubUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateSubUser: async (updateSubUserDto: UpdateSubUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSubUserDto' is not null or undefined
            assertParamExists('userControllerUpdateSubUser', 'updateSubUserDto', updateSubUserDto)
            const localVarPath = `/users/sub-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserCardDto} updateUserCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUserCard: async (updateUserCardDto: UpdateUserCardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserCardDto' is not null or undefined
            assertParamExists('userControllerUpdateUserCard', 'updateUserCardDto', updateUserCardDto)
            const localVarPath = `/users/user-card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserCardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUserManagement: async (page?: number, size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/user-management`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifySubUserDto} verifySubUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerVerifyCreateSubUser: async (verifySubUserDto: VerifySubUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifySubUserDto' is not null or undefined
            assertParamExists('userControllerVerifyCreateSubUser', 'verifySubUserDto', verifySubUserDto)
            const localVarPath = `/users/sub-user/verify-create-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifySubUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerAdminGetUserDetailHistory(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserPlanHistories>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerAdminGetUserDetailHistory(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateSubUserDto} createSubUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCreateSubUser(createSubUserDto: CreateSubUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCreateSubUser(createSubUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCustomerMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerMeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCustomerMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerDeleteSubUser(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerDeleteSubUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerFindById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerFindById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {boolean} [mainUserOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetAll(page?: number, size?: number, sort?: string, fullTextSearch?: string, mainUserOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserControllerGetAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetAll(page, size, sort, fullTextSearch, mainUserOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetAllSubUser(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserControllerGetAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetAllSubUser(page, size, sort, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetUserCard(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetUserCard(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendOtpDto} sendOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerSendOtp(sendOtpDto: SendOtpDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerSendOtp(sendOtpDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdate(updateUserDto: UpdateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdate(updateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateSubUserDto} updateSubUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateSubUser(updateSubUserDto: UpdateSubUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateSubUser(updateSubUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserCardDto} updateUserCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateUserCard(updateUserCardDto: UpdateUserCardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateUserCard(updateUserCardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUserManagement(page?: number, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserControllerGetAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUserManagement(page, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifySubUserDto} verifySubUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerVerifyCreateSubUser(verifySubUserDto: VerifySubUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerVerifyCreateSubUser(verifySubUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAdminGetUserDetailHistory(userId: string, options?: any): AxiosPromise<Array<UserPlanHistories>> {
            return localVarFp.userControllerAdminGetUserDetailHistory(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSubUserDto} createSubUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateSubUser(createSubUserDto: CreateSubUserDto, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerCreateSubUser(createSubUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCustomerMe(options?: any): AxiosPromise<CustomerMeResponse> {
            return localVarFp.userControllerCustomerMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteSubUser(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerDeleteSubUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindById(id: number, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {boolean} [mainUserOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAll(page?: number, size?: number, sort?: string, fullTextSearch?: string, mainUserOnly?: boolean, options?: any): AxiosPromise<UserControllerGetAll200Response> {
            return localVarFp.userControllerGetAll(page, size, sort, fullTextSearch, mainUserOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAllSubUser(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: any): AxiosPromise<UserControllerGetAll200Response> {
            return localVarFp.userControllerGetAllSubUser(page, size, sort, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUserCard(options?: any): AxiosPromise<void> {
            return localVarFp.userControllerGetUserCard(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendOtpDto} sendOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSendOtp(sendOtpDto: SendOtpDto, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerSendOtp(sendOtpDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdate(updateUserDto: UpdateUserDto, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerUpdate(updateUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateSubUserDto} updateSubUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateSubUser(updateSubUserDto: UpdateSubUserDto, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerUpdateSubUser(updateSubUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserCardDto} updateUserCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUserCard(updateUserCardDto: UpdateUserCardDto, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerUpdateUserCard(updateUserCardDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUserManagement(page?: number, size?: number, fullTextSearch?: string, options?: any): AxiosPromise<UserControllerGetAll200Response> {
            return localVarFp.userControllerUserManagement(page, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifySubUserDto} verifySubUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerVerifyCreateSubUser(verifySubUserDto: VerifySubUserDto, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerVerifyCreateSubUser(verifySubUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerAdminGetUserDetailHistory(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerAdminGetUserDetailHistory(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSubUserDto} createSubUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerCreateSubUser(createSubUserDto: CreateSubUserDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerCreateSubUser(createSubUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerCustomerMe(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerCustomerMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerDeleteSubUser(id: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerDeleteSubUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerFindById(id: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {boolean} [mainUserOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerGetAll(page?: number, size?: number, sort?: string, fullTextSearch?: string, mainUserOnly?: boolean, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerGetAll(page, size, sort, fullTextSearch, mainUserOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerGetAllSubUser(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerGetAllSubUser(page, size, sort, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerGetUserCard(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerGetUserCard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendOtpDto} sendOtpDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerSendOtp(sendOtpDto: SendOtpDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerSendOtp(sendOtpDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserDto} updateUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerUpdate(updateUserDto: UpdateUserDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerUpdate(updateUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateSubUserDto} updateSubUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerUpdateSubUser(updateSubUserDto: UpdateSubUserDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerUpdateSubUser(updateSubUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserCardDto} updateUserCardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerUpdateUserCard(updateUserCardDto: UpdateUserCardDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerUpdateUserCard(updateUserCardDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerUserManagement(page?: number, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerUserManagement(page, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifySubUserDto} verifySubUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerVerifyCreateSubUser(verifySubUserDto: VerifySubUserDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerVerifyCreateSubUser(verifySubUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}


